const DownloadXlsx = ({width="40", height="40"}) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-app-defaultColor ">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9339 9.45094C18.5181 10.0352 18.8464 10.8276 18.8464 11.6538V11.3077C18.8464 9.10435 17.9711 6.99126 16.4131 5.43327C14.855 3.87527 12.7419 3 10.5385 3H10.1924C11.0187 3 11.8111 3.32823 12.3953 3.91247C12.9796 4.49672 13.3078 5.28913 13.3078 6.11538V7.5C13.3078 7.77542 13.4172 8.03955 13.612 8.2343C13.8067 8.42905 14.0709 8.53846 14.3463 8.53846H15.7309C16.5572 8.53846 17.3496 8.86669 17.9339 9.45094Z"
        fill="white"/>
      <path
        d="M18.8464 14.0769V11.6538C18.8464 10.8276 18.5181 10.0352 17.9339 9.45094C17.3496 8.86669 16.5572 8.53846 15.7309 8.53846H14.3463C14.0709 8.53846 13.8067 8.42905 13.612 8.2343C13.4172 8.03955 13.3078 7.77542 13.3078 7.5V6.11538C13.3078 5.28913 12.9796 4.49672 12.3953 3.91247C11.8111 3.32823 11.0187 3 10.1924 3H8.46159H6.03848C5.46524 3 5 3.46523 5 4.03846V19.9615C5 20.5348 5.46524 21 6.03848 21H17.8079C18.3811 21 18.8464 20.5348 18.8464 19.9615V14.0769Z"
        fill="white"/>
      <path 
        d="M18.8464 11.6538V14.0769V19.9615M18.8464 11.6538C18.8464 10.8276 18.5181 10.0352 17.9339 9.45094C17.3496 8.86669 16.5572 8.53846 15.7309 8.53846H14.3463C14.0709 8.53846 13.8067 8.42905 13.612 8.2343C13.4172 8.03955 13.3078 7.77542 13.3078 7.5V6.11538C13.3078 5.28913 12.9796 4.49672 12.3953 3.91247C11.8111 3.32823 11.0187 3 10.1924 3M18.8464 11.6538V11.3077M10.1924 3H8.46159H6.03848M10.1924 3H10.5385M10.5385 3H6.03848M10.5385 3C12.7419 3 14.855 3.87527 16.4131 5.43327C17.9711 6.99126 18.8464 9.10435 18.8464 11.3077M6.03848 3C5.46524 3 5 3.46523 5 4.03846V19.9615C5 20.5348 5.46524 21 6.03848 21H17.8079C18.3811 21 18.8464 20.5348 18.8464 19.9615M18.8464 19.9615V11.3077"
        stroke="currentColor"
        strokeWidth="1.38463"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M9.18408 10.9679C9.24456 10.9893 9.30935 11 9.37846 11C9.44758 11.0003 9.51237 10.9896 9.57285 10.9679C9.63332 10.9461 9.68948 10.9093 9.74131 10.8574L11.6074 8.99136C11.7114 8.88768 11.7612 8.76673 11.7567 8.62851C11.7522 8.49028 11.7024 8.36933 11.6074 8.26566C11.5041 8.16164 11.3811 8.10756 11.2383 8.10341C11.0956 8.09926 10.9724 8.14902 10.8687 8.2527L9.89682 9.22462V5.51836C9.89717 5.37184 9.84758 5.24881 9.74805 5.14929C9.64853 5.04976 9.52533 5 9.37846 5C9.23194 4.99966 9.10892 5.04925 9.00939 5.14877C8.90987 5.2483 8.86011 5.37149 8.86011 5.51836V9.22462L7.88819 8.2527C7.78486 8.14937 7.66184 8.09978 7.51912 8.10393C7.37639 8.10807 7.2532 8.16198 7.14953 8.26566C7.05415 8.36933 7.00439 8.49028 7.00024 8.62851C6.99609 8.76673 7.04585 8.88768 7.14953 8.99136L9.01561 10.8574C9.06745 10.9096 9.12361 10.9464 9.18408 10.9679Z"
        fill="currentColor"/>
      <path
        d="M11.7237 18.1217C11.7922 18.0533 11.8307 17.9604 11.8307 17.8635C11.8307 17.7667 11.7922 17.6738 11.7237 17.6053C11.6553 17.5368 11.5624 17.4983 11.4655 17.4983H10.5525V15.3072C10.5525 15.2103 10.514 15.1174 10.4456 15.0489C10.3771 14.9804 10.2842 14.942 10.1873 14.942C10.0905 14.942 9.99759 14.9804 9.9291 15.0489C9.86061 15.1174 9.82214 15.2103 9.82214 15.3072V17.8635C9.82214 17.9604 9.86061 18.0533 9.9291 18.1217C9.99759 18.1902 10.0905 18.2287 10.1873 18.2287H11.4655C11.5624 18.2287 11.6553 18.1902 11.7237 18.1217Z"
        fill="currentColor"/>
      <path
        d="M8.80894 14.9514C8.85567 14.9622 8.8998 14.9821 8.93883 15.01C8.97785 15.0379 9.01101 15.0731 9.03639 15.1138C9.06178 15.1545 9.07891 15.1998 9.08679 15.2471C9.09468 15.2944 9.09316 15.3428 9.08235 15.3895C9.07153 15.4363 9.05161 15.4804 9.02373 15.5194L8.2623 16.5853L9.02282 17.6512C9.07406 17.7301 9.09292 17.8257 9.07546 17.9181C9.058 18.0105 9.00557 18.0926 8.9291 18.1473C8.85262 18.202 8.75798 18.2251 8.6649 18.2118C8.57181 18.1985 8.48745 18.1497 8.42938 18.0758L7.81357 17.2135L7.19685 18.0758C7.13878 18.1497 7.05442 18.1985 6.96133 18.2118C6.86825 18.2251 6.77361 18.202 6.69714 18.1473C6.62066 18.0926 6.56823 18.0105 6.55077 17.9181C6.53331 17.8257 6.55217 17.7301 6.60341 17.6512L7.36484 16.5853L6.60432 15.5194C6.57386 15.4806 6.55157 15.4361 6.53881 15.3884C6.52604 15.3408 6.52306 15.291 6.53005 15.2422C6.53703 15.1934 6.55384 15.1464 6.57946 15.1043C6.60507 15.0621 6.63896 15.0256 6.67908 14.9969C6.7192 14.9682 6.76472 14.9479 6.81289 14.9373C6.86107 14.9267 6.91089 14.9259 6.95936 14.9351C7.00784 14.9442 7.05395 14.9631 7.09492 14.9906C7.1359 15.018 7.17088 15.0535 7.19776 15.0949L7.81357 15.9572L8.42938 15.0949C8.45725 15.0559 8.49254 15.0227 8.53323 14.9973C8.57392 14.9719 8.61921 14.9548 8.66652 14.9469C8.71382 14.939 8.76222 14.9406 8.80894 14.9514Z"
        fill="currentColor"/>
      <path
        d="M13.4485 16.1866L13.4554 16.1886C13.9068 16.3192 14.661 16.5397 14.5601 17.3299C14.5459 17.4695 14.501 17.6043 14.4285 17.7245C14.3561 17.8447 14.2579 17.9474 14.141 18.0251C13.9041 18.1831 13.6083 18.2287 13.3426 18.2287C13.1085 18.2277 12.8755 18.197 12.6492 18.1374C12.5557 18.1114 12.4763 18.0494 12.4285 17.9649C12.4049 17.9231 12.3897 17.877 12.3838 17.8293C12.378 17.7816 12.3816 17.7332 12.3945 17.6869C12.4073 17.6405 12.4292 17.5972 12.4588 17.5594C12.4884 17.5215 12.5252 17.4898 12.567 17.4662C12.6515 17.4184 12.7515 17.4062 12.845 17.4321C13.045 17.4874 13.527 17.5558 13.737 17.4162C13.7776 17.3888 13.821 17.3468 13.8352 17.2368C13.8507 17.1149 13.8023 17.0496 13.2518 16.8903C12.8249 16.767 12.1105 16.5602 12.2018 15.8349C12.2159 15.6982 12.2598 15.5664 12.3304 15.4485C12.401 15.3307 12.4966 15.2298 12.6104 15.1529C13.1504 14.7886 14.0127 15.0018 14.1091 15.0269C14.2028 15.0515 14.2828 15.1124 14.3317 15.196C14.3805 15.2797 14.3941 15.3794 14.3695 15.4731C14.3448 15.5668 14.284 15.6469 14.2003 15.6957C14.1166 15.7446 14.017 15.7582 13.9233 15.7335C13.7188 15.6801 13.2285 15.6167 13.0185 15.7591C12.991 15.7777 12.9683 15.8026 12.9524 15.8317C12.9365 15.8609 12.9279 15.8934 12.9272 15.9266C12.9222 15.9677 12.9213 15.9759 12.9783 16.0134C13.0824 16.0805 13.268 16.1343 13.4485 16.1866Z"
        fill="currentColor"/>
      <path
        d="M17.2174 14.9514C17.2641 14.9622 17.3083 14.9821 17.3473 15.01C17.3863 15.0379 17.4195 15.0731 17.4449 15.1138C17.4702 15.1545 17.4874 15.1998 17.4953 15.2471C17.5031 15.2944 17.5016 15.3428 17.4908 15.3895C17.48 15.4363 17.4601 15.4804 17.4322 15.5194L16.6708 16.5853L17.4313 17.6512C17.4825 17.7301 17.5014 17.8257 17.4839 17.9181C17.4665 18.0105 17.414 18.0926 17.3376 18.1473C17.2611 18.202 17.1665 18.2251 17.0734 18.2118C16.9803 18.1985 16.8959 18.1497 16.8378 18.0758L16.222 17.2135L15.6053 18.0758C15.5472 18.1497 15.4629 18.1985 15.3698 18.2118C15.2767 18.2251 15.1821 18.202 15.1056 18.1473C15.0291 18.0926 14.9767 18.0105 14.9592 17.9181C14.9418 17.8257 14.9606 17.7301 15.0119 17.6512L15.7733 16.5853L15.0128 15.5194C14.9823 15.4806 14.96 15.4361 14.9473 15.3884C14.9345 15.3408 14.9315 15.291 14.9385 15.2422C14.9455 15.1934 14.9623 15.1464 14.9879 15.1043C15.0135 15.0621 15.0474 15.0256 15.0875 14.9969C15.1277 14.9682 15.1732 14.9479 15.2214 14.9373C15.2695 14.9267 15.3194 14.9259 15.3678 14.9351C15.4163 14.9442 15.4624 14.9631 15.5034 14.9906C15.5444 15.018 15.5793 15.0535 15.6062 15.0949L16.222 15.9572L16.8378 15.0949C16.8657 15.0559 16.901 15.0227 16.9417 14.9973C16.9824 14.9719 17.0277 14.9548 17.075 14.9469C17.1223 14.939 17.1707 14.9406 17.2174 14.9514Z"
        fill="currentColor"/>
    </svg>
  )
}

export default DownloadXlsx;