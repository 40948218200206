const Constants = {
  ARRAY_PRODUCTS_TYPES_IN_MERCHANDISE_GROUP: ["merchandising", "service"],
  PRODUCT_TYPE_SIAE_TITOLO: "siae_titolo",
  INFINITE_VALUE: 1000000,
  LOGGED_DATA_HEADERS_TO_SEARCH: ['logged_name', 'logged_mail', 'logged_customer_code', 'mode'],
  alipay: "Alipay",
  card: "C/C",
  cashsales: "Contanti",
  credit_card: "Carta di credito",
  other: "Altri metodi",
  pos: "Pos",
  sepa: "SEPA Credit Transfer",
  sepa_credit_transfer: "SEPA Credit Transfer",
  sofort: "Sofort",
  wechat: "WeChat Pay",
  SEPA_CREDIT_TRANSFER_PAYMENT_METHOD: "sepa_credit_transfer",
  PRODUCT_PROFILING_CATEGORY_AGE: "AGE",
  PRODUCT_PROFILING_CATEGORY_AGE_KEY: "agn",
  PRODUCT_PROFILING_CATEGORY_ESP: "ESP",
  PRODUCT_PROFILING_CATEGORY_STAFF: "STF",
  PRODUCT_PROFILING_CATEGORY_PUB: "PUB",
  PRODUCT_PROFILING_CATEGORY_OTH: "OTH",
  CUSTOMER_TYPE_EXHIBITOR: "exhibitor",
  CUSTOMER_TYPE_SCHOOL: "school",
  CUSTOMER_TYPE_AGENCY: "agency",
  CUSTOMER_TYPE_DIREZIONE: "direzione",
  CUSTOMER_TYPE_OFFICE: "office",
  HOLDING_FORM_TYPE_DEFAULT: "DEFAULT",
  HOLDING_FORM_TYPE_USER_EXHIBITOR_PROFILING_CATEGORY_ESP: "USER_EXHIBITOR_PROFILING_CATEGORY_ESP",
  HOLDING_FORM_TYPE_USER_SCHOOL_PROFILING_CATEGORY_PUB: "USER_SCHOOL_PROFILING_CATEGORY_PUB",
  EASY_TICKET_NAME_COMPONENT: "EasyTicket",
  LOCAL_STORAGE_CUSTOMER_ISSUE: "LOCAL_STORAGE_CUSTOMER_ISSUE",
  EXCEL_REPORT_STATUS_GENERATED: "GENERATED",
  EXCEL_REPORT_STATUS_WAITING: "WAITING",
  FORCE_DESKTOP_VERSION: "FORCE_DESKTOP_VERSION",
  SENTRY_IGNORE_ERRORS: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    //"Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    "gtm.js",
    "node_modules",
    /.*(.totale .importo).*/g,
    /.*(reading 'innerHTML').*/g,
    /.*(ChunkLoadError: Loading chunk).*/g,
    /.*(Loading CSS chunk).*/g,
    /.*(property 'innerHTML' of null).*/g,
  ],
  SENTRY_DENY_URLS: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^safari-web-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ]
};

export default Constants;
