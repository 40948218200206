import * as React from "react";

function SvgCopy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.833 3.459v7.791a2.25 2.25 0 002.25 2.25h5.125a1.417 1.417 0 01-1.291.833H6.083A3.083 3.083 0 013 11.25v-6.5a1.417 1.417 0 01.833-1.291zm4.753-1.792a1.417 1.417 0 011.002.415l2.997 2.997A1.416 1.416 0 0113 6.081v5.169a1.417 1.417 0 01-1.417 1.417h-5.5a1.417 1.417 0 01-1.416-1.417V3.083a1.417 1.417 0 011.416-1.416h2.503zm-.253.833h-2.25a.583.583 0 00-.583.583v8.167c0 .322.261.583.583.583h5.5a.583.583 0 00.584-.583V6.333H9.75a1.417 1.417 0 01-1.415-1.348l-.002-.068V2.5zm3.494 3l-2.66-2.66v2.077a.583.583 0 00.535.581l.048.002h2.077z"
        fill="#000"
      />
      <path
        d="M4.667 3.459h-.834v.958h.834v-.958zM11.208 13.5v-.833h-.875v.833h.875z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgCopy;
