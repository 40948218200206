import * as React from "react";

function Sofort(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="65"
      height="39"
      viewBox="0 0 65 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="65" height="39" rx="2.6" fill="#FFB3C7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9845 19.678C45.002 21.2876 44.9912 22.8955 44.9912 24.5018C44.9912 24.8787 44.9471 24.9213 44.5635 24.9213C44.1201 24.9238 43.675 24.9238 43.2307 24.9213C42.8837 24.9213 42.8446 24.8837 42.8446 24.5435C42.8446 22.9824 42.8496 21.4222 42.8396 19.8602C42.8396 19.5995 42.8039 19.3379 42.7564 19.0805C42.689 18.7086 42.4378 18.4972 42.0792 18.4378C41.4277 18.3292 40.842 18.5364 40.2962 18.8741C40.2238 18.9192 40.1955 19.0822 40.1955 19.1908C40.1881 20.5045 40.1906 21.8183 40.1906 23.132C40.1906 23.6268 40.1847 24.1198 40.193 24.6129C40.1972 24.8394 40.0982 24.9263 39.8786 24.9238C39.3868 24.9146 38.8951 24.9213 38.4034 24.9196C38.1189 24.9179 38.0407 24.8377 38.0407 24.5461C38.0398 22.0615 38.0398 19.5777 38.0407 17.0932C38.0407 16.8316 38.1189 16.7497 38.3801 16.748C38.8236 16.7438 39.2662 16.7447 39.713 16.7472C39.9942 16.7488 40.0425 16.8015 40.0491 17.0823C40.0508 17.1901 40.0608 17.2962 40.0674 17.4676C40.213 17.3748 40.2979 17.323 40.3827 17.2661C41.2489 16.6761 42.2106 16.5324 43.2199 16.7121C44.1442 16.8742 44.6476 17.4951 44.8348 18.3768C44.9288 18.8039 44.9795 19.2434 44.9845 19.678Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.3796 19.1347C31.427 20.9549 31.4178 22.7784 31.4287 24.6011C31.4295 24.8443 31.3413 24.9237 31.0925 24.9254C30.664 24.9271 30.2364 24.9262 29.8088 24.9254C29.446 24.9254 29.4011 24.8803 29.3828 24.5209C29.3811 24.4816 29.3736 24.4432 29.3636 24.3671C29.1199 24.5034 28.9127 24.6337 28.6947 24.7399C27.9334 25.1101 27.1405 25.1443 26.3401 24.9078C25.6196 24.6922 25.1787 24.1941 25.0023 23.4629C24.875 22.9347 24.8642 22.404 24.9698 21.8784C25.1346 21.0644 25.6812 20.6123 26.4416 20.3833C27.178 20.1593 27.9359 20.1342 28.6964 20.1418C29.2405 20.1484 29.2413 20.1434 29.2397 19.5894C29.2397 19.4464 29.2496 19.2994 29.2239 19.1606C29.1423 18.7144 28.8445 18.4361 28.3536 18.4361C27.5316 18.4361 26.7087 18.4712 25.8875 18.518C25.4848 18.5422 25.4316 18.5221 25.4316 18.1135C25.4316 17.8268 25.4366 17.5393 25.4299 17.2544C25.4266 17.0947 25.489 16.9853 25.6404 16.9535C26.9159 16.6802 28.198 16.4914 29.4918 16.8139C30.6582 17.1023 31.348 17.9171 31.3796 19.1347ZM29.2906 22.8303C29.2715 22.6406 29.2865 22.4483 29.2865 22.2561C29.2865 22.0096 29.2956 21.7622 29.2798 21.5165C29.2757 21.4547 29.1916 21.3469 29.1475 21.3485C28.6517 21.3661 28.1533 21.3795 27.6608 21.4405C27.3587 21.4789 27.1374 21.7004 27.0767 22.0029C27.0201 22.2862 27.0035 22.5854 27.0268 22.8754C27.0534 23.193 27.2814 23.3785 27.5925 23.432C28.0926 23.5189 28.5635 23.4211 29.0294 23.2398C29.2299 23.1629 29.3156 23.0509 29.2906 22.8303Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.5564 19.5852C52.5606 21.2416 52.5589 22.8988 52.5581 24.5552C52.5581 24.8837 52.5173 24.9213 52.1854 24.9213C51.7727 24.9238 51.3617 24.9238 50.949 24.9213C50.5946 24.9213 50.5721 24.9004 50.5538 24.5535C50.5521 24.5126 50.548 24.4733 50.5397 24.4332C50.538 24.4182 50.523 24.409 50.4989 24.3722C50.4473 24.3956 50.3999 24.4106 50.3624 24.4374C49.81 24.8511 49.1743 25.0124 48.4996 25.065C47.1642 25.1736 46.1434 24.2911 46.0801 22.9473C46.0643 22.6164 46.056 22.2787 46.1051 21.9536C46.2266 21.1697 46.7075 20.6491 47.4488 20.4393C48.0079 20.2822 48.5944 20.2154 49.1727 20.1468C49.5088 20.1059 49.8541 20.1351 50.1952 20.1401C50.345 20.1452 50.419 20.0933 50.4107 19.9362C50.3982 19.6997 50.4199 19.4565 50.3799 19.2225C50.2909 18.7002 49.9964 18.4286 49.4705 18.4303C48.656 18.4353 47.8415 18.4721 47.0261 18.518C46.6168 18.5397 46.5585 18.5072 46.5585 18.0843C46.5585 17.8219 46.5594 17.5586 46.5585 17.2962C46.5577 17.0923 46.6451 16.9786 46.8531 16.9302C47.8007 16.7154 48.7575 16.5992 49.726 16.6602C50.1311 16.6861 50.543 16.7756 50.929 16.9051C51.8501 17.2135 52.3584 17.8937 52.5065 18.8465C52.544 19.0922 52.5556 19.3404 52.5564 19.5852ZM50.4604 22.9064C50.4504 22.6899 50.4588 22.476 50.4571 22.262C50.4571 22.033 50.4529 21.8007 50.4604 21.57C50.4638 21.4129 50.4097 21.3335 50.2408 21.3411C49.9404 21.3477 49.6368 21.3235 49.3381 21.3469C48.3372 21.423 48.1558 21.7965 48.1433 22.623C48.1367 23.1094 48.3621 23.3718 48.7473 23.4379C49.2823 23.5256 49.7824 23.4145 50.2691 23.198C50.4014 23.1387 50.4663 23.0585 50.4604 22.9064Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4242 13.9275C22.9641 13.9275 22.5048 13.9275 22.0447 13.9275C21.6994 13.9283 21.667 13.9626 21.667 14.3136C21.667 16.0093 21.667 17.7058 21.667 19.4014C21.667 21.1288 21.667 22.8563 21.667 24.5854C21.667 24.872 21.7161 24.9196 21.9973 24.9213C22.4482 24.9238 22.9 24.9213 23.3526 24.9213C23.7778 24.9213 23.8169 24.8837 23.8169 24.4625C23.8169 21.5559 23.8169 18.6493 23.8169 15.7435C23.8169 15.2655 23.8177 14.7883 23.8169 14.3102C23.816 13.9852 23.7561 13.9283 23.4242 13.9275Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8399 16.6502C36.2209 16.6769 35.6618 16.8725 35.1609 17.236C35.0927 17.2862 35.0244 17.3321 34.9221 17.4057C34.9138 17.2912 34.9046 17.2235 34.9046 17.155C34.898 16.8123 34.8381 16.7488 34.5044 16.7488C34.0743 16.7471 33.6483 16.748 33.2198 16.748C32.828 16.7488 32.7631 16.8123 32.7631 17.2043C32.7631 18.3024 32.7631 19.4022 32.7631 20.5003C32.7631 21.8324 32.7622 23.1621 32.7631 24.49C32.7631 24.8427 32.8404 24.9212 33.1741 24.9212C33.625 24.9238 34.0785 24.9238 34.5302 24.9212C34.8946 24.9212 34.9529 24.8661 34.9529 24.4883C34.9537 22.7225 34.9554 20.9541 34.9496 19.1841C34.9496 19.0094 35.0003 18.91 35.1601 18.8339C35.6892 18.584 36.2317 18.3968 36.8266 18.4211C37.0445 18.4303 37.1502 18.3475 37.1452 18.116C37.1361 17.7249 37.1377 17.3363 37.1444 16.946C37.1452 16.7329 37.0504 16.6418 36.8399 16.6502Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.387 13.9284C13.9668 13.9259 13.5466 13.9243 13.1257 13.9293C12.6847 13.936 12.4409 14.1883 12.4409 14.6329C12.4409 16.2735 12.4409 17.9148 12.4409 19.5553C12.4409 21.2209 12.4409 22.884 12.4409 24.5495C12.4409 24.8412 12.48 24.8805 12.7712 24.8805C13.1989 24.8838 13.6274 24.8838 14.055 24.8805C14.5043 24.8771 14.7298 24.6523 14.7298 24.2035C14.7298 20.8983 14.7298 17.5914 14.7298 14.287C14.7306 13.997 14.6698 13.9309 14.387 13.9284Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1124 21.4248C18.1939 20.4604 17.064 19.9297 15.8318 19.8068C15.4341 19.7993 15.4308 19.7993 15.4416 20.101C15.4458 20.2122 15.4674 20.3216 15.4841 20.432C15.7045 21.8986 16.4184 23.067 17.6065 23.9403C18.3853 24.5153 19.2663 24.8136 20.2256 24.9039C20.511 24.9314 20.5593 24.9114 20.531 24.6306C20.4087 23.4071 19.9652 22.3215 19.1124 21.4248Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2325 13.9552C20.1235 13.9727 20.0104 13.966 19.9022 13.9827C18.6526 14.1825 17.5918 14.7382 16.7481 15.6901C15.9752 16.5609 15.5459 17.588 15.4444 18.748C15.4186 19.0505 15.4352 19.0513 15.7522 19.0923C16.1873 18.9845 16.64 18.9185 17.056 18.7597C19.1335 17.9674 20.27 16.4297 20.5321 14.2234C20.5653 13.9435 20.5187 13.9117 20.2325 13.9552Z"
        fill="black"
      />
    </svg>
  );
}

export default Sofort;
