import * as React from "react";

function Sepa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="65"
      height="39"
      viewBox="0 0 65 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.325"
        y="0.325"
        width="64.35"
        height="38.35"
        rx="2.275"
        fill="white"
        stroke="#9B9B9B"
        strokeWidth="0.65"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.47656 26.3773L8.95603 26.3289C8.98485 26.4906 9.04343 26.6092 9.13161 26.6852C9.21994 26.761 9.33895 26.7989 9.48881 26.7989C9.64749 26.7989 9.76697 26.7652 9.84755 26.6979C9.92798 26.6305 9.96812 26.5517 9.96812 26.4615C9.96812 26.4036 9.95123 26.3545 9.9176 26.3137C9.88382 26.2731 9.82493 26.2379 9.74078 26.2077C9.68313 26.188 9.55203 26.1526 9.34732 26.1018C9.08372 26.0367 8.899 25.9563 8.79269 25.861C8.64331 25.727 8.56861 25.5636 8.56861 25.3707C8.56861 25.2468 8.60379 25.1307 8.6743 25.0228C8.74466 24.9147 8.84631 24.8324 8.97881 24.776C9.11146 24.7195 9.27154 24.6912 9.45905 24.6912C9.76527 24.6912 9.9957 24.7586 10.1505 24.8932C10.3053 25.0279 10.3865 25.2076 10.3943 25.4324L9.9004 25.4518C9.87932 25.3262 9.83407 25.236 9.76465 25.1808C9.69538 25.1258 9.59139 25.0982 9.4527 25.0982C9.30951 25.0982 9.19762 25.1276 9.11658 25.1865C9.06435 25.2242 9.03832 25.2748 9.03832 25.338C9.03832 25.3958 9.0628 25.4452 9.11162 25.4864C9.1736 25.5385 9.32439 25.5929 9.56397 25.6492C9.80339 25.7056 9.98052 25.7641 10.0953 25.8249C10.21 25.8855 10.2999 25.9684 10.3648 26.0736C10.4296 26.179 10.4622 26.3089 10.4622 26.4636C10.4622 26.6041 10.4233 26.7354 10.3455 26.8579C10.2677 26.9804 10.1578 27.0714 10.0157 27.131C9.87359 27.1905 9.69646 27.2204 9.48431 27.2204C9.17562 27.2204 8.93852 27.1486 8.77301 27.0051C8.60766 26.8615 8.5088 26.6523 8.47656 26.3773Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9738 25.4084H11.4435V27.1768H10.9738V25.4084ZM10.9736 24.7349H11.4433V25.1662H10.9736V24.7349Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.636 27.1768H13.1663V26.2722C13.1663 26.0809 13.1562 25.9572 13.1364 25.901C13.1164 25.8449 13.0842 25.801 13.0394 25.7699C12.9946 25.7389 12.9407 25.7232 12.8778 25.7232C12.797 25.7232 12.7247 25.7455 12.6605 25.79C12.5964 25.8345 12.5525 25.8935 12.5288 25.9669C12.5049 26.0403 12.4931 26.1759 12.4931 26.3741V27.1768H12.0234V25.4083H12.4592V25.6682C12.6134 25.4658 12.8077 25.3647 13.0417 25.3647C13.1449 25.3647 13.2393 25.3835 13.3247 25.4207C13.4101 25.4579 13.4747 25.5055 13.5186 25.5634C13.5624 25.6212 13.5929 25.6868 13.6101 25.7603C13.6273 25.8336 13.636 25.9387 13.636 26.0755V27.1768Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.586 26.2544C14.586 26.4426 14.6219 26.5805 14.6941 26.6677C14.7662 26.7553 14.8551 26.7989 14.961 26.7989C15.0743 26.7989 15.17 26.7541 15.2483 26.6645C15.3264 26.5749 15.3656 26.442 15.3656 26.266C15.3656 26.0824 15.3281 25.946 15.2533 25.8569C15.1782 25.7679 15.0837 25.7232 14.9692 25.7232C14.8579 25.7232 14.7662 25.7671 14.6941 25.8544C14.6219 25.942 14.586 26.0752 14.586 26.2544ZM14.1646 27.2931L14.6972 27.3512C14.7062 27.4171 14.7268 27.4624 14.759 27.4872C14.8036 27.5225 14.8738 27.5402 14.9696 27.5402C15.0922 27.5402 15.1841 27.5219 15.2455 27.4853C15.2865 27.4608 15.3178 27.4214 15.3391 27.367C15.3535 27.3279 15.3607 27.2565 15.3607 27.152L15.3621 26.8938C15.2236 27.0825 15.0479 27.1768 14.8346 27.1768C14.5968 27.1768 14.4086 27.0763 14.2698 26.8749C14.1608 26.7158 14.1064 26.5177 14.1064 26.2808C14.1064 25.9837 14.1777 25.7569 14.3205 25.6C14.4632 25.4432 14.6408 25.3647 14.8529 25.3647C15.0717 25.3647 15.2523 25.461 15.3945 25.6533V25.4083H15.8305V26.998C15.8305 27.2072 15.8133 27.3634 15.7787 27.4667C15.7443 27.5703 15.6959 27.6514 15.6336 27.7103C15.5713 27.7692 15.4883 27.8154 15.3843 27.8487C15.2803 27.8821 15.1489 27.8988 14.9901 27.8988C14.6899 27.8988 14.477 27.847 14.3513 27.7436C14.2256 27.6402 14.1629 27.509 14.1629 27.3501C14.1629 27.3344 14.1635 27.3155 14.1646 27.2931Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3931 24.7349H16.8628V27.1768H16.3931V24.7349Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.498 26.1448C18.4947 26.0071 18.4595 25.9026 18.3924 25.8308C18.3255 25.7592 18.244 25.7232 18.1479 25.7232C18.0451 25.7232 17.9602 25.7612 17.8933 25.8367C17.8262 25.9122 17.7933 26.015 17.7944 26.1448H18.498ZM18.4737 26.6197L18.9386 26.6972C18.8785 26.8676 18.7835 26.9975 18.6538 27.0867C18.5241 27.176 18.3617 27.2205 18.1669 27.2205C17.8583 27.2205 17.6301 27.1196 17.4821 26.9178C17.3651 26.7563 17.3066 26.5524 17.3066 26.3059C17.3066 26.0119 17.3833 25.7814 17.5371 25.6147C17.6906 25.448 17.8848 25.3647 18.1196 25.3647C18.3835 25.3647 18.5916 25.4522 18.7441 25.6269C18.8966 25.802 18.9694 26.0697 18.9628 26.4307H17.7861C17.7894 26.5699 17.8275 26.6781 17.9003 26.7557C17.9732 26.8329 18.064 26.8716 18.1728 26.8716C18.2467 26.8716 18.309 26.8518 18.3593 26.8119C18.4097 26.7723 18.448 26.7081 18.4737 26.6197Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 27.1768V24.7349H22.3064V25.1468H20.994V25.6895H22.2144V26.1013H20.994V26.765H22.3548V27.1768H20.5Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0338 27.1769V26.9121C23.9691 27.0065 23.8844 27.0815 23.7791 27.1372C23.6739 27.1927 23.563 27.2206 23.4461 27.2206C23.3269 27.2206 23.2203 27.1944 23.1256 27.1422C23.0311 27.0899 22.9626 27.0164 22.9203 26.922C22.878 26.8274 22.8569 26.6967 22.8569 26.5299V25.4084H23.3266V26.2218C23.3266 26.4708 23.3352 26.6234 23.3525 26.6794C23.3697 26.7358 23.401 26.7801 23.4466 26.813C23.4921 26.8457 23.5498 26.862 23.6198 26.862C23.6998 26.862 23.7715 26.8403 23.8348 26.7963C23.8982 26.7524 23.9414 26.698 23.9648 26.6328C23.988 26.5679 23.9998 26.4086 23.9998 26.1553V25.4084H24.4695V27.1769H24.0338Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5019 27.1768H25.0322V25.4083H25.468V25.6598C25.5425 25.541 25.6095 25.4623 25.669 25.4232C25.7285 25.3843 25.7959 25.3647 25.8715 25.3647C25.9781 25.3647 26.081 25.3944 26.1799 25.4534L26.037 25.8629C25.958 25.8119 25.8842 25.7863 25.8164 25.7863C25.7505 25.7863 25.6949 25.8045 25.6491 25.8404C25.6034 25.8767 25.5675 25.942 25.5413 26.0364C25.5151 26.1308 25.5019 26.3288 25.5019 26.6299V27.1768Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8487 26.2925C26.8487 26.4688 26.8904 26.6036 26.9739 26.6972C27.0574 26.7908 27.1603 26.8377 27.2829 26.8377C27.4053 26.8377 27.5081 26.7908 27.591 26.6972C27.6739 26.6036 27.7154 26.4676 27.7154 26.2893C27.7154 26.1155 27.6739 25.9815 27.591 25.888C27.5081 25.7944 27.4053 25.7475 27.2829 25.7475C27.1603 25.7475 27.0574 25.7944 26.9739 25.888C26.8904 25.9815 26.8487 26.1166 26.8487 26.2925ZM26.3691 26.2674C26.3691 26.1114 26.4074 25.9604 26.4841 25.8144C26.5607 25.6685 26.6692 25.557 26.8097 25.4801C26.9501 25.4032 27.1071 25.3647 27.2803 25.3647C27.548 25.3647 27.7673 25.4519 27.9383 25.6263C28.1093 25.8007 28.1948 26.0212 28.1948 26.2876C28.1948 26.5561 28.1083 26.7789 27.9357 26.9555C27.7631 27.1321 27.5456 27.2205 27.2836 27.2205C27.1215 27.2205 26.9668 27.1836 26.8198 27.1103C26.6726 27.0366 26.5607 26.9288 26.4841 26.7867C26.4074 26.6445 26.3691 26.4716 26.3691 26.2674Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.218 25.1468V25.8445H30.487C30.6808 25.8445 30.8103 25.8318 30.8757 25.8061C30.9409 25.7803 30.9922 25.74 31.0293 25.6851C31.0663 25.6304 31.0849 25.5667 31.0849 25.494C31.0849 25.4045 31.0589 25.3307 31.0068 25.2726C30.9547 25.2144 30.8889 25.1781 30.8092 25.1636C30.7505 25.1524 30.6327 25.1468 30.4554 25.1468H30.218ZM29.7241 27.1768V24.7349H30.5148C30.8143 24.7349 31.0097 24.7473 31.1007 24.7716C31.2405 24.8082 31.3575 24.888 31.4518 25.0108C31.5462 25.1335 31.5933 25.2921 31.5933 25.4865C31.5933 25.6365 31.5662 25.7624 31.5118 25.8648C31.4576 25.9669 31.3885 26.0472 31.3048 26.1055C31.2211 26.1639 31.136 26.2024 31.0496 26.2213C30.9319 26.2447 30.7618 26.2563 30.5391 26.2563H30.218V27.1768H29.7241Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.0689 26.329C33.0089 26.3491 32.9142 26.373 32.7846 26.4009C32.6551 26.4287 32.5703 26.4561 32.5307 26.4828C32.4696 26.5263 32.4394 26.5815 32.4394 26.6484C32.4394 26.7143 32.4636 26.771 32.5122 26.8191C32.561 26.867 32.623 26.891 32.6985 26.891C32.7826 26.891 32.8628 26.8631 32.9393 26.8074C32.9957 26.765 33.0329 26.7132 33.0506 26.6518C33.0627 26.6116 33.0689 26.5352 33.0689 26.4225V26.329ZM32.4396 25.9462L32.0182 25.8686C32.066 25.6973 32.1481 25.5706 32.2646 25.4883C32.3813 25.406 32.5546 25.3647 32.7844 25.3647C32.9931 25.3647 33.1487 25.3896 33.2508 25.4389C33.353 25.4886 33.425 25.5514 33.4666 25.6275C33.5082 25.7037 33.5292 25.8437 33.5292 26.0473L33.5244 26.5946C33.5244 26.7502 33.5318 26.8653 33.5467 26.9391C33.5617 27.013 33.5898 27.0923 33.6308 27.1768H33.1659C33.1535 27.1462 33.1386 27.1008 33.1208 27.0406C33.1131 27.0132 33.1075 26.9952 33.1044 26.9864C33.0244 27.0644 32.939 27.123 32.8481 27.162C32.7571 27.201 32.6603 27.2205 32.5571 27.2205C32.3751 27.2205 32.2316 27.1709 32.1269 27.072C32.0221 26.9729 31.9697 26.8476 31.9697 26.6963C31.9697 26.5963 31.9936 26.507 32.0412 26.4285C32.0891 26.3501 32.156 26.2899 32.242 26.2482C32.3282 26.2066 32.4523 26.1699 32.6144 26.1387C32.833 26.0976 32.9846 26.0594 33.0691 26.0237V25.9764C33.0691 25.8852 33.0467 25.8203 33.0021 25.7814C32.9573 25.7426 32.8732 25.7232 32.7492 25.7232C32.6655 25.7232 32.6003 25.7398 32.5533 25.7727C32.5064 25.8057 32.4684 25.8635 32.4396 25.9462Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8662 25.4084H34.3649L34.7887 26.664L35.2028 25.4084H35.6871L34.9504 27.4214C34.9091 27.525 34.8699 27.6039 34.8327 27.6585C34.7955 27.7129 34.7526 27.7572 34.7043 27.791C34.6558 27.825 34.5966 27.8517 34.5259 27.8704C34.4552 27.8895 34.3754 27.8989 34.2865 27.8989C34.1964 27.8989 34.1078 27.8895 34.0212 27.8706L33.9775 27.5039C34.0511 27.5186 34.1177 27.5258 34.1768 27.5258C34.2863 27.5258 34.3671 27.4933 34.4196 27.4289C34.4721 27.364 34.5123 27.2817 34.5402 27.1819L33.8662 25.4084Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0537 25.4083H36.4847V25.6499C36.6395 25.4598 36.8237 25.3647 37.0376 25.3647C37.151 25.3647 37.2496 25.388 37.3331 25.4347C37.4166 25.4815 37.4851 25.5522 37.5384 25.6466C37.6162 25.5522 37.7004 25.4815 37.7903 25.4347C37.8805 25.388 37.9764 25.3647 38.0787 25.3647C38.2088 25.3647 38.3189 25.3911 38.4089 25.4441C38.4989 25.4967 38.5663 25.5744 38.6108 25.6769C38.6429 25.7524 38.659 25.8747 38.659 26.0438V27.1768H38.1893V26.1639C38.1893 25.988 38.1732 25.8747 38.1411 25.8235C38.0977 25.7565 38.0312 25.7232 37.9415 25.7232C37.876 25.7232 37.8143 25.7432 37.7566 25.7834C37.699 25.8235 37.6575 25.8821 37.632 25.9595C37.6065 26.0367 37.5936 26.1589 37.5936 26.3257V27.1768H37.1239V26.2057C37.1239 26.033 37.1157 25.922 37.099 25.8717C37.0824 25.8217 37.0567 25.7845 37.0216 25.76C36.9866 25.7356 36.9393 25.7232 36.8794 25.7232C36.8073 25.7232 36.7424 25.7427 36.6847 25.7817C36.6271 25.8206 36.5859 25.8769 36.5608 25.9502C36.5358 26.0237 36.5234 26.1454 36.5234 26.3156V27.1768H36.0537V25.4083Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2771 26.1448C40.2737 26.0071 40.2387 25.9026 40.1714 25.8308C40.1046 25.7592 40.0229 25.7232 39.9269 25.7232C39.8243 25.7232 39.7392 25.7612 39.6724 25.8367C39.6053 25.9122 39.5725 26.015 39.5735 26.1448H40.2771ZM40.2531 26.6197L40.718 26.6972C40.6578 26.8676 40.5629 26.9975 40.4332 27.0867C40.3034 27.176 40.1411 27.2205 39.9462 27.2205C39.6378 27.2205 39.4095 27.1196 39.2614 26.9178C39.1444 26.7563 39.0859 26.5524 39.0859 26.3059C39.0859 26.0119 39.163 25.7814 39.3164 25.6147C39.4701 25.448 39.6641 25.3647 39.899 25.3647C40.163 25.3647 40.3709 25.4522 40.5234 25.6269C40.6759 25.802 40.7489 26.0697 40.7422 26.4307H39.5654C39.5688 26.5699 39.6069 26.6781 39.6798 26.7557C39.7525 26.8329 39.8433 26.8716 39.9522 26.8716C40.0261 26.8716 40.0883 26.8518 40.1386 26.8119C40.1893 26.7723 40.2274 26.7081 40.2531 26.6197Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8318 27.1768H42.3621V26.2722C42.3621 26.0809 42.352 25.9572 42.3324 25.901C42.3124 25.8449 42.28 25.801 42.2352 25.7699C42.1904 25.7389 42.1365 25.7232 42.0736 25.7232C41.993 25.7232 41.9205 25.7455 41.8565 25.79C41.7923 25.8345 41.7485 25.8935 41.7246 25.9669C41.7009 26.0403 41.6889 26.1759 41.6889 26.3741V27.1768H41.2192V25.4083H41.655V25.6682C41.8094 25.4658 42.0035 25.3647 42.2375 25.3647C42.3407 25.3647 42.4353 25.3835 42.5205 25.4207C42.6059 25.4579 42.6707 25.5055 42.7145 25.5634C42.7584 25.6212 42.7887 25.6868 42.8059 25.7603C42.8233 25.8336 42.8318 25.9387 42.8318 26.0755V27.1768Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.2178 25.4084V25.7814H43.8981V26.4969C43.8981 26.6418 43.9011 26.7263 43.9071 26.7501C43.913 26.7742 43.9266 26.7939 43.9479 26.8095C43.9692 26.825 43.995 26.8329 44.0255 26.8329C44.0683 26.8329 44.1296 26.8179 44.2105 26.788L44.2516 27.1505C44.1433 27.1971 44.0207 27.2205 43.8839 27.2205C43.7997 27.2205 43.7242 27.2063 43.6568 27.1779C43.5896 27.1497 43.5401 27.1128 43.5085 27.0677C43.4771 27.0227 43.4554 26.9618 43.4433 26.885C43.4332 26.8304 43.4284 26.7202 43.4284 26.5545V25.7814H43.2153V25.4084H43.4284V25.0547L43.8981 24.7786V25.4084H44.2178Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.4819 26.6778L44.9516 26.6051C44.9716 26.6955 45.0121 26.764 45.073 26.811C45.134 26.858 45.2193 26.8812 45.329 26.8812C45.45 26.8812 45.541 26.8589 45.6019 26.8143C45.6429 26.7828 45.6636 26.7411 45.6636 26.6884C45.6636 26.6527 45.6522 26.6229 45.6299 26.5997C45.6064 26.5772 45.554 26.5566 45.4725 26.5375C45.093 26.4536 44.8523 26.3772 44.751 26.3082C44.6102 26.2125 44.54 26.0794 44.54 25.9093C44.54 25.7555 44.6006 25.6263 44.7223 25.5218C44.8441 25.4172 45.0327 25.3647 45.2882 25.3647C45.5315 25.3647 45.7122 25.404 45.8306 25.4823C45.9489 25.5607 46.0304 25.6765 46.0751 25.8299L45.6344 25.9122C45.6154 25.8435 45.5791 25.7907 45.5258 25.7541C45.4723 25.7173 45.3962 25.699 45.2975 25.699C45.1729 25.699 45.0835 25.7162 45.0297 25.7511C44.9936 25.7756 44.9758 25.8074 44.9758 25.8465C44.9758 25.8801 44.9913 25.9086 45.0226 25.9322C45.0649 25.9634 45.2114 26.0076 45.4616 26.0646C45.7119 26.1217 45.8867 26.1915 45.986 26.2741C46.084 26.3578 46.1333 26.4746 46.1333 26.6242C46.1333 26.7871 46.0654 26.9273 45.9301 27.0445C45.7945 27.1619 45.5943 27.2205 45.329 27.2205C45.0883 27.2205 44.8977 27.1717 44.7573 27.0744C44.6169 26.9769 44.5252 26.845 44.4819 26.6778Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9922 26.2128L48.651 25.3067L48.3212 26.2128H48.9922ZM49.8978 27.1768H49.3659L49.1548 26.6246H48.1711L47.97 27.1768H47.4473L48.3927 24.7349H48.9122L49.8978 27.1768Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.7046 27.1768H50.2349V25.4083H50.6706V25.6598C50.7452 25.541 50.812 25.4623 50.8716 25.4232C50.931 25.3843 50.9984 25.3647 51.0742 25.3647C51.1808 25.3647 51.2835 25.3944 51.3825 25.4534L51.2397 25.8629C51.1605 25.8119 51.0869 25.7863 51.019 25.7863C50.953 25.7863 50.8973 25.8045 50.8516 25.8404C50.8059 25.8767 50.77 25.942 50.7439 26.0364C50.7176 26.1308 50.7046 26.3288 50.7046 26.6299V27.1768Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7388 26.1448C52.7354 26.0071 52.7002 25.9026 52.6333 25.8308C52.5662 25.7592 52.4847 25.7232 52.3887 25.7232C52.2858 25.7232 52.2011 25.7612 52.1341 25.8367C52.067 25.9122 52.034 26.015 52.0353 26.1448H52.7388ZM52.7146 26.6197L53.1795 26.6972C53.1193 26.8676 53.0244 26.9975 52.8946 27.0867C52.7649 27.176 52.6025 27.2205 52.4077 27.2205C52.0992 27.2205 51.8708 27.1196 51.7228 26.9178C51.6058 26.7563 51.5474 26.5524 51.5474 26.3059C51.5474 26.0119 51.6242 25.7814 51.778 25.6147C51.9314 25.448 52.1257 25.3647 52.3605 25.3647C52.6242 25.3647 52.8323 25.4522 52.985 25.6269C53.1373 25.802 53.2103 26.0697 53.2036 26.4307H52.0268C52.0302 26.5699 52.0684 26.6781 52.141 26.7557C52.214 26.8329 52.3048 26.8716 52.4135 26.8716C52.4875 26.8716 52.5497 26.8518 52.6002 26.8119C52.6506 26.7723 52.6887 26.7081 52.7146 26.6197Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.6598 26.329C54.6 26.3491 54.5051 26.373 54.3756 26.4009C54.246 26.4287 54.1614 26.4561 54.1216 26.4828C54.0605 26.5263 54.0303 26.5815 54.0303 26.6484C54.0303 26.7143 54.0545 26.771 54.1033 26.8191C54.1519 26.867 54.2139 26.891 54.2894 26.891C54.3736 26.891 54.4537 26.8631 54.5302 26.8074C54.5866 26.765 54.6238 26.7132 54.6415 26.6518C54.6536 26.6116 54.6598 26.5352 54.6598 26.4225V26.329ZM54.0303 25.9462L53.6089 25.8686C53.6566 25.6973 53.7388 25.5706 53.8553 25.4883C53.972 25.406 54.1452 25.3647 54.3751 25.3647C54.5838 25.3647 54.7394 25.3896 54.8415 25.4389C54.9436 25.4886 55.0157 25.5514 55.0572 25.6275C55.0989 25.7037 55.1198 25.8437 55.1198 26.0473L55.115 26.5946C55.115 26.7502 55.1225 26.8653 55.1373 26.9391C55.1524 27.013 55.1804 27.0923 55.2213 27.1768H54.7566C54.7442 27.1462 54.7295 27.1008 54.7115 27.0406C54.7039 27.0132 54.6982 26.9952 54.6951 26.9864C54.6151 27.0644 54.5299 27.123 54.4389 27.162C54.3479 27.201 54.2509 27.2205 54.1477 27.2205C53.9658 27.2205 53.8223 27.1709 53.7175 27.072C53.6129 26.9729 53.5605 26.8476 53.5605 26.6963C53.5605 26.5963 53.5843 26.507 53.632 26.4285C53.6797 26.3501 53.7467 26.2899 53.8327 26.2482C53.9188 26.2066 54.043 26.1699 54.2051 26.1387C54.4237 26.0976 54.5753 26.0594 54.6597 26.0237V25.9764C54.6597 25.8852 54.6374 25.8203 54.5928 25.7814C54.5482 25.7426 54.4638 25.7232 54.3399 25.7232C54.2562 25.7232 54.191 25.7398 54.144 25.7727C54.0972 25.8057 54.0591 25.8635 54.0303 25.9462Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8488 15.1395H15.6506C15.6506 14.6144 15.5889 14.262 15.4662 14.0826C15.2759 13.8172 14.7506 13.6843 13.8908 13.6843C13.0558 13.6843 12.5023 13.7607 12.2322 13.9138C11.9622 14.0673 11.8271 14.4011 11.8271 14.915C11.8271 15.3803 11.9467 15.6867 12.1866 15.8335C12.3585 15.9377 12.5855 15.999 12.8686 16.0173L13.5136 16.0628C14.8959 16.1546 15.7592 16.2193 16.1036 16.256C17.1967 16.3663 17.9892 16.6571 18.4809 17.1285C18.8679 17.4962 19.0986 17.9769 19.1722 18.5704C19.2153 18.9255 19.2367 19.3272 19.2367 19.7736C19.2367 20.8024 19.1386 21.556 18.9421 22.0335C18.586 22.9097 17.7861 23.463 16.5414 23.6954C16.0199 23.7937 15.2221 23.8429 14.1491 23.8429C12.358 23.8429 11.1098 23.7362 10.405 23.5218C9.53996 23.2591 8.96968 22.727 8.69368 21.9257C8.53996 21.4797 8.46387 20.7369 8.46387 19.697H11.6621C11.6621 19.8129 11.6624 19.9016 11.6624 19.9627C11.6624 20.5176 11.8218 20.8745 12.14 21.0335C12.3605 21.1431 12.6267 21.2006 12.9398 21.2074H14.1155C14.7155 21.2074 15.0985 21.1767 15.2637 21.115C15.5576 20.9994 15.7506 20.8131 15.8428 20.5555C15.8917 20.3968 15.9161 20.1916 15.9161 19.9404C15.9161 19.3777 15.7107 19.0354 15.3009 18.9126C15.1476 18.8641 14.4344 18.7965 13.1615 18.7107C12.1394 18.6377 11.4293 18.5675 11.0317 18.4994C9.98456 18.3034 9.28674 17.9004 8.93853 17.2886C8.63247 16.7688 8.47936 15.9822 8.47936 14.9301C8.47936 14.1288 8.56212 13.4863 8.72747 13.003C8.89297 12.5196 9.15967 12.1526 9.52771 11.9016C10.0668 11.5163 10.7568 11.2901 11.5965 11.2225C12.2953 11.1615 13.0704 11.1304 13.923 11.1304C15.2654 11.1304 16.222 11.2076 16.7923 11.3606C18.184 11.7347 18.8798 12.7827 18.8798 14.5048C18.8798 14.646 18.8694 14.8572 18.8488 15.1395Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3511 17.5785H38.4943C39.1738 17.5541 39.5871 17.5018 39.7359 17.4199C39.9391 17.3081 40.0754 17.0844 40.1433 16.7487C40.1867 16.5253 40.2084 16.1932 40.2084 15.752C40.2084 15.2115 40.1653 14.8108 40.0787 14.5491C39.955 14.1826 39.6589 13.9593 39.1898 13.8782C39.0971 13.866 38.8751 13.8595 38.5233 13.8595H36.3511V17.5785ZM32.9131 23.8429V11.0627H39.318C40.1965 11.0627 40.8669 11.1345 41.3275 11.2779C42.3809 11.6082 43.0877 12.2844 43.4497 13.3063C43.6366 13.8426 43.7301 14.65 43.7301 15.7282C43.7301 17.0246 43.6264 17.9562 43.4214 18.5234C43.0106 19.645 42.1649 20.2911 40.888 20.4585C40.7378 20.4836 40.0989 20.5061 38.9712 20.5241L38.4005 20.5432H36.3509V23.8429H32.9131Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.3858 19.1444L48.7751 13.6044L47.212 19.1444H50.3858ZM51.0687 21.6341H46.4722L45.8547 23.8429H42.2979L46.1358 11.0627H51.3216L55.2066 23.8429H51.7245L51.0687 21.6341Z"
        fill="#132B84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1681 12.8608C29.5861 12.8608 30.8515 13.5143 31.6834 14.5379L32.4245 12.9482C31.2655 11.8522 29.6881 11.1765 27.9482 11.1765C25.2394 11.1765 22.9222 12.8133 21.9712 15.1303H20.5261L19.6729 16.9605H21.5318C21.5179 17.1303 21.5089 17.3002 21.5089 17.4735C21.5089 17.6833 21.5197 17.8906 21.5405 18.0954H20.6062L19.7531 19.9262H22.0155C22.9936 22.1846 25.2819 23.7701 27.9482 23.7701C29.292 23.7701 30.5388 23.3673 31.5712 22.6792V20.4355C30.7398 21.3826 29.5238 21.981 28.1681 21.981C26.5834 21.981 25.1895 21.1635 24.3778 19.9262H29.1153L29.9684 18.0954H23.6808C23.6484 17.8751 23.631 17.65 23.631 17.4207C23.631 17.2656 23.6391 17.1121 23.654 16.9605H30.4971L31.3502 15.1303H24.2458C25.0315 13.7737 26.4936 12.8608 28.1681 12.8608Z"
        fill="#F6BC25"
      />
    </svg>
  );
}

export default Sepa;
