import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";

// Assets
import "react-datepicker/dist/react-datepicker.css";

// Icons
import { Calendar } from "../../icons";

const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="flex flex-row items-center mt-1" style={{ height: "1.5vh" }}>
    <div className=" flex  w-full h-full leading-normal">{value}</div>
    <button onClick={onClick} ref={ref} className="ml-64 absolute">
      <Calendar />
    </button>
  </div>
));

const FormikDatePicker = ({
  label,
  value,
  onChange,
  compCss,
  error = false,
  errorLabel = "",
  ...props
}) => {
  return (
    <div className={`flex flex-col w-full ${compCss}`}>
      {label && (
        <span className="text-app-mediumgrey font-normal mb-2">{label}</span>
      )}
      <span className="font-tk-usual text-app-redLight">{errorLabel}</span>
      <div
        className={`flex w-full ${
          error && "border-app-redLight"
        } rounded-md border px-4 py-3 bg-app-white transition-colors focus:border-app-orange focus:outline-none border-app-line disabled:cursor-not-allowed`}
      >
        <DatePicker
          selected={value}
          onChange={onChange}
          customInput={<DatePickerCustomInput />}
          placeholderText="yyyy/MM/dd"
          dateFormat="yyyy/MM/dd"
          {...props}
        />
      </div>
    </div>
  );
};

export default FormikDatePicker;
