import * as React from "react";

function SvgMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.667 12H10c.367 0 .667-.3.667-.667 0-.366-.3-.666-.667-.666H2.667c-.367 0-.667.3-.667.666 0 .367.3.667.667.667zm0-3.333H8c.367 0 .667-.3.667-.667 0-.367-.3-.667-.667-.667H2.667C2.3 7.333 2 7.633 2 8c0 .367.3.667.667.667zm-.667-4c0 .366.3.666.667.666H10c.367 0 .667-.3.667-.666 0-.367-.3-.667-.667-.667H2.667C2.3 4 2 4.3 2 4.667zM13.533 9.92L11.613 8l1.92-1.92a.663.663 0 10-.94-.94L10.2 7.533a.665.665 0 000 .94l2.393 2.394c.26.26.68.26.94 0a.678.678 0 000-.947z"
        fill={props.color ?? "#000"}
      />
    </svg>
  );
}

export default SvgMenu;
