import * as React from "react";

function SvgSwitch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        y={0.5}
        width={27}
        height={15}
        rx={7.5}
        fill="#fff"
        stroke="#ADB5BD"
      />
      <circle cx={8} cy={8} r={6} fill="#ADB5BD" />
    </svg>
  );
}

export default SvgSwitch;
