import { nextAPI } from "../../../../api/axiosAPI";

export const download = async (id) => {
    
    // write correct headers to download xlsx file
    const xlsxDownloadResult = await nextAPI.get(
        "/report/desk_excel_reports/xlsx/download/" + id,
        {
            headers: {
                Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            responseType: "arraybuffer",
        }
    );
    return xlsxDownloadResult;
}