import * as React from "react";

function SvgSdm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="302px" height="80px" viewBox="0 0 302 80" 
    xmlns="http://www.w3.org/2000/svg" version="1.1" 
      className={props.className || ""}
      onClick={props.onClick}
      >
      <desc>Created with Lunacy</desc>
      <defs>
        <rect width="302" height="80" id="artboard_1" />
        <clipPath id="clip_1">
          <use href="#artboard_1" clipRule="evenodd" />
        </clipPath>
      </defs>
      <g id="Ui/Brand/Logo/Logo-Black" clipPath="url(#clip_1)">
        <path d="M33.869 0L0 12.326L12.327 46.195L46.196 33.868L33.869 0Z" transform="translate(8.9997 16.8044)" id="Fill-1" fill="#E30613" fillRule="evenodd" stroke="#E30613" strokeWidth="1" />
        <path d="M7.59332 4.63573C8.43431 6.94473 7.45232 8.27473 5.37931 7.90973C3.30631 7.54573 1.14131 5.65673 0.299315 3.34773C-0.541685 1.03673 0.449315 -0.30627 2.52331 0.05973C4.59531 0.42573 6.75232 2.32373 7.59332 4.63573ZM2.02731 3.65173C2.43931 4.78573 3.61432 5.31573 4.49231 5.47173C5.36831 5.62673 6.27931 5.46373 5.86631 4.32873C5.45431 3.19573 4.28731 2.65373 3.40931 2.50073C2.53331 2.34573 1.61432 2.51973 2.02731 3.65173Z" transform="translate(28.183685 31.84387)" id="Fill-2" fill="#FFFFFE" fillRule="evenodd" stroke="none" />
        <path d="M1.669 0L0 1.987L0.589 3.603L2.257 1.615L1.669 0ZM3.23 10.862L4.898 8.875L2.55 2.424L0.882 4.412L3.23 10.862Z" transform="translate(40.6626 22.7959)" id="Fill-4" fill="#FFFFFE" fillRule="evenodd" stroke="none" />
        <path d="M0.588 1.615L3.145 2.067L2.557 0.451L0 0L0.588 1.615ZM3.437 2.874L0.884 2.424L3.231 8.875L5.788 9.325L3.437 2.874Z" transform="translate(17.6104 45.3184)" id="Fill-6" fill="#FFFFFE" fillRule="evenodd" stroke="none" />
        <path d="M9.301 3.628L10.97 1.64L1.669 0L0 1.989L9.301 3.628Z" transform="translate(26.513 39.6588)" id="Fill-8" fill="#FFFFFE" fillRule="evenodd" stroke="none" />
        <path d="M0 5.08429L1.62 3.15229L1.94 4.03629L1.959 4.01229C2.025 2.99229 2.562 1.76229 3.17 1.03729C4.87 -0.987713 5.577 0.416287 5.916 1.34729L7.422 5.48629L5.754 7.47629L4.617 4.35629C4.297 3.47329 4.05 2.48429 3.136 3.57229C2.508 4.32129 2.479 5.31929 2.735 6.02929L4.017 9.54529L2.347 11.5343L0 5.08429Z" transform="translate(35.8027 28.968113)" id="Fill-10" fill="#FFFFFE" fillRule="evenodd" stroke="none" />
        <path d="M1.9218 3.39844C2.1688 4.07444 2.5968 4.55144 3.2128 4.76844L4.0428 7.04944C3.1408 6.85244 2.4008 6.28944 1.8088 5.56844C1.2298 4.84844 0.785798 3.96544 0.477798 3.11744C-0.164202 1.35144 -0.434202 -0.293563 1.4808 0.0444366L4.4388 0.565437C5.0108 0.667437 5.6358 0.776437 6.0648 0.583437L6.9588 3.03944C6.7818 3.08844 6.5848 3.07944 6.3728 3.06944C7.2018 3.80644 7.7758 4.78044 8.0998 5.67044C8.6158 7.09144 8.3738 8.09744 6.9798 7.84944C4.7778 7.46244 3.9858 4.85144 3.0568 3.10144C2.8278 2.67244 2.6138 2.41944 2.1958 2.34544C1.6898 2.25644 1.7388 2.89744 1.9218 3.39844ZM4.7748 4.23844C5.0908 4.80444 5.4658 5.30144 6.0008 5.39444C6.5478 5.49044 6.5958 5.05644 6.3978 4.51044C5.9748 3.34844 5.2348 2.85344 3.8608 2.66644C4.1828 3.04644 4.4498 3.68444 4.7748 4.23844Z" transform="translate(29.994701 43.392365)" id="Fill-12" fill="#FFFFFE" fillRule="evenodd" stroke="none" />
        <path d="M0.982392 8.93153C1.68839 10.6765 3.10939 9.71253 4.31939 8.27353C5.46739 6.90553 6.55539 4.78853 5.92639 3.06053C5.73139 2.52753 5.12239 1.89853 3.99539 2.86553C3.15439 3.56653 1.99439 4.78853 1.77139 4.17753C1.60939 3.73353 2.03639 3.16753 2.31239 2.83953C2.51839 2.59453 2.72139 2.42153 2.90139 2.36953C3.07939 2.31853 3.22139 2.39853 3.32139 2.67253L4.86339 0.83453C4.19239 -0.773469 2.87539 0.208531 1.75539 1.54253C0.754392 2.73753 -0.417608 4.73953 0.147392 6.29153C0.782392 8.03253 2.11139 6.50153 3.28339 5.58453C3.60939 5.32253 4.16639 4.80053 4.34839 5.29853C4.53239 5.80753 4.13539 6.53253 3.81039 6.91853C3.57539 7.19853 3.32239 7.39353 3.11139 7.43153C2.88439 7.46853 2.69039 7.36253 2.57339 7.03653L0.982392 8.93153Z" transform="translate(24.067408 44.14437)" id="Fill-14" fill="#FFFFFE" fillRule="evenodd" stroke="none" />
        <path d="M0.023 11.806L0.537 11.806C0.865 12.764 1.683 16.645 5.096 16.645C7.316 16.645 8.789 14.868 8.789 12.811C8.789 9.093 5.236 10.005 2.899 9.093C1.075 8.393 0 7.083 0 4.723C0 2.222 2.197 0 4.511 0C6.101 0 6.942 0.678 7.55 1.1L8.345 0.118L8.813 0.118L8.813 4.349L8.345 4.349C8.018 3.274 7.34 0.562 4.488 0.562C2.781 0.562 1.403 2.198 1.403 3.717C1.403 6.662 4.652 6.032 6.732 6.756C8.742 7.457 10.192 9.188 10.192 11.479C10.192 14.681 8.205 17.205 5.096 17.205C3.226 17.205 2.151 16.271 1.543 15.78L0.491 16.972L0.023 16.972L0.023 11.806Z" transform="translate(67.4643 33.8119)" id="Fill-16" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M8.275 11.501C9.49 11.501 10.215 10.776 10.589 10.285L10.239 9.958C10.098 10.121 9.748 10.472 9.374 10.472C8.813 10.472 8.649 9.818 8.649 9.07L8.649 2.15C8.649 0.491 7.41 0 5.119 0C2.875 0 0.771 1.122 0.771 2.595C0.771 3.413 1.379 3.95 1.987 3.95C2.595 3.95 3.156 3.413 3.156 2.688C3.156 2.033 2.571 1.87 2.571 1.496C2.571 0.935 3.88 0.561 4.722 0.561C5.844 0.561 6.265 0.889 6.265 1.87L6.265 4.955C5.563 5.002 4.511 5.072 3.366 5.306C1.987 5.61 0 6.545 0 8.672C0 10.379 1.169 11.501 2.688 11.501C4.862 11.501 5.634 10.355 6.242 9.186L6.288 9.186C6.335 9.889 6.615 11.501 8.275 11.501ZM4.535 5.703C5.096 5.563 5.75 5.516 6.265 5.516L6.265 6.989C6.265 8.625 5.353 10.472 3.904 10.472C2.875 10.472 2.384 9.468 2.384 8.135C2.384 6.498 3.366 6.007 4.535 5.703Z" transform="translate(78.1473 39.5165)" id="Fill-18" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M0.257 15.989L1.87 15.989L1.87 0.748L0 0.748L0 0.186L1.8 0.186C2.805 0.186 3.553 0.093 4.254 0L4.254 15.989L5.985 15.989L5.985 16.55L0.257 16.55L0.257 15.989Z" transform="translate(88.5726 34.0468)" id="Fill-20" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M5.377 0C2.197 0 0 2.945 0 5.75C0 8.556 2.197 11.501 5.377 11.501C8.556 11.501 10.753 8.556 10.753 5.75C10.753 2.945 8.556 0 5.377 0ZM7.995 5.75C7.995 8.51 7.995 10.94 5.377 10.94C2.758 10.94 2.758 8.51 2.758 5.75C2.758 2.992 2.758 0.562 5.377 0.562C7.995 0.562 7.995 2.992 7.995 5.75Z" transform="translate(94.3657 39.5163)" id="Fill-22" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M0.257 10.52L1.87 10.52L1.87 0.983L0 0.983L0 0.421L1.8 0.421C2.758 0.421 3.436 0.351 4.114 0.235L4.114 3.227L4.161 3.227C4.628 2.105 5.61 0 8.065 0C11.15 0 11.314 1.894 11.314 3.67L11.314 10.52L12.974 10.52L12.974 11.081L7.387 11.081L7.387 10.52L8.93 10.52L8.93 4.115C8.93 2.081 8.93 0.795 7.41 0.795C5.82 0.795 4.254 3.179 4.254 5.213L4.254 10.52L5.984 10.52L5.984 11.081L0.257 11.081L0.257 10.52Z" transform="translate(104.8851 39.5158)" id="Fill-24" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M5.376 11.501C8.696 11.501 9.584 8.976 10.005 7.737L9.537 7.573C9.257 8.555 8.041 10.94 5.33 10.94C2.782 10.94 2.758 8.602 2.758 7.059L2.758 4.955L9.841 4.955C9.841 2.571 8.228 0 5.283 0C2.384 0 0 3.085 0 5.82C0 8.836 2.221 11.501 5.376 11.501ZM5.26 0.561C6.756 0.561 7.27 1.566 7.27 2.945L7.27 4.394L2.758 4.394C2.758 2.22 2.805 0.561 5.26 0.561Z" transform="translate(117.5619 39.5166)" id="Fill-26" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M7.83 14.727L7.877 14.727L7.877 16.737C8.578 16.644 9.303 16.55 10.308 16.55L11.874 16.55L11.874 15.989L10.121 15.989L10.121 0C9.42 0.094 8.672 0.187 7.667 0.187L5.867 0.187L5.867 0.748L7.737 0.748L7.737 7.714L7.69 7.714C7.456 7.013 6.545 5.47 4.605 5.47C2.126 5.47 0 8.088 0 11.104C0 14.189 2.126 16.971 4.605 16.971C6.615 16.971 7.48 15.405 7.83 14.727ZM7.737 11.22C7.737 13.839 7.059 16.41 4.605 16.41C2.804 16.41 2.758 14.423 2.758 11.081C2.758 8.041 2.804 6.031 4.605 6.031C7.035 6.031 7.737 8.299 7.737 11.22Z" transform="translate(131.248 34.0461)" id="Fill-28" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M5.376 11.501C8.695 11.501 9.584 8.976 10.004 7.737L9.536 7.573C9.256 8.555 8.041 10.94 5.329 10.94C2.781 10.94 2.757 8.602 2.757 7.059L2.757 4.955L9.84 4.955C9.84 2.571 8.228 0 5.282 0C2.383 0 0 3.085 0 5.82C0 8.836 2.22 11.501 5.376 11.501ZM5.259 0.561C6.755 0.561 7.27 1.566 7.27 2.945L7.27 4.394L2.757 4.394C2.757 2.22 2.804 0.561 5.259 0.561Z" transform="translate(143.123 39.5166)" id="Fill-30" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M0.257 15.989L1.87 15.989L1.87 0.748L0 0.748L0 0.186L1.799 0.186C2.805 0.186 3.553 0.093 4.254 0L4.254 15.989L5.985 15.989L5.985 16.55L0.257 16.55L0.257 15.989Z" transform="translate(152.8475 34.0468)" id="Fill-32" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M0 15.803L2.058 15.803L2.058 0.562L0 0.562L0 0L5.073 0L8.906 11.875L8.953 11.875L12.692 0L17.72 0L17.72 0.562L15.802 0.562L15.802 15.803L17.742 15.803L17.742 16.364L11.104 16.364L11.104 15.803L13.044 15.803L13.044 1.379L12.997 1.379L8.228 16.364L7.69 16.364L2.806 1.146L2.759 1.146L2.759 15.803L4.815 15.803L4.815 16.364L0 16.364L0 15.803Z" transform="translate(162.0908 34.2328)" id="Fill-34" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M5.377 0C2.197 0 0 2.945 0 5.75C0 8.556 2.197 11.501 5.377 11.501C8.556 11.501 10.753 8.556 10.753 5.75C10.753 2.945 8.556 0 5.377 0ZM7.994 5.75C7.994 8.51 7.994 10.94 5.377 10.94C2.759 10.94 2.759 8.51 2.759 5.75C2.759 2.992 2.759 0.562 5.377 0.562C7.994 0.562 7.994 2.992 7.994 5.75Z" transform="translate(179.6699 39.5163)" id="Fill-36" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M1.987 15.989L0 15.989L0 16.55L1.801 16.55C2.314 16.55 2.735 16.573 3.133 16.596C3.529 16.643 3.88 16.689 4.23 16.737L4.23 14.726L4.277 14.726C4.628 15.405 5.493 16.97 7.504 16.97C9.982 16.97 12.109 14.188 12.109 11.102C12.109 8.088 9.982 5.469 7.504 5.469C5.562 5.469 4.652 7.012 4.418 7.714L4.371 7.714L4.371 0C3.67 0.093 2.922 0.186 1.917 0.186L0.117 0.186L0.117 0.748L1.987 0.748L1.987 15.989ZM9.35 11.08C9.35 14.423 9.305 16.41 7.504 16.41C5.049 16.41 4.371 13.838 4.371 11.219C4.371 8.298 5.073 6.031 7.504 6.031C9.305 6.031 9.35 8.041 9.35 11.08Z" transform="translate(189.9883 34.0468)" id="Fill-38" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M2.992 0C2.221 0 1.588 0.631 1.588 1.403C1.588 2.173 2.221 2.804 2.992 2.804C3.764 2.804 4.395 2.173 4.395 1.403C4.395 0.631 3.764 0 2.992 0ZM1.869 15.148L0.256 15.148L0.256 15.709L5.984 15.709L5.984 15.148L4.254 15.148L4.254 4.863C3.553 4.956 2.805 5.049 1.8 5.049L0 5.049L0 5.611L1.869 5.611L1.869 15.148Z" transform="translate(201.9315 34.8878)" id="Fill-40" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M0.257 15.989L1.869 15.989L1.869 0.748L0 0.748L0 0.186L1.8 0.186C2.805 0.186 3.553 0.093 4.254 0L4.254 15.989L5.984 15.989L5.984 16.55L0.257 16.55L0.257 15.989Z" transform="translate(207.1912 34.0468)" id="Fill-42" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M5.376 11.501C8.695 11.501 9.584 8.976 10.005 7.737L9.537 7.573C9.257 8.555 8.041 10.94 5.33 10.94C2.781 10.94 2.758 8.602 2.758 7.059L2.758 4.955L9.841 4.955C9.841 2.571 8.229 0 5.283 0C2.384 0 0 3.085 0 5.82C0 8.836 2.221 11.501 5.376 11.501ZM5.26 0.561C6.756 0.561 7.27 1.566 7.27 2.945L7.27 4.394L2.758 4.394C2.758 2.22 2.805 0.561 5.26 0.561Z" transform="translate(212.9647 39.5166)" id="Fill-44" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M0 1.449C0 0.631 0.631 0 1.473 0C2.314 0 2.945 0.631 2.945 1.449C2.945 2.267 2.314 2.898 1.473 2.898C0.631 2.898 0 2.267 0 1.449" transform="translate(223.5547 48.1191)" id="Fill-46" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M0 15.803L2.057 15.803L2.057 0.562L0 0.562L0 0L5.073 0L8.906 11.875L8.953 11.875L12.693 0L17.719 0L17.719 0.562L15.803 0.562L15.803 15.803L17.743 15.803L17.743 16.364L11.104 16.364L11.104 15.803L13.044 15.803L13.044 1.379L12.997 1.379L8.229 16.364L7.691 16.364L2.805 1.146L2.758 1.146L2.758 15.803L4.816 15.803L4.816 16.364L0 16.364L0 15.803Z" transform="translate(229.6191 34.2328)" id="Fill-48" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M2.992 0C2.221 0 1.59 0.631 1.59 1.403C1.59 2.173 2.221 2.804 2.992 2.804C3.765 2.804 4.394 2.173 4.394 1.403C4.394 0.631 3.765 0 2.992 0ZM1.87 15.148L0.257 15.148L0.257 15.709L5.984 15.709L5.984 15.148L4.254 15.148L4.254 4.863C3.553 4.956 2.806 5.049 1.8 5.049L0 5.049L0 5.611L1.87 5.611L1.87 15.148Z" transform="translate(247.3856 34.8878)" id="Fill-50" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M0.257 15.989L1.87 15.989L1.87 0.748L0 0.748L0 0.186L1.8 0.186C2.806 0.186 3.554 0.093 4.255 0L4.255 15.989L5.985 15.989L5.985 16.55L0.257 16.55L0.257 15.989Z" transform="translate(252.5516 34.0468)" id="Fill-52" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M8.275 11.501C9.49 11.501 10.215 10.776 10.588 10.285L10.238 9.958C10.098 10.121 9.746 10.472 9.373 10.472C8.813 10.472 8.649 9.818 8.649 9.07L8.649 2.15C8.649 0.491 7.409 0 5.118 0C2.875 0 0.771 1.122 0.771 2.595C0.771 3.413 1.379 3.95 1.986 3.95C2.594 3.95 3.155 3.413 3.155 2.688C3.155 2.033 2.57 1.87 2.57 1.496C2.57 0.935 3.879 0.561 4.721 0.561C5.844 0.561 6.264 0.889 6.264 1.87L6.264 4.955C5.563 5.002 4.51 5.072 3.365 5.306C1.986 5.61 0 6.545 0 8.672C0 10.379 1.168 11.501 2.688 11.501C4.861 11.501 5.633 10.355 6.24 9.186L6.287 9.186C6.334 9.889 6.614 11.501 8.275 11.501ZM4.534 5.703C5.096 5.563 5.75 5.516 6.264 5.516L6.264 6.989C6.264 8.625 5.353 10.472 3.903 10.472C2.875 10.472 2.383 9.468 2.383 8.135C2.383 6.498 3.365 6.007 4.534 5.703Z" transform="translate(258.7167 39.5165)" id="Fill-54" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M0.258 10.52L1.871 10.52L1.871 0.983L0 0.983L0 0.421L1.801 0.421C2.759 0.421 3.437 0.351 4.115 0.235L4.115 3.227L4.162 3.227C4.629 2.105 5.612 0 8.067 0C11.151 0 11.315 1.894 11.315 3.67L11.315 10.52L12.975 10.52L12.975 11.081L7.388 11.081L7.388 10.52L8.93 10.52L8.93 4.115C8.93 2.081 8.93 0.795 7.41 0.795C5.82 0.795 4.255 3.179 4.255 5.213L4.255 10.52L5.985 10.52L5.985 11.081L0.258 11.081L0.258 10.52Z" transform="translate(269.0545 39.5158)" id="Fill-56" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
        <path d="M5.376 0C2.196 0 0 2.945 0 5.75C0 8.556 2.196 11.501 5.376 11.501C8.556 11.501 10.753 8.556 10.753 5.75C10.753 2.945 8.556 0 5.376 0ZM7.993 5.75C7.993 8.51 7.993 10.94 5.376 10.94C2.758 10.94 2.758 8.51 2.758 5.75C2.758 2.992 2.758 0.562 5.376 0.562C7.993 0.562 7.993 2.992 7.993 5.75Z" transform="translate(281.7119 39.5163)" id="Fill-58" fill={props.color || "#000000"} fillRule="evenodd" stroke="none" />
      </g>
    </svg>
  );
}

export default SvgSdm;
