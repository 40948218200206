import * as React from "react";

function DTicketingLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="153"
      height="50"
      viewBox="0 0 153 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70173 11.6958C1.2612 11.6958 0.901367 12.0627 0.901367 12.5107C0.901367 12.9592 1.2612 13.3256 1.70173 13.3256C2.14227 13.3256 2.5021 12.9592 2.5021 12.5107C2.4924 12.0627 2.14227 11.6958 1.70173 11.6958Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71313 6.2251C1.08254 6.2251 0.582031 6.74456 0.582031 7.37604C0.582031 8.0181 1.09224 8.52742 1.71313 8.52742C2.33358 8.52742 2.84378 8.00795 2.84378 7.37604C2.85393 6.74456 2.34372 6.2251 1.71313 6.2251Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71142 3.48413C2.66216 3.48413 3.42284 2.69964 3.42284 1.74184C3.42284 0.784051 2.66216 0 1.71142 0C0.77038 0 0 0.784051 0 1.74184C0 2.70978 0.77038 3.48413 1.71142 3.48413Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.16445 6.69333C5.97496 6.69333 6.63553 6.02129 6.63553 5.19579C6.63553 4.37073 5.97496 3.69824 5.16445 3.69824C4.35394 3.69824 3.69336 4.37073 3.69336 5.19579C3.69336 6.02129 4.35394 6.69333 5.16445 6.69333Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.31537 11.1561C4.84586 11.1561 5.27625 10.7178 5.27625 10.178C5.27625 9.63828 4.84586 9.19995 4.31537 9.19995C3.78488 9.19995 3.35449 9.63828 3.35449 10.178C3.35449 10.7178 3.78488 11.1561 4.31537 11.1561Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.73201 11.6958C2.17254 11.6958 2.53237 12.0627 2.53237 12.5107C2.53237 12.9592 2.17254 13.3256 1.73201 13.3256C1.29147 13.3256 0.931641 12.9592 0.931641 12.5107C0.931641 12.0627 1.29147 11.6958 1.73201 11.6958Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71313 6.2251C2.34372 6.2251 2.84378 6.74456 2.84378 7.37604C2.84378 8.0181 2.33358 8.52742 1.71313 8.52742C1.09224 8.52742 0.582031 8.0181 0.582031 7.38618C0.582031 6.74456 1.08254 6.2251 1.71313 6.2251Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71142 3.48413C0.77038 3.48413 0 2.70978 0 1.74184C0 0.784051 0.77038 0 1.71142 0C2.66216 0 3.42284 0.784051 3.42284 1.74184C3.42284 2.70978 2.66216 3.48413 1.71142 3.48413Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16681 12.49C5.75626 12.337 5.29588 12.5509 5.14595 12.9791C4.99602 13.3967 5.20593 13.8654 5.62661 14.018C6.03672 14.171 6.4971 13.9571 6.64703 13.529C6.7974 13.1114 6.58705 12.643 6.16681 12.49Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01532 7.35525C7.42529 7.14138 6.77442 7.44697 6.56407 8.04802C6.35373 8.64906 6.65447 9.31185 7.2445 9.52484C7.8354 9.73915 8.48539 9.43355 8.69574 8.83295C8.91578 8.2416 8.60578 7.5797 8.01532 7.35525Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.93595 4.78805C9.82672 5.11393 10.8074 4.65575 11.1276 3.74911C11.4482 2.84203 10.9975 1.84367 10.1067 1.51779C9.21641 1.19147 8.23569 1.65052 7.91554 2.55672C7.59496 3.45322 8.05533 4.45202 8.93595 4.78805Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1101 8.99605C11.8708 9.28136 12.7214 8.8739 13.0014 8.09955C13.2819 7.3252 12.8815 6.45957 12.1208 6.17426C11.3601 5.88895 10.5095 6.29597 10.2295 7.07032C9.94902 7.85525 10.3393 8.72088 11.1101 8.99605Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80637 12.8975C9.30688 13.081 9.84707 12.816 10.027 12.3168C10.2073 11.8176 9.94717 11.2571 9.45681 11.0741C8.96645 10.8902 8.41611 11.1553 8.23619 11.6544C8.05584 12.1536 8.30587 12.7141 8.80637 12.8975Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19597 12.5001C6.60608 12.6531 6.82656 13.1219 6.67619 13.5395C6.52626 13.9571 6.06588 14.1815 5.65534 14.0285C5.24523 13.8755 5.02519 13.4072 5.17512 12.9891C5.32505 12.5715 5.78543 12.3471 6.19597 12.5001Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01427 7.35525C8.60429 7.56912 8.90503 8.2416 8.69469 8.83251C8.48434 9.43356 7.82421 9.73915 7.24344 9.52484C6.66312 9.31141 6.35268 8.63892 6.56302 8.04802C6.77337 7.44697 7.4238 7.14138 8.01427 7.35525Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.93595 4.7882C8.05533 4.45218 7.59495 3.45338 7.91554 2.54718C8.23569 1.6401 9.22612 1.17178 10.1067 1.5078C10.9975 1.83368 11.4579 2.84219 11.1276 3.73869C10.8074 4.64533 9.82672 5.11408 8.93595 4.7882Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0974 14.8032C9.75736 14.5178 9.25686 14.5589 8.9764 14.905C8.69638 15.2512 8.73651 15.7605 9.07694 16.0458C9.41693 16.3316 9.91744 16.2906 10.1975 15.944C10.4779 15.5978 10.4378 15.0885 10.0974 14.8032Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5612 10.6162C13.0805 10.2087 12.36 10.2696 11.96 10.7586C11.5592 11.2481 11.6196 11.981 12.1003 12.3889C12.5805 12.7959 13.301 12.7351 13.7014 12.246C14.112 11.757 14.0414 11.0338 13.5612 10.6162Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2924 8.51734C16.0129 9.13868 17.0933 9.03681 17.7041 8.30347C18.3148 7.57013 18.2147 6.46946 17.4942 5.84813C16.7732 5.2268 15.6923 5.32867 15.082 6.062C14.4819 6.80593 14.5718 7.90615 15.2924 8.51734Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9123 13.2338C16.5327 13.7634 17.4636 13.6818 17.9835 13.0503C18.5043 12.4189 18.4241 11.4712 17.8036 10.9412C17.1832 10.412 16.2523 10.4931 15.7324 11.1251C15.2116 11.7561 15.2914 12.7042 15.9123 13.2338Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4487 16.0966C12.8491 16.4428 13.4594 16.3921 13.7998 15.9745C14.1398 15.567 14.09 14.9457 13.6799 14.5991C13.2791 14.2524 12.6692 14.3036 12.3288 14.7212C11.9883 15.1388 12.0382 15.75 12.4487 16.0966Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1286 14.8237C10.4686 15.109 10.5092 15.6183 10.2287 15.9645C9.94873 16.3111 9.44822 16.3521 9.10779 16.0664C8.7678 15.7811 8.72767 15.2717 9.00769 14.9256C9.28771 14.5794 9.78865 14.5282 10.1286 14.8237Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5612 10.6162C14.0414 11.0237 14.1014 11.757 13.7014 12.246C13.301 12.7351 12.58 12.7959 12.1003 12.3889C11.6196 11.981 11.5592 11.2481 11.96 10.7586C12.3701 10.2696 13.0805 10.2087 13.5612 10.6162Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2927 8.51734C14.5721 7.89557 14.472 6.79534 15.0823 6.062C15.6931 5.32867 16.7739 5.2268 17.4944 5.84813C18.215 6.46946 18.3151 7.57013 17.7043 8.30347C17.1042 9.04695 16.0233 9.13868 15.2927 8.51734Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0204 18.3382C12.8004 17.9515 12.31 17.8187 11.9294 18.0428C11.5493 18.2668 11.4192 18.7664 11.6393 19.1531C11.8593 19.5403 12.3497 19.673 12.7302 19.449C13.1108 19.214 13.2405 18.7249 13.0204 18.3382Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6821 15.6177C17.3716 15.0674 16.6713 14.8738 16.1307 15.1895C15.5905 15.5057 15.4005 16.2188 15.7105 16.7687C16.0205 17.319 16.7212 17.5121 17.2618 17.1968C17.8121 16.871 17.9925 16.1681 17.6821 15.6177Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0169 14.2425C20.487 15.0782 21.5383 15.3635 22.3585 14.8846C23.1791 14.4057 23.4596 13.3359 22.9895 12.5002C22.5186 11.665 21.4682 11.3797 20.6475 11.8586C19.8269 12.3477 19.5468 13.4069 20.0169 14.2425Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0155 18.888C19.4261 19.6015 20.3265 19.8559 21.0272 19.4383C21.7275 19.0203 21.978 18.1035 21.5679 17.3904C21.1569 16.6774 20.2564 16.4225 19.5561 16.8401C18.8453 17.2582 18.6054 18.1745 19.0155 18.888Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7928 20.3656C15.053 20.8343 15.6435 20.9874 16.0941 20.7223C16.5545 20.4573 16.7044 19.8563 16.4443 19.3981C16.1836 18.9293 15.5932 18.7763 15.1429 19.0413C14.6826 19.3059 14.5225 19.9074 14.7928 20.3656Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0409 18.358C13.2614 18.7452 13.1313 19.2443 12.7508 19.4688C12.3702 19.6928 11.8803 19.5605 11.6598 19.1729C11.4397 18.7862 11.5698 18.2865 11.9499 18.0625C12.3305 17.8385 12.8209 17.9712 13.0409 18.358Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6841 15.6179C17.9946 16.1682 17.8142 16.8813 17.2639 17.197C16.7232 17.5127 16.0225 17.3293 15.713 16.7688C15.4025 16.2189 15.5824 15.5063 16.1328 15.1901C16.6734 14.8744 17.364 15.0574 17.6841 15.6179Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0169 14.2425C19.5468 13.4069 19.8269 12.3477 20.6475 11.8586C21.4682 11.3797 22.5089 11.665 22.9895 12.5002C23.4596 13.3359 23.1791 14.3955 22.3585 14.8846C21.5383 15.3635 20.487 15.0782 20.0169 14.2425Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5826 22.6783C14.5028 22.2399 14.0922 21.9449 13.6619 22.0159C13.2315 22.0975 12.9413 22.5151 13.0114 22.9534C13.0912 23.3913 13.5018 23.6868 13.9322 23.6153C14.3723 23.5337 14.6527 23.1161 14.5826 22.6783Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8737 21.7403C19.7634 21.1084 19.173 20.6908 18.5627 20.8028C17.9422 20.9148 17.5317 21.5159 17.6419 22.1377C17.7517 22.7691 18.3426 23.1867 18.9529 23.0752C19.5632 22.9627 19.9839 22.3718 19.8737 21.7403Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5271 21.2624C22.6872 22.2096 23.578 22.8415 24.5089 22.6783C25.4393 22.5156 26.0602 21.609 25.8997 20.6613C25.7396 19.7141 24.8493 19.0826 23.918 19.2449C22.9774 19.4081 22.3569 20.3151 22.5271 21.2624Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0163 25.2759C20.1565 26.0908 20.9168 26.6412 21.7273 26.4881C22.5285 26.3457 23.0687 25.5714 22.9188 24.7463C22.7786 23.9309 22.0179 23.3815 21.2074 23.5336C20.4167 23.6866 19.8761 24.461 20.0163 25.2759Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.562 25.2043C15.6524 25.7339 16.152 26.0906 16.6728 25.9883C17.1932 25.897 17.5438 25.3877 17.4437 24.8577C17.3533 24.3281 16.8527 23.9717 16.3328 24.0732C15.812 24.1755 15.4619 24.6747 15.562 25.2043Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5824 22.7085C14.6627 23.1468 14.3721 23.5746 13.932 23.6456C13.5016 23.7272 13.0814 23.4322 13.0113 22.9837C12.9314 22.5458 13.2216 22.1181 13.6617 22.0466C14.0921 21.9646 14.5123 22.2605 14.5824 22.7085Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.874 21.7403C19.9843 22.3718 19.5737 22.9729 18.9533 23.0752C18.3328 23.1867 17.7424 22.7691 17.6423 22.1377C17.5422 21.5057 17.9426 20.9047 18.563 20.8028C19.1733 20.6908 19.7638 21.1084 19.874 21.7403Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5281 21.2624C22.368 20.3151 22.9885 19.4081 23.9189 19.2449C24.8502 19.0826 25.7406 19.7141 25.9006 20.6613C26.0607 21.609 25.4403 22.5156 24.5098 22.6783C23.5789 22.8415 22.6882 22.2096 22.5281 21.2624Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5824 27.2927C14.6627 26.8543 14.3721 26.427 13.932 26.3556C13.5016 26.274 13.0814 26.569 13.0113 27.0175C12.9314 27.4558 13.2216 27.8836 13.6617 27.955C14.0921 28.026 14.5123 27.731 14.5824 27.2927Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.874 28.2607C19.9843 27.6292 19.5737 27.0282 18.9533 26.9259C18.3328 26.8139 17.7424 27.2319 17.6423 27.8634C17.5422 28.4953 17.9426 29.0963 18.563 29.1978C19.1733 29.3102 19.7638 28.8926 19.874 28.2607Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5281 28.7395C22.368 29.6867 22.9885 30.5938 23.9189 30.7569C24.8502 30.9197 25.7406 30.2882 25.9006 29.3405C26.0607 28.3933 25.4403 27.4862 24.5098 27.3235C23.5789 27.1502 22.6882 27.7821 22.5281 28.7395Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8242 31.632C18.684 32.4469 19.2145 33.2213 20.0149 33.3743C20.8157 33.5167 21.5763 32.977 21.7267 32.1616C21.8665 31.3467 21.336 30.5723 20.5356 30.4198C19.7247 30.2773 18.9645 30.8171 18.8242 31.632Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6509 30.0131C14.5605 30.5427 14.9111 31.0418 15.4218 31.1441C15.9417 31.2354 16.4325 30.8787 16.5326 30.3597C16.6225 29.8296 16.2724 29.3304 15.7617 29.2286C15.2414 29.1267 14.751 29.4834 14.6509 30.0131Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5826 27.3235C14.5028 27.7618 14.0922 28.0568 13.6619 27.9858C13.2315 27.9042 12.9413 27.4866 13.0114 27.0483C13.0912 26.61 13.5018 26.315 13.9322 26.3864C14.3723 26.468 14.6527 26.8856 14.5826 27.3235Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8737 28.2604C19.7634 28.8923 19.173 29.3099 18.5627 29.1974C17.9422 29.0854 17.5317 28.4844 17.6419 27.8631C17.7517 27.2316 18.3426 26.8135 18.9529 26.926C19.5632 27.0279 19.9839 27.6289 19.8737 28.2604Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5271 28.739C22.6872 27.7918 23.578 27.1599 24.5089 27.323C25.4393 27.4862 26.0602 28.3928 25.8997 29.3405C25.7396 30.2877 24.8493 30.9192 23.918 30.7564C22.9774 30.5831 22.3569 29.6862 22.5271 28.739Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0409 31.6333C13.2614 31.2466 13.1313 30.7469 12.7508 30.5225C12.3702 30.2985 11.8803 30.4312 11.6598 30.8184C11.4397 31.2051 11.5698 31.7047 11.9499 31.9287C12.3305 32.1629 12.8209 32.0306 13.0409 31.6333Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6841 34.3832C17.9946 33.8328 17.8142 33.1202 17.2639 32.804C16.7232 32.4883 16.0225 32.6717 15.713 33.2322C15.4025 33.7821 15.5824 34.4952 16.1328 34.8109C16.6734 35.1266 17.364 34.9335 17.6841 34.3832Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0169 35.7585C19.5468 36.5941 19.8269 37.6529 20.6475 38.1424C21.4682 38.6208 22.5089 38.336 22.9895 37.5008C23.4596 36.6651 23.1791 35.6055 22.3585 35.1164C21.5383 34.6375 20.487 34.9228 20.0169 35.7585Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5614 37.1952C15.1513 37.9087 15.3912 38.8251 16.102 39.2431C16.8023 39.6607 17.7028 39.4164 18.1133 38.6928C18.5239 37.9793 18.2835 37.0629 17.5731 36.6449C16.8627 36.2273 15.9618 36.4716 15.5614 37.1952Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1897 34.2102C11.9295 34.6785 12.0794 35.2694 12.5398 35.5344C13.0002 35.7995 13.5805 35.6464 13.8411 35.1781C14.1013 34.7094 13.9514 34.1185 13.491 33.8534C13.0403 33.5884 12.46 33.7511 12.1897 34.2102Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0204 31.6635C12.8004 32.0503 12.31 32.183 11.9294 31.959C11.5493 31.735 11.4192 31.2358 11.6393 30.8486C11.8593 30.461 12.3497 30.3287 12.7302 30.5532C13.1108 30.7772 13.2405 31.2763 13.0204 31.6635Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6821 34.3827C17.3716 34.933 16.6713 35.1262 16.1307 34.8104C15.5905 34.4947 15.4005 33.7816 15.7105 33.2317C16.0205 32.6814 16.7212 32.4878 17.2618 32.8035C17.8121 33.1299 17.9925 33.8323 17.6821 34.3827Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0169 35.7585C20.487 34.9228 21.5383 34.6375 22.3585 35.1164C23.1791 35.5957 23.4596 36.6651 22.9895 37.5008C22.5186 38.336 21.4682 38.6208 20.6475 38.1428C19.8269 37.6529 19.5468 36.5941 20.0169 35.7585Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1286 35.178C10.4686 34.8927 10.5092 34.3834 10.2287 34.0368C9.94873 33.6906 9.44822 33.6496 9.10779 33.9354C8.7678 34.2207 8.72767 34.73 9.00769 35.0762C9.28771 35.4228 9.78865 35.4633 10.1286 35.178Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5612 39.3752C14.0414 38.9678 14.1014 38.234 13.7014 37.7454C13.301 37.2564 12.58 37.1946 12.1003 37.6025C11.6196 38.01 11.5592 38.7433 11.96 39.2324C12.3701 39.7214 13.0805 39.7928 13.5612 39.3752Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2927 41.4743C14.5721 42.0956 14.472 43.1959 15.0823 43.9292C15.6931 44.663 16.7739 44.7649 17.4944 44.1435C18.215 43.5222 18.3151 42.4215 17.7048 41.6882C17.1042 40.9549 16.0233 40.8631 15.2927 41.4743Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.628 41.2812C10.0075 41.8108 9.92726 42.7585 10.448 43.39C10.968 44.0214 11.8989 44.103 12.5193 43.5734C13.1402 43.0442 13.22 42.0961 12.6992 41.4647C12.1793 40.8332 11.2484 40.7516 10.628 41.2812Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46784 37.3078C8.06744 37.654 8.00747 38.2651 8.3479 38.6832C8.68789 39.0902 9.2885 39.1515 9.69904 38.8053C10.1091 38.4587 10.159 37.8475 9.81899 37.4299C9.47856 37.0123 8.86825 36.9612 8.46784 37.3078Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0974 35.199C9.75736 35.4843 9.25686 35.4433 8.9764 35.0971C8.69638 34.7505 8.73651 34.2412 9.07694 33.9559C9.41693 33.6706 9.91744 33.7111 10.1975 34.0578C10.4779 34.4044 10.4378 34.9137 10.0974 35.199Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5612 39.3762C13.0805 39.7837 12.36 39.7224 11.96 39.2333C11.5592 38.7443 11.6196 38.011 12.1003 37.6035C12.5805 37.1956 13.301 37.2573 13.7014 37.7464C14.112 38.2451 14.0414 38.9688 13.5612 39.3762Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2924 41.4741C16.0129 40.8528 17.0933 40.9546 17.7041 41.688C18.3148 42.4213 18.2147 43.522 17.4942 44.1433C16.7732 44.7647 15.6923 44.6623 15.082 43.929C14.4819 43.1957 14.5718 42.0954 15.2924 41.4741Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19597 37.4907C6.60608 37.3377 6.82656 36.8694 6.67619 36.4518C6.52626 36.0337 6.06588 35.8093 5.65534 35.9627C5.24523 36.1153 5.02519 36.584 5.17512 37.0016C5.32505 37.4294 5.78543 37.6437 6.19597 37.4907Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01427 42.6354C8.60473 42.4215 8.90503 41.749 8.69469 41.1585C8.48434 40.5575 7.82421 40.2515 7.24344 40.4658C6.66312 40.6792 6.35268 41.3517 6.56302 41.9426C6.77337 42.5436 7.42424 42.8598 8.01427 42.6354Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.93821 45.2118C8.04745 45.5377 7.58707 46.5466 7.9178 47.4431C8.23795 48.3498 9.22837 48.8185 10.109 48.4825C10.9998 48.1562 11.4601 47.1477 11.1299 46.2507C10.8097 45.3547 9.82898 44.8859 8.93821 45.2118Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.62241 43.3993C3.86218 43.6846 3.46177 44.5401 3.74179 45.325C4.02181 46.0989 4.86274 46.5068 5.63313 46.2211C6.3938 45.9358 6.79421 45.0803 6.51419 44.2958C6.23373 43.5215 5.38309 43.114 4.62241 43.3993Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.92084 38.9166C3.42034 39.1 3.1703 39.6605 3.35022 40.1592C3.53058 40.6686 4.08091 40.9234 4.57128 40.74C5.06164 40.5566 5.32181 39.9965 5.14145 39.4969C4.96154 38.988 4.4112 38.7331 3.92084 38.9166Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16269 37.501C5.75258 37.654 5.29221 37.4401 5.14228 37.0119C4.99191 36.5943 5.20181 36.1256 5.6225 35.973C6.03304 35.8204 6.49342 36.0339 6.64335 36.462C6.79328 36.8898 6.58294 37.348 6.16269 37.501Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01532 42.6354C7.42485 42.8492 6.77442 42.5436 6.56407 41.9426C6.35373 41.3416 6.65447 40.6792 7.24449 40.4658C7.8354 40.2515 8.48539 40.5575 8.69574 41.1585C8.91578 41.7596 8.60534 42.4215 8.01532 42.6354Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.93617 45.213C9.82693 44.8871 10.8077 45.3453 11.1278 46.2519C11.4484 47.159 10.9977 48.1569 10.107 48.4837C9.21663 48.8091 8.23546 48.3505 7.91531 47.4443C7.59517 46.5478 8.05554 45.5389 8.93617 45.213Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.73201 38.3056C2.17254 38.3056 2.53237 37.9392 2.53237 37.4907C2.53237 37.0422 2.17254 36.6758 1.73201 36.6758C1.29147 36.6758 0.931641 37.0422 0.931641 37.4907C0.931641 37.9392 1.29147 38.3056 1.73201 38.3056Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71313 43.7662C2.34372 43.7662 2.84378 43.2467 2.84378 42.6152C2.84378 41.9732 2.33358 41.4639 1.71313 41.4639C1.09224 41.4639 0.582031 41.9732 0.582031 42.6152C0.582031 43.2569 1.08254 43.7662 1.71313 43.7662Z"
        fill="#EB7700"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="46"
        width="4"
        height="4"
      >
        <path d="M0 46.5159H3.42284V49.9999H0V46.5159Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.71142 46.5159C0.760679 46.5159 0 47.3004 0 48.2582C0 49.2261 0.77038 50 1.71142 50C2.66216 50 3.42284 49.2159 3.42284 48.2582C3.42284 47.2902 2.66216 46.5159 1.71142 46.5159Z"
          fill="#EB7700"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70173 38.3056C1.2612 38.3056 0.901367 37.9392 0.901367 37.4907C0.901367 37.0422 1.2612 36.6758 1.70173 36.6758C2.14227 36.6758 2.5021 37.0422 2.5021 37.4907C2.4924 37.9392 2.14227 38.3056 1.70173 38.3056Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71313 43.7662C1.08254 43.7662 0.582031 43.2467 0.582031 42.6152C0.582031 41.9732 1.09224 41.4639 1.71313 41.4639C2.33358 41.4639 2.84378 41.9833 2.84378 42.6152C2.85393 43.2569 2.34372 43.7662 1.71313 43.7662Z"
        fill="#EB7700"
      />
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="46"
        width="4"
        height="4"
      >
        <path d="M0 46.5159H3.42284V49.9999H0V46.5159Z" fill="white" />
      </mask>
      <g mask="url(#mask1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.71142 46.5159C2.66216 46.5159 3.42284 47.3004 3.42284 48.2582C3.42284 49.2261 2.65201 50 1.71142 50C0.760679 50 0 49.2159 0 48.2582C0 47.2902 0.77038 46.5159 1.71142 46.5159Z"
          fill="#EB7700"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.2202 20.284C42.1019 20.284 44.4285 22.4541 44.4285 25.3363C44.4285 28.2185 42.1019 30.3881 39.2202 30.3881H35.9565C35.5394 30.3881 35.21 30.0587 35.21 29.6415V21.0134C35.21 20.5963 35.5394 20.2668 35.9565 20.2668H39.2202V20.284ZM36.7031 21.7079V28.965H39.2374C41.2866 28.965 42.953 27.385 42.953 25.3363C42.953 23.2875 41.2866 21.7079 39.2202 21.7079H36.7031Z"
        fill="#F8F9FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.6121 20.2139C64.2722 20.2139 64.8097 20.7523 64.8097 21.4116C64.8097 22.0717 64.2722 22.5753 63.6121 22.5753C62.9524 22.5753 62.4492 22.0717 62.4492 21.4116C62.4492 20.7523 62.9524 20.2139 63.6121 20.2139Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.3525 21.7071H49.6196C49.2033 21.7071 48.873 21.4121 48.873 20.9954C48.873 20.5787 49.2033 20.2832 49.6196 20.2832H58.5436C58.9603 20.2832 59.2902 20.5787 59.2902 20.9954C59.2902 21.4121 58.9603 21.7071 58.5436 21.7071H54.8284V29.7108C54.8284 30.1275 54.4985 30.4573 54.0818 30.4573C53.6647 30.4573 53.3353 30.1275 53.3353 29.7108V21.7071H53.3525Z"
        fill="#F8F9FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3584 29.7106C64.3584 30.1273 64.0281 30.4572 63.6118 30.4572C63.1951 30.4572 62.8652 30.1273 62.8652 29.7106V25.1792C62.8652 24.7629 63.1951 24.4326 63.6118 24.4326C64.0281 24.4326 64.3584 24.7629 64.3584 25.1792V29.7106Z"
        fill="#F8F9FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.355 20.2139C122.015 20.2139 122.553 20.7523 122.553 21.4116C122.553 22.0717 122.015 22.5753 121.355 22.5753C120.696 22.5753 120.192 22.0717 120.192 21.4116C120.192 20.7523 120.696 20.2139 121.355 20.2139Z"
        fill="#EB7700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.104 29.7106C122.104 30.1273 121.774 30.4572 121.358 30.4572C120.941 30.4572 120.611 30.1273 120.611 29.7106V25.1792C120.611 24.7629 120.941 24.4326 121.358 24.4326C121.774 24.4326 122.104 24.7629 122.104 25.1792V29.7106Z"
        fill="#F8F9FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.3421 30.5439C71.4604 30.5439 69.1338 28.2177 69.1338 25.3355C69.1338 22.4533 71.4604 20.1272 74.3421 20.1272C75.8009 20.1272 77.0854 20.7 78.0401 21.6375C78.3351 21.933 78.3351 22.3841 78.0401 22.6795C77.7451 22.9746 77.2587 23.009 76.9637 22.7144C76.2692 22.0371 75.3837 21.6203 74.3421 21.6203C72.2938 21.6203 70.6269 23.2868 70.6269 25.3355C70.6269 27.3843 72.2938 29.0512 74.3421 29.0512C75.3837 29.0512 76.304 28.6688 76.9986 27.9743C77.2936 27.6793 77.7451 27.6793 78.0401 27.9743C78.3351 28.2698 78.3351 28.7209 78.0401 29.0163C77.0854 29.971 75.8009 30.5439 74.3421 30.5439Z"
        fill="#F8F9FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.0384 25.3359L91.7541 29.2593C92.0143 29.5203 92.1183 30.0063 91.8061 30.2669C91.5107 30.5619 90.9378 30.475 90.6428 30.1447L86.5105 25.7698H84.5142V29.6936C84.5142 30.1103 84.1843 30.4402 83.7676 30.4402C83.3509 30.4402 83.0215 30.1103 83.0215 29.6936V20.9606C83.0215 20.5443 83.3509 20.2144 83.7676 20.2144C84.1843 20.2144 84.5142 20.5443 84.5142 20.9606V24.3464H87.0145L90.3474 20.4751C90.6428 20.18 91.2156 20.0579 91.5107 20.3534C91.8061 20.6135 91.7192 21.0999 91.4586 21.3605L88.0384 25.3359Z"
        fill="#F8F9FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.493 28.9646C103.91 28.9646 104.239 29.2596 104.239 29.6768C104.239 30.0935 103.91 30.3885 103.493 30.3885H96.3403C95.9232 30.3885 95.5938 30.0582 95.5938 29.6419V21.013C95.5938 20.5967 95.9232 20.2668 96.3403 20.2668H103.493C103.91 20.2668 104.239 20.5619 104.239 20.9786C104.239 21.3953 103.91 21.6903 103.493 21.6903H97.0869V24.4852H102C102.417 24.4852 102.747 24.7806 102.747 25.1974C102.747 25.6136 102.417 25.9091 102 25.9091H97.0869V28.9474H103.493V28.9646Z"
        fill="#F8F9FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.095 21.7071H107.379C106.962 21.7071 106.633 21.4121 106.633 20.9954C106.633 20.5787 106.962 20.2832 107.379 20.2832H116.303C116.72 20.2832 117.049 20.5787 117.049 20.9954C117.049 21.4121 116.72 21.7071 116.303 21.7071H112.588V29.7108C112.588 30.1275 112.257 30.4573 111.841 30.4573C111.425 30.4573 111.095 30.1275 111.095 29.7108V21.7071Z"
        fill="#F8F9FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.552 22.7496V29.7116C129.552 30.1279 129.222 30.4578 128.805 30.4578C128.388 30.4578 128.059 30.1279 128.059 29.7116V20.9614C128.059 20.5447 128.388 20.2148 128.805 20.2148C129.101 20.2148 129.257 20.3013 129.552 20.5967L136.809 27.9231V20.9614C136.809 20.5447 137.139 20.2148 137.555 20.2148C137.972 20.2148 138.302 20.5447 138.302 20.9614V29.7116C138.302 30.1279 137.972 30.4578 137.555 30.4578C137.26 30.4578 137.104 30.3709 136.809 30.0759L129.552 22.7496Z"
        fill="#F8F9FA"
      />
      <mask
        id="mask2"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="143"
        y="20"
        width="10"
        height="11"
      >
        <path
          d="M143.076 20.1108H152.225V30.5275H143.076V20.1108Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150.732 26.0481C150.732 25.6318 151.063 25.3359 151.479 25.3359C151.896 25.3359 152.225 25.6662 152.225 26.0829V28.4267C152.225 28.6005 152.139 28.8778 151.982 29.0167C151.027 29.9714 149.743 30.5275 148.285 30.5275C145.402 30.5275 143.076 28.2009 143.076 25.3192C143.076 22.437 145.402 20.1108 148.285 20.1108C149.743 20.1108 151.027 20.6832 151.982 21.6207C152.278 21.9162 152.278 22.3673 151.982 22.6628C151.687 22.9582 151.201 22.9926 150.906 22.6971C150.211 22.0207 149.327 21.6035 148.285 21.6035C146.236 21.6035 144.569 23.2704 144.569 25.3192C144.569 27.3679 146.236 29.0339 148.285 29.0339C149.239 29.0339 150.072 28.7045 150.732 28.1489V26.0481Z"
          fill="#F8F9FA"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8064 28.0789C47.4665 28.0789 48.0041 28.6173 48.0041 29.2765C48.0041 29.9367 47.4665 30.4398 46.8064 30.4398C46.1467 30.4398 45.6436 29.9367 45.6436 29.2765C45.6436 28.6173 46.1467 28.0789 46.8064 28.0789Z"
        fill="#EB7700"
      />
    </svg>
  );
}

export default DTicketingLogo;
