import classes from "./ProgressBar.module.css";
import CheckCircle from "../icons/CheckCircle";

const ProgressBar = ((props) => {
  const {
    currentStep,
    steps
  } = props

  const todoStep = ((index) => {
    return (
      <div className={`${classes.dot} ${(currentStep == (index+1)) ? 'bg-app-defaultColor' : 'bg-app-disabledgrey'}`}>
        <div className={classes.dotWhite}/>
      </div>
    )
  })
  
  return (
    <div className={`${classes.wrapper} mb-6`}>
      {
        steps.map((step, index) => {
          return(
            <span className={`${classes.stepWrapper} flex flex-col`} key={index}>
              <div className={classes.dotsWrapper}>
                {(index+1 < currentStep) && 
                  <div className={classes.lightGreyCircle}>
                    <div className={classes.completedStep}>
                      <CheckCircle/>
                    </div>
                  </div>
                }
                {(index+1 >= currentStep) && todoStep(index)}
              </div>

              {(index+1 < steps.length) && <div className={classes.line}/>}
              <div className='ass-modal-desc'>
                Step {index + 1}
              </div>
              <div className='text-center'>
                {step}
              </div>
            </span>
          )
        }
      )}
    </div>
  )
})

export default ProgressBar;
