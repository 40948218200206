import { useState, useEffect, useRef } from "react";
import classes from "./EditableTable.module.css";
import { Button } from "..";
import useTranslation from "next-translate/useTranslation";
import { REGEXPS_MAP } from "@develon/dticketing-form-generator/dist/constants/REGEXPS";
import Danger from "@components/icons/ticketsWizard/Danger";
import VerifiedIcon from '@mui/icons-material/Verified';
import DownloadXlsx from "@components/icons/DownloadXlsx";

import Calendar from "@components/icons/ticketsWizard/Calendar";
import CircularProgress from '@mui/material/CircularProgress';
import { conditionIsMet } from "@develon/dticketing-form-generator/dist/utils/validating";
import { AutocompleteField } from "../autocompleteField";
import { nextAPI } from "../../api/axiosAPI";
import { TicketsWizardEmailField } from "../ticketsWizardEmailField"
import { DayPicker } from "react-day-picker"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { it, en } from "date-fns/locale";
import { useScrollState } from "./useScrollState";
import { isObject } from "lodash";
import Tooltip from '@mui/material/Tooltip';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { format } from "date-fns";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { 
  TICKETS_WIZARD_PRODUCT
} from "@constants"


const EditableTable = ((props) => {
  const {
    kind,
    disabled: wholeTableDisabled,
    formFields,
    onlineValidatingRows,
    onUpdate,
    onUpdateErrors,
    onUpdateOnlineValidatingRows,
    onUpdateProductAvailableQuantity,
    onValidateEmails,
    originalAvailableQuantity,
    productAvailableQuantity,
    selectedErrorCoordinates = [],
    showHeaders,
    startIndex,
    tableData,
    tableErrors,
    tableGroups,
    tableHeaders,
    confirmedColumns,
    visibleGroups,
    handleVisibleGroups,
    addRowEnabled,
    tableMaxRows,
    countries,
    unassignableRows,
    listOfPrevalidatedCities,
    TABLE_GROUPS_BUTTON_COLORS,
    TABLE_GROUPS_BUTTON_BG_COLORS,
    downloadXlsx,
    //modality
  } = props

  const translationCode = `tickets_wizard${process.env.LOCALES_SUFFIX}`
  const { t, lang } = useTranslation()
  const [checkedRows, setCheckedRows] = useState([])
  const [cities, setCities] = useState([])
  const [emailsValidations, setEmailsValidations] = useState([])
  const [showDayPickers, setShowDayPickers] = useState([])
  const [lastUnassignableRowsCount, setLastUnassignableRowsCount] = useState(null)
  const [abortControllers, setAbortControllers] = useState({});

  const cellRef = useRef(null);
  const datePickerRef = useRef()
  const scrollContainerRef = useRef(null);
  const onlineValidateTheRowCounterTimeouts = useRef({})

  const [scrollTableLeftDisabled, scrollTableRightDisabled] = useScrollState(scrollContainerRef, confirmedColumns)
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });


  const scrollTableLeft = () => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollBy({
          left: -100,
          behavior: 'smooth'
        })}, 100)
    }
  };

  const scrollTableRight = () => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollBy({
          left: 100,
          behavior: 'smooth'
        })}, 100)
    }
  };
  
  const cellErrors = (rowIndex, colIndex) => {
    if (!tableErrors) return

    if (!tableErrors[rowIndex]) return

    return tableErrors[rowIndex][colIndex]
  }

  const cellVisibleOrHidden = (colIndex) => {
    if (!formFields || formFields.length === 0) return ''

    const formField = formFields[colIndex]

    if (!formField) return ''

    if(visibleGroups.includes(formField.display.group.key) && !formField.attributes?.ghost) {
      return ''
    }
    return ' hidden'
  }

  const clearValueOnDependencyChange = (colIndex, changedColIndexes) => {
    if(formFields[colIndex].dependencies && formFields[colIndex].dependencies.enabled) {
      const targetColIndex = targetFieldColIndex(colIndex)
      return changedColIndexes.includes(targetColIndex)
    }
  }

  const populateNewTableRow = () => {
    let newRow = []
    onUpdateProductAvailableQuantity(productAvailableQuantity - 1)
    formFields.forEach((formField) => {
      if (formField.attributes.key === 'invites_quantity') {
        newRow.push(1)
      } else {
        newRow.push('')
      }
    })
    setEmailsValidations([...emailsValidations, null])
    return newRow
  }

  const deleteSelectedRows = () => {
    const newTableData = tableData.filter((row, index) => !checkedRows.includes(index))
    const newTableErrors = tableErrors.filter((row, index) => !checkedRows.includes(index))
    const newEmailsValidations = emailsValidations.filter((emailValidation, index) => !checkedRows.includes(index))
    if (newTableData.length === 0) {
      newTableData.push(populateNewTableRow())
      newTableErrors.push(formFields.map((field, index) => {
        return validateField(newTableData, index, 0)
      }))
      newEmailsValidations.push(null)
    }
    const quantityThatWillBeRetrievedFromDeletion = totalCheckedRowsInvitationsQuantity()
    onUpdateProductAvailableQuantity(quantityThatWillBeRetrievedFromDeletion)
    setCheckedRows([])
    onUpdate(newTableData)
    setEmailsValidations(newEmailsValidations)
    onUpdateErrors(newTableErrors)
    scrollContainerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const addDataRow = () => {
    if (tableData.length > tableMaxRows || productAvailableQuantity <= 0) return
    const newData = [...tableData]
    const newTableRow = populateNewTableRow()
    newData.push(newTableRow)
    let newTableErrors = [...tableErrors]
    const newRowError = newTableRow.map((cellData, colIndex) => {
      return validateField(newData, colIndex, newData.length - 1)
    })
    newTableErrors.push(newRowError)
    onUpdate(newData)
    onUpdateErrors(newTableErrors)
    scrollContainerRef.current.scrollTo({
      top: scrollContainerRef.current.scrollHeight,
      behavior: 'smooth'
    })
  }

  const dependenciesValidations = (currentTableData, rowIndex, colIndex) => {
    if(formFields[colIndex].validations.required) return true

    return mustBePresentForDependencies(currentTableData, rowIndex, colIndex)
  }

  const disableInput = (value, rowIndex, colIndex) => {
    if(wholeTableDisabled) return wholeTableDisabled

    if(isObject(formFields[colIndex]?.dependencies) && formFields[colIndex]?.dependencies.enabled) {
      return (formFields[colIndex].dependencies.enabled === true && !mustBePresentForDependencies(tableData, rowIndex, colIndex))
    }
    return false
  }

  const filterOptionData = (optionsDatas, conditionCode) => {
    return optionsDatas.find(optionsData => {
      return optionsData.code === conditionCode
    })
  }

  const getCities = async (rowIndex, cityValue) => {
    const nationIndex = formFields.findIndex(field => field.attributes.key === 'nation')
    const nationValue = tableData[rowIndex][nationIndex]
    const citiesDatasFromApi = await nextAPI.post(`/addresses/city?nation=${nationValue}&name=${cityValue}`)

    const citiesFromApi = JSON.parse(citiesDatasFromApi.data.data)

    let citiesArray = [...cities]

    if(!citiesArray[rowIndex]) {
      citiesArray[rowIndex] = []
    }

    citiesArray[rowIndex] = citiesFromApi.map((city) => {
      return {code: city.name, label: {it: city.label, en: city.label}}
    })
    setCities(citiesArray)
  }

  const getConditions = (conditions, targetFieldValue) => {
    return conditions.find(condition => {
      return conditionIsMet(targetFieldValue, condition.operator, condition.value)
    })
  }

  const mustBePresentForDependencies = (currentTableData, rowIndex, colIndex) => {
    const formField = formFields[colIndex]
    const dependencies = formField.dependencies
    if(dependencies && dependencies.enabled) {
      const targetFieldValue = currentTableData[rowIndex][targetFieldColIndex(colIndex)]

      if(!targetFieldValue) return false

      const mustValidate = getConditions(dependencies.conditions, targetFieldValue)
      if(mustValidate) return true

      return false
    }
    return false
  }

  const onlineValidateTheRow = async (rowData, rowIndex, newTableErrors) => {
    onUpdateOnlineValidatingRows(rowIndex, "add")
    // Create a new AbortController for this row
    const abortController = new AbortController();

    // Store the AbortController in state
    setAbortControllers(prevControllers => ({
      ...prevControllers,
      [rowIndex]: abortController,
    }));

    const typology = sessionStorage.getItem('ticketsWizardTicketTypology')
    let product_code = null;
    try {
      if(typology === 'heading') {
        try {
          product_code = JSON.parse(sessionStorage.getItem(TICKETS_WIZARD_PRODUCT))?.code
        } catch (error) {}
      }
      const remappedRowData = {}
      formFields.forEach((field, index) => {
        remappedRowData[field.attributes.key] = rowData[index]
      })
      const availableDates = JSON.parse(sessionStorage.getItem('ticketsWizardAvailableDates'))
      if (availableDates.reservationsEnabled && availableDates.hidden && (availableDates.max === availableDates.min) ) {
        remappedRowData["dates"] = availableDates.dates.map((dateObj) => { return new Date(dateObj.date) })
      }
      const {
        data: {
          isValid,
          errors,
        }
      } = await nextAPI.post(`/builder/validate?mode=${typology}`, {
        formData: remappedRowData,
        product_code,
        lang
      }, { signal: abortController.signal })
      if(isValid === false) {
        const remappedErrors = []
        formFields.forEach((field) => {
          const _errors = errors[field.attributes.key] ? [errors[field.attributes.key]] : []
          remappedErrors.push(_errors)
        })
        newTableErrors[rowIndex] = remappedErrors
      }
    } catch (error) {
      //console.error(error)
    } finally {
      onUpdateErrors(newTableErrors)
      onUpdateOnlineValidatingRows(rowIndex, "remove")
    }
  }

  const optionsList = (rowIndex, colIndex) => {
    const formField = formFields[colIndex]
    const dependencies = formField.dependencies
    if(dependencies.enabled && dependencies.multiOption && dependencies.target !== "") {
      const targetFieldValue = tableData[rowIndex][targetFieldColIndex(colIndex)]

      if(!targetFieldValue) return []

      const condition = getConditions(dependencies.conditions, targetFieldValue)

      if(!condition) return []

      const filteredOptionData = filterOptionData(formField.optionsData, condition.code)
      const options = filteredOptionData.options || []
      return options || []
    } else {
      return formField.optionsData[0].options || []
    }
  }

  const populateEmailsValidations = (data, rowIndex) => {
    if (wholeTableDisabled) return
    const email = {
      email: data.email,
      id: data.request_uuid,
      status: data.status,
      row: rowIndex + 1,
      valid: data.valid
    }
    setEmailsValidations((previousData) => {
      const newData = [...previousData]
      newData[rowIndex] = email
      return newData
    })
  }

  const putNumberInRange = (value, min, max) => {
    if(parseInt(value) < min) return min
    if(parseInt(value) > max) return max
    return parseInt(value)
  }

  const renderAutocompleteField = (value, rowIndex, colIndex, options, listName) => {
    if(listName.includes('countries')) {
      value = countries.find((country) => country.code === value)?.label[lang]
    }
    return (
      <div className={classes.cellInput}>
        <AutocompleteField
          disabled={disableInput(value, rowIndex, colIndex)}
          options={options}
          value={value}
          onChange={(e) => updateAutocomplete(e.target.value, rowIndex, colIndex, listName)}
          listName={listName}
        />
      </div>
    )
  }

  const renderAutocomplete = (value, rowIndex, colIndex) => {
    const fieldKey = formFields[colIndex].attributes.key
    switch (fieldKey) {
      case "nation":
        return renderAutocompleteField(value, rowIndex, colIndex, countries, 'countries')
      case "city":
        const citiesOptions = cities[rowIndex] || []
        return renderAutocompleteField(value, rowIndex, colIndex, citiesOptions, `cities-${rowIndex}`)
    }
  }

  const formatDates = (dates) => {
    let formattedDates = []
    if (dates) {
      const selectedDates = dates.map((date) => { return new Date(date) })
      formattedDates = selectedDates.map((date) => {
        return date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit' })
      })
    }
    return formattedDates
  }

  const renderDatePicker = (values, rowIndex, colIndex) => {
    const datesLength = formFields[colIndex].optionsData.length
    const firstDate = new Date(formFields[colIndex].optionsData[0].date)
    const lastDate = new Date(formFields[colIndex].optionsData[datesLength - 1].date)
    const availableDates = JSON.parse(sessionStorage.getItem('ticketsWizardAvailableDates'))
    let selectedDates = []
    if (values) {
      selectedDates = values.map((date) => { return new Date(date) })
    } 
    const formattedDates = formatDates(values)
    const disabledDayPicker = disableInput(values, rowIndex, colIndex)

    return (
      <>
        <a
          href="#"
          onClick={(e) => disabledDayPicker ? '' : toggleDayPicker(e, rowIndex)}
          className={`flex items-center gap-x-3 ${classes.cellInput}`}>
          <input
            type="text"
            value={formattedDates.join(', ')}
            disabled={disabledDayPicker}
            title={formattedDates.join(', ')}
            className={`cursor-pointer ${classes.cellInput}`}
            onClick={(e) => disabledDayPicker ? '' : toggleDayPicker(e, rowIndex)}/>
          <div>
            <Calendar />
          </div>
        </a>

        { showDayPickers[rowIndex] &&
          <div ref={datePickerRef}>
            <DayPicker
              locale={lang === 'it' ? it : en}
              defaultMonth={firstDate}
              mode="multiple"
              max={availableDates.max}
              fromDate={firstDate}
              toDate={lastDate}
              selected={selectedDates}
              onSelect={(dates) => {
                updateCell(dates, rowIndex, colIndex)
                if (dates.length === availableDates.max) {
                  handleCloseDayPicker()
                }
              }}
              className={classes.dayPicker}
              onBlur={() => handleClickOutside(rowIndex)}
            />
          </div> }
      </>
    )
  }

  const renderEmailInput = (value, rowIndex, colIndex) => {
    return (
      <div className={classes.cellInput}>
        <TicketsWizardEmailField
          disabled={disableInput(value, rowIndex, colIndex)}
          errors={cellErrors(rowIndex, colIndex) || []}
          onChange={(e) => updateCell(e.target.value, rowIndex, colIndex)}
          validationCallback={(data) => populateEmailsValidations(data, rowIndex)}
          value={value}
        />
      </div>
    )
  }

  const renderField = (value, rowIndex, colIndex) => {
    const {
      attributes: {
        type: formFieldType,
        input_variant: inputVariant
      }
    } = formFields[colIndex]
    switch (formFieldType) {
      case 'AutoComplete':
        return renderAutocomplete(value, rowIndex, colIndex)
        break
      case 'Dropdown':
        return renderSelect(value, rowIndex, colIndex)
        break
      case 'DatePicker':
        return renderDatePicker(value, rowIndex, colIndex)
        break
      case 'Email':
        return renderEmailInput(value, rowIndex, colIndex)
        break
      case 'Number':
        return renderNumberInput(value, rowIndex, colIndex)
        break
      case 'TextInput':
        return renderTextInput(value, inputVariant, rowIndex, colIndex)
      default:
        return renderTextInput(value, inputVariant, rowIndex, colIndex)
    }
  }

  const renderMainTableFormFields = (cellData, rowIndex, colIndex) => {
      const isMail = formFields[colIndex].attributes.type === 'Email'
      const hasFormalErrors = confirmedColumns && cellErrors(rowIndex, colIndex)?.length > 0;
      
      let iconToShow = null
      
      if(isMail) {
        const hasServiceValidationErrors = emailsValidations && emailsValidations[rowIndex] && emailsValidations[rowIndex].valid === false

        if (hasFormalErrors) {
          iconToShow = <Danger />
        } else if (hasServiceValidationErrors) {
          iconToShow = (<DangerousIcon
            className={`text-app-redLight !text-lg ml-1`}
          />)
        } else if (!hasServiceValidationErrors && emailsValidations[rowIndex] && emailsValidations[rowIndex].valid === true) {
          iconToShow = <VerifiedIcon
            className={`text-app-checkGreen !text-lg ml-1`}
          />
        }

      } else {
        if (hasFormalErrors) {
          iconToShow = <Danger />
        }
      }

    return (
      <>
        {renderField(cellData, rowIndex, colIndex)}
        {iconToShow}
      </>
    );
  }

  const renderNumberInput = (value, rowIndex, colIndex) => {
    return (
      <input
        disabled={disableInput(Number(value), rowIndex, colIndex)}
        type="number"
        // min={formFields[colIndex]?.validations?.min}
        // max={productAvailableQuantity}
        required={formFields[colIndex]?.validations?.required}
        value={Number(value)}
        className={classes.cellInput}
        pattern="[0-9]*"
        onChange={(e) => { 
          updateCell(Number(e.target.value), rowIndex, colIndex)
        }}/>
    )
  }

  const renderSelect = (value, rowIndex, colIndex) => {
    const disabled = disableInput(value, rowIndex, colIndex)
    const className = `${classes.cellInput} ${disabled ? 'cursor-not-allowed' : ''}`

    const render = disabled ? (
      <Tooltip
        title={t(`${translationCode}:step_5.disabled_input_dependencies`)}
        placement="bottom"
      >
        <select
          disabled={disabled}
          value={value || ''}
          className={className}
          onChange={(e) => updateCell(e.target.value, rowIndex, colIndex)}>
          <option value="" />
          {renderSelectOptions(rowIndex, colIndex)}
        </select>
      </Tooltip>
    ) : (
    <select
      disabled={disabled}
      value={value || ''}
      className={className}
      onChange={(e) => updateCell(e.target.value, rowIndex, colIndex)}>
      <option value="" />
      {renderSelectOptions(rowIndex, colIndex)}
    </select>
    )

    return (
      render
    )
  }

  const renderSelectOptions = (rowIndex, colIndex) => {
    const optionsData = formFields[colIndex].optionsData
    if(optionsData[0].mode === 'host') return
    if(optionsData[0].options?.length > 0) {
      const options = optionsList(rowIndex, colIndex)
      return ( options.map((option, index) => {
        return (
          <option
            key={index}
            value={option.value}>
              {option.label[lang]}
          </option>
        )
      }))
    }
  }

  const renderTextInput = (value, inputVariant, rowIndex, colIndex) => {
    let castedValue = value
    if (!inputVariant || inputVariant === 'text') {   // The excel file is passing phone numbers as numbers, so we need to cast them to string
      castedValue = String(value)
    }
    const disabled = disableInput(value, rowIndex, colIndex)
    const className = `${classes.cellInput} ${disabled ? 'cursor-not-allowed' : ''}`

    const render = disabled ? (
      <Tooltip
        title={t(`${translationCode}:step_5.disabled_input_dependencies`)}
        placement="bottom"
      >
        <input
          className={className}
          disabled={disabled}
          max={formFields[colIndex]?.validations?.max}
          min={formFields[colIndex]?.validations?.min}
          onChange={(e) => updateCell(e.target.value, rowIndex, colIndex)}
          required={formFields[colIndex]?.validations?.required}
          type={inputVariant || 'text'}
          value={castedValue || ''}
        />
      </Tooltip>
    ) : (
      <input
        className={className}
        disabled={disabled}
        max={formFields[colIndex]?.validations?.max}
        min={formFields[colIndex]?.validations?.min}
        onChange={(e) => updateCell(e.target.value, rowIndex, colIndex)}
        required={formFields[colIndex]?.validations?.required}
        type={inputVariant || 'text'}
        value={castedValue || ''}
      />
    )

    return (
      render
    )
  }

  const rowErrors = (rowIndex) => {
    if (!tableErrors) return

    if (!tableErrors[rowIndex]) return

    return tableErrors[rowIndex]
  }

  const rowInvalidClasses = (rowIndex) => {
    if(confirmedColumns) {
      if(rowErrors(rowIndex)?.some(error => error.length > 0)){
        return classes.invalidRow
      }
    }
    return ''
  }

  const selectTableRow = (rowIndex) => {
    if (!wholeTableDisabled) {
      const isChecked = checkedRows.includes(rowIndex)
      if (isChecked) {
        setCheckedRows(checkedRows.filter(row => row != rowIndex))
      } else {
        setCheckedRows([...checkedRows, rowIndex])
      }
    }
  }

  const tableGroupsHeaderStyle = (groupKey) => {
    const tableGroupsKeys = tableGroups.map((tableGroup) => tableGroup.key)
    const index = tableGroupsKeys.indexOf(groupKey)
    const color = TABLE_GROUPS_BUTTON_COLORS[index]
    const bgColor = TABLE_GROUPS_BUTTON_BG_COLORS[index]
    return {
      //borderTopColor: color,
      //borderLeftColor: color,
      //borderRightColor: color,
      color: color,
      backgroundColor: bgColor
    }
  }

  const targetFieldColIndex = (colIndex) => {
    return formFields.findIndex(field => field.attributes.key === formFields[colIndex].dependencies.target)
  }

  const toggleDayPicker = (e, rowIndex) => {
    e.preventDefault()
    let newShowDayPickers = [...showDayPickers]
    newShowDayPickers[rowIndex] = !newShowDayPickers[rowIndex]
    setShowDayPickers(newShowDayPickers)
  }

  const totalCheckedRowsInvitationsQuantity = () => {
    const invitationsQuantityIndex = formFields.findIndex(field => field.attributes.key === 'invites_quantity')

    if (invitationsQuantityIndex === -1) return checkedRows.length    // If there is no invites_quantity field, we assume that each checked row is worth 1 invitation

    let invitationsQuantity = 0
    checkedRows.forEach(rowIndex => {
      invitationsQuantity += parseInt(tableData[rowIndex][invitationsQuantityIndex])
    })
    return invitationsQuantity
  }

  const validateField = (currentTableData, colIndex, rowIndex) => {
    const validations = formFields[colIndex].validations
    const errors = []
    const value = currentTableData[rowIndex][colIndex]
    if (validateFieldRegex(value, validations.regex)) errors.push(t(`${translationCode}:errors.regex`))
    const required = dependenciesValidations(currentTableData, rowIndex, colIndex)
    if (validateFieldPresence(value, required, rowIndex, colIndex)) errors.push(t(`${translationCode}:errors.required`))
    if (formFields[colIndex].attributes.key === 'nation') {
      if(validateNation(value)) errors.push(t(`${translationCode}:errors.invalid_nation`))
    }
    if (formFields[colIndex].attributes.key === 'city') {
      if (value.length < 3 || validateCity(value, rowIndex)) errors.push(t(`${translationCode}:errors.invalid_city`))
    }
    if (formFields[colIndex].attributes.key === 'dates') {
      const mindDatesAreMatching = validateDates(value)
      if (!mindDatesAreMatching) errors.push(t(`${translationCode}:errors.invalid_date`))
    }
    return errors
  }

  const validateDates = (dates) => {
    const availableDates = JSON.parse(sessionStorage.getItem('ticketsWizardAvailableDates'))
    let isValid = true
    if (availableDates.hidden === true || availableDates.reservationsEnabled === false) isValid = true
    else if (dates.length === 0) isValid = false
    else if (dates.length < availableDates.min) isValid = false
    else if (dates.length > availableDates.max) isValid = false
    return isValid
  }

  const validateCity = (value, rowIndex) => {

    if(!cities[rowIndex] || cities[rowIndex].length === 0) {
      return true
    }

    const citiesLabels = cities[rowIndex].map(city => city.label[lang])


    return !citiesLabels.includes(value)
  }

  const validateFieldPresence = (value, validation, rowIndex, colIndex) => {
    if (!validation) return

    return value.length === 0
  }

  const validateFieldRegex = (value, validation) => {
    if (!validation || !value) return

    const regex = REGEXPS_MAP[validation]

    let theStringIsValid = true
    let breakStringAtEverySpace = value.split(' ');
    for (let i = 0; i < breakStringAtEverySpace.length; i++) {
      const chunk = breakStringAtEverySpace[i];
      if (!regex.test(chunk)) {
        theStringIsValid = false;
        break;
      }
    }
    
    return !theStringIsValid;
  }

  const validateNation = (value) => {
    if(countries.length === 0) return
    const countriesCodes = countries.map(nation => nation.code)
    return !countriesCodes.includes(value)
  }

  const updateAutocomplete = (newValue, rowIndex, colIndex, listName) => {

    updateCell(newValue, rowIndex, colIndex, listName)

    if(newValue.length < 3) return

    if(listName.includes('cities')) {
      getCities(rowIndex, newValue)
    }
  }

  const updateCell = (newValue, rowIndex, colIndex, listName = null) => {
    if (onlineValidateTheRowCounterTimeouts.current[rowIndex]) {
      clearTimeout(onlineValidateTheRowCounterTimeouts.current[rowIndex])
    }
    if (abortControllers[rowIndex]) {
      abortControllers[rowIndex].abort();
    }
    let colIndexes = [colIndex]
    let newTableErrors = [...tableErrors]

    if(listName?.includes('countries')) {
      const country = countries.find((country) => country.label[lang] == newValue)
      if (country) newValue = country.code
    }
    if(formFields[colIndex].attributes.type === 'DatePicker') {
      newValue = newValue.map((date) => {
        return format(new Date(date), 'yyyy-MM-dd')
      })
    }
    if(formFields[colIndex].attributes.type === 'Number') {
      const totalAllocatedQuantity = tableData.reduce((acc, row, index) => {
        if (index !== rowIndex) {
          return acc + parseInt(row[colIndex])
        }
        return acc
      }, 0)
      const maxValue = originalAvailableQuantity - totalAllocatedQuantity
      newValue = putNumberInRange(newValue, formFields[colIndex].validations.min, maxValue)
      onUpdateProductAvailableQuantity(originalAvailableQuantity - totalAllocatedQuantity - newValue)
    }
    let newTableData = [...tableData]
    newTableData[rowIndex][colIndex] = newValue

    newTableData[rowIndex].forEach((cellData, index) => {
      if(clearValueOnDependencyChange(index, colIndexes)) {
        colIndexes.push(index)
        newTableData[rowIndex][index] = ''
      }
    })

    onUpdate(newTableData)

    let rowErrors = newTableErrors[rowIndex]
    rowErrors[colIndex] = validateField(newTableData, colIndex, rowIndex)
    
    const dependableFieldIndex = formFields.findIndex(field => field.dependencies && field.dependencies.enabled && field.dependencies.target === formFields[colIndex].attributes.key)
    if(dependableFieldIndex !== -1) {
      rowErrors[dependableFieldIndex] = validateField(newTableData, dependableFieldIndex, rowIndex)
    }
    newTableErrors[rowIndex] = rowErrors

    const theRowIsClientSideValid = rowErrors.every(errors => errors.length === 0)
    if(theRowIsClientSideValid) {
      onlineValidateTheRowCounterTimeouts.current[rowIndex] = setTimeout(() => {
        onlineValidateTheRow(newTableData[rowIndex], rowIndex, newTableErrors)
      }, 1000)
    } else {
      onUpdateErrors(newTableErrors) // This line was missing  -- the state was being manually updated
    }
  }

  const handleCloseDayPicker = () => {
    setShowDayPickers(Array(tableData.length).fill(false))
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition({
        x: scrollContainerRef.current.scrollLeft,
        y: scrollContainerRef.current.scrollTop,
      });
    };

    scrollContainerRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      Object.values(onlineValidateTheRowCounterTimeouts.current).forEach(timeout => clearTimeout(timeout)),
      Object.values(abortControllers).forEach(controller => controller.abort()),
      scrollContainerRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => { // This one synchronizes tableData and tableErrors
    if (!tableErrors && confirmedColumns && tableData.length > 0) {
      const newTableErrors = tableData.map((row, rowIndex) => {
        return row.map((cellData, colIndex) => {
          const twoDIndex = rowIndex + ":" + colIndex
          if (listOfPrevalidatedCities.includes(twoDIndex)) {
            return []
          }
          return validateField(tableData, colIndex, rowIndex)
        })
      })
      onUpdateErrors(newTableErrors)
      setShowDayPickers(Array(tableData.length).fill(false))
      setEmailsValidations(Array(tableData.length).fill(null))
    }
  }, [tableData, tableErrors, confirmedColumns])

  useEffect(() => { 
    // This must be executed if and only if unassignableRows has been changed  -- if this is the case into the tableData is coming the first row of the unassignableRows, we must update the errors against the newtableData
   if(Array.isArray(unassignableRows) && unassignableRows.length >= 0) {
    const actualUnassiagnableRowsLength = unassignableRows.length
    const oldUnassignableRowsLength = lastUnassignableRowsCount
    setLastUnassignableRowsCount(actualUnassiagnableRowsLength)
     if (actualUnassiagnableRowsLength < oldUnassignableRowsLength) {
      // this means that some rows have been passed from unassignable to assignable -- we need to update the errors accordingly -- we will update the errors of the last row of the tableData given from the delta
      const tableDataRows = tableData.length
      const indexFromWichToStart = tableDataRows - (oldUnassignableRowsLength - actualUnassiagnableRowsLength)
      const newTableErrors = tableData.map((row, rowIndex) => {
        if (rowIndex >= indexFromWichToStart) {
          return row.map((cellData, colIndex) => {
            return validateField(tableData, colIndex, rowIndex)
          })
        } else {
          return tableErrors[rowIndex]
        }
      }
      )
      onUpdateErrors(newTableErrors)
      const newEmailsValidations = emailsValidations.map((emailValidation, rowIndex) => {
        if (rowIndex >= indexFromWichToStart) {
          return null
        } else {
          return emailValidation
        }
      })
      setEmailsValidations(newEmailsValidations)
    }
   }
   }, [unassignableRows])


  useEffect(() => {
    if (!formFields || wholeTableDisabled) return

    const invitationsQuantityIndex = formFields.findIndex(field => field.attributes.key === 'invites_quantity')

    let quantityToRemove = 0

    if (invitationsQuantityIndex === -1) {
      quantityToRemove = tableData.length
    } else {
      tableData.forEach(row => {
        quantityToRemove += parseInt(row[invitationsQuantityIndex])
      })
    }
    const product = JSON.parse(sessionStorage.getItem(TICKETS_WIZARD_PRODUCT))

    onUpdateProductAvailableQuantity(product.not_assigned - quantityToRemove)
  }, [formFields])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        event.target instanceof Node &&
        !datePickerRef.current.contains(event.target)
      ) {
        handleCloseDayPicker(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleCloseDayPicker]);

  useEffect(() => {
    if (wholeTableDisabled || !emailsValidations) return
    const filteredEmailsValidations = emailsValidations.filter(emailValidation => emailValidation && emailValidation.valid === false)
    onValidateEmails(filteredEmailsValidations)
  }, [emailsValidations])

  useEffect(()=>{
    if (!tableGroups || tableGroups.length === 0) return
    handleVisibleGroups(tableGroups.map((tableGroup) => tableGroup.key ))
  }, [tableGroups])

  useEffect(() => {
    if (selectedErrorCoordinates.length > 0 && cellRef.current) {
      setTimeout(() => {  // This is needed to give time to eventual hidden cells to show up before scrolling
        cellRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "center"});
      }, 120)
    }
  }, [selectedErrorCoordinates]);

  

  return (
    <div className="flex flex-col mt-2 select-none">

      {/* Buttons bar: it's handled this way because otherwise we would have needed to move every validation function in the parent component */}
      <div className="flex justify-between items-center fixed bottom-[1.5rem] right-[36%] z-[1000]">
          {confirmedColumns && (
              <Button
                variant={`${(checkedRows.length === 0 || onlineValidatingRows.length > 0) ? "disabled-btn-outline" : "secondary"}`}
                css="h-[36px] leading-[36px] text-center"
                disabled={checkedRows.length === 0 || onlineValidatingRows.length > 0}
                onClick={deleteSelectedRows}
                endIcon={<DeleteIcon />}>
                {t(`${translationCode}:delete_selected_rows`)}
              </Button>
            )
          }

          {addRowEnabled &&
            <Button
              variant={`${onlineValidatingRows.length > 0 ? 'disabled-btn' : 'primary'}`}
              css="h-[36px] leading-[36px] text-center"
              disabled={onlineValidatingRows.length > 0}
              onClick={addDataRow}
              endIcon={<AddCircleOutlineIcon />}
              >
              {t(`${translationCode}:add_new_row`)}
            </Button>
          }

        {confirmedColumns &&
          <Button
            onClick={() => {
              downloadXlsx();
            }}
            variant="secondary"
            css="h-[36px] leading-[36px] text-center"
          >
            <div className='flex items-center'>
              {t(`${translationCode}:download_updated_file`)}
              <DownloadXlsx width="23px" height="23px"/>
            </div>
          </Button>
        }
      </div>

      {/* Here we have the table and its scroll buttons */}

      <div className="flex items-start">

        <div
          className={`mt-14 p-4 mr-2 rounded-xl cursor-pointer bg-stone-50 flex items-center ${scrollTableLeftDisabled ? 'opacity-50 !cursor-not-allowed' : 'hover:bg-zinc-100'}`}
          onClick={scrollTableLeft}
          disabled={scrollTableLeftDisabled}
        >
          <ArrowBackIosNewIcon
            fontSize="medium"
            className="text-gray-500"
          />
        </div>

        <div
          ref={scrollContainerRef}
          className="flex overflow-x-auto overflow-y-auto h-[355px] w-fit"
          >

          <table
            className="border-gray-500 border-separate border-spacing-0 w-fit h-fit">
            {showHeaders &&
              <thead>
                {!wholeTableDisabled && (
                  <tr>
                    <th className={`${classes.cell} min-w-[54px] invisible !border-none`}>
                      &nbsp;
                    </th>
                    <th className={`${classes.cell} min-w-[54px] invisible !border-none`}>
                      &nbsp;
                    </th>
                    {visibleGroups.map((visibleGroupKey, index) => {
                        const colspan = formFields?.filter((field) => { return field.display.group.key === visibleGroupKey }).length || 1
                        const group = tableGroups.find((tableGroup) => { return tableGroup.key === visibleGroupKey })
                        const groupLabel = group.label[lang]
                        if (group.cannotBeShown) return
                        return (
                          <th key={index} className={`${classes.cell} ${classes.headerText} border-gray-500 bg-[#C2C9D1]`}
                            colSpan={colspan}
                            style={tableGroupsHeaderStyle(visibleGroupKey)}>
                            {groupLabel}
                          </th>
                        )
                      })
                    }
                  </tr>
                )}
                <tr>
                  <th   
                    className={`${classes.cell} min-w-[54px]`}
                    style={{
                      position: (confirmedColumns && scrollPosition.y > 0 || scrollPosition.x > 0) ? 'sticky' : 'static',
                      zIndex: 100,
                      top: 0,
                      left: 0,
                      backgroundColor: '#f0f0f0',
                    }}
                    ></th>
                  <th className={`${classes.cell} min-w-[54px]`}
                    style={{
                      position: (confirmedColumns && scrollPosition.y > 0 || scrollPosition.x > 0) ? 'sticky' : 'static',
                      zIndex: 100,
                      top: 0,
                      left: scrollPosition.x > 0 ? "54px" : "0",
                      backgroundColor: '#f0f0f0',
                    }}>#</th>
                  {tableHeaders.map((tableHeader, index) => {
                    const isEmail = typeof tableHeader === "string" && tableHeader.toLowerCase() === "email"
                    const groupKey = formFields[index]?.display.group.key || "no-group"
                    const isOneOfTheFirstTwoColumns = index <= 1
                    let isSticky = (confirmedColumns && groupKey === "registry" && isOneOfTheFirstTwoColumns) && "sticky"
                    const isMovingFromLeft = isSticky && scrollPosition.x > 0
                    const isMovingFromTop = confirmedColumns && scrollPosition.y > 0
                    const stickyPositions = {
                      0: 108,
                      1: 288,
                      //2: 608,
                      //3: modality === "assign" ? 858 : 1058,
                    };
                    let cellWidthClasses = groupKey === "registry" ? "!w-[180px] !min-w-[180px] !max-w-[180px]" : ""
                    if (isEmail) {
                      cellWidthClasses = "!w-[450px] !min-w-[450px] !max-w-[450px]"
                    }
                    let stickyLeft = stickyPositions[index] ? stickyPositions[index] + "px" : null
                    const tableHeaderClass = `${classes.cell} ${classes.headerText} ${cellVisibleOrHidden(index)} top-0 bg-gray-200 ${cellWidthClasses}`
                    const stickyStylesToOverShadowOverlappingColumns = {
                      position: 'sticky',
                      zIndex: 100 - index,
                      backgroundColor: '#f0f0f0', // light gray
                      //border: '2px solid #000', // black border
                    }
                    return (
                      <th key={index}
                        className={tableHeaderClass}
                        style={{
                          ...((isMovingFromLeft || isMovingFromTop) && stickyStylesToOverShadowOverlappingColumns),
                          ...(isMovingFromLeft && { left: stickyLeft }),
                        }}>
                        {tableHeader}
                      </th>
                    )
                  })}
                </tr>
              </thead>
            }
            <tbody>
              {tableData.map((row, rowIndex) => {
                const disabledRow = wholeTableDisabled || onlineValidatingRows.includes(rowIndex)
                const tableRowClass = `${rowIndex % 2 !== 0 ? 'bg-[#DFE9E9]' : 'bg-white'} ${rowInvalidClasses(rowIndex)} ${disabledRow ? 'opacity-50 pointer-events-none' : ''}`
                const showProgress = !wholeTableDisabled && onlineValidatingRows?.includes(rowIndex)
                return (
                  <tr key={rowIndex}>
                    <td className={`${classes.cell} ${tableRowClass} !h-[50px] select-text ${showProgress ? "pt-3" : ""} ${((confirmedColumns && scrollPosition.y > 0 || scrollPosition.x > 0) && kind === "active") ? 'sticky !opacity-100 z-10' : 'static'} ${scrollPosition.x > 0 && 'left-0'}`}>
                      <div className="w-full h-full">
                        {showProgress ? (
                          <CircularProgress
                            size={13}
                            thickness={5}
                            color="primary"
                            className="w-full h-full p-0"
                          />
                        ) : (
                          <input
                            type="checkbox"
                            checked={!wholeTableDisabled && checkedRows.includes(rowIndex)}
                            onChange={() => selectTableRow(rowIndex)}
                            disabled={wholeTableDisabled}
                            className="w-full h-full"
                          />
                        )}
                      </div>
                    </td>
                    <td className={`${classes.cell} ${tableRowClass} !!h-[50px] text-center select-text ${((confirmedColumns && scrollPosition.y > 0 || scrollPosition.x > 0) && kind === "active") ? 'sticky !opacity-100 z-10' : 'static'} ${scrollPosition.x > 0 && 'left-[54px]'}`}>
                      {startIndex + rowIndex}
                    </td>
                    {row.map((cellData, colIndex) => {
                      const cellIsFocused = selectedErrorCoordinates[0] === rowIndex && selectedErrorCoordinates[1] === colIndex
                      const groupKey = kind === "active" ? formFields[colIndex]?.display.group.key : "no-group"
                      const isEmail = kind === "active" ? (typeof formFields[colIndex]?.attributes.key === "string" && formFields[colIndex]?.attributes.key.toLowerCase() === "email") : false
                      const isOneOfTheFirstTwoColumns = colIndex <= 1
                      let isSticky = (confirmedColumns && groupKey === "registry" && isOneOfTheFirstTwoColumns) && "sticky"
                      const isMovingFromLeft = isSticky && scrollPosition.x > 0
                      const isMovingFromTop = isSticky && scrollPosition.y > 0
                      const stickyPositions = {
                        0: 108,
                        1: 288,
                        //2: 608,
                        //3: modality === "assign" ? 858 : 1058,
                      };
                      let cellWidthClasses = groupKey === "registry"? "!w-[180px] !min-w-[180px] !max-w-[180px]" : ""
                      if (isEmail) {
                        cellWidthClasses = "!w-[450px] !min-w-[450px] !max-w-[450px]"
                      }
                      let stickyLeft = stickyPositions[colIndex] ? stickyPositions[colIndex] + "px" : null
                      const tableDataClass = `${classes.cell} ${classes.bodyCell} ${cellVisibleOrHidden(colIndex)} ${cellIsFocused ? ' !border-4 !border-red-400 bg-red-200' : ''} ${tableRowClass} ${cellWidthClasses}`
                      const stickyStylesToOverShadowOverlappingColumns = {
                        position: 'sticky',
                        //backgroundColor: 'white',
                        zIndex: 50 - colIndex,
                        color: 'black',
                        border: '1px solid #C2C9D1',
                        ...(cellIsFocused && { border: '4px solid #FF0000' }),
                      }
                      return (
                        <td
                          ref={cellIsFocused ? cellRef : null}
                          title={confirmedColumns && cellErrors(rowIndex, colIndex)?.join(", ")}
                          key={colIndex}
                          style={{
                            ...((isMovingFromLeft || isMovingFromTop) && stickyStylesToOverShadowOverlappingColumns),
                            ...(isMovingFromLeft && { left: stickyLeft }),
                          }}
                          className={tableDataClass + " select-text"}>
                          <div className="flex items-center">
                            {wholeTableDisabled ?
                              (<span title={cellData} className="text-ellipsis overflow-hidden">{cellData}</span>) :
                              renderMainTableFormFields(cellData, rowIndex, colIndex)}
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>

        </div>

        <div
          className={`mt-14 ml-2 p-4 rounded-xl cursor-pointer bg-stone-50 flex items-center ${scrollTableRightDisabled ? 'opacity-50 !cursor-not-allowed' : 'hover:bg-zinc-100'}`}
          onClick={scrollTableRight}
          disabled={scrollTableRightDisabled}
        >
          <ArrowForwardIosIcon
            fontSize="medium"
            className="text-gray-500"
          />
        </div>
        
      </div>

    </div>
  )
})

EditableTable.defaultProps = {
  showHeaders: true,
  startIndex: 1,
}

export default EditableTable;
