import classNames from "classnames";
import React from "react";

const SIZES = {
  SMALL: "text-xs",
  MEDIUM: "text-sm",
  LARGE: "text-md",
  h1: "text-5xl font-bold",
  h2: "text-3xl md:text-4xl font-semibold",
  h3: "text-3xl font-semibold",
  h4: "text-2xl font-medium",
  h5: "text-1xl font-normal",
  h6: "text-xl font-light",
  display1: "",
  /**
    font-family: Museo Sans Rounded;
    font-style: normal;
    font-weight: normal;
    font-size: 96px;
    color:  rgba(52, 58, 64, 0.1); 
  */
  display2: "",
  /**
    font-family: Museo Sans Rounded;
    font-style: normal;
    font-weight: normal;
    font-size: 88px;
    color:  rgba(52, 58, 64, 0.1);
  */
  display3: "",
  /**
    font-family: Museo Sans Rounded;
    font-style: normal;
    font-weight: normal;
    font-size: 72px;
    color:  rgba(52, 58, 64, 0.1);
  */
  display4: "",
  /**
    font-family: Museo Sans Rounded;
    font-style: normal;
    font-weight: normal;
    font-size: 56px;
    color:  rgba(52, 58, 64, 0.1);
  */
  body1: "font-tk-usual font-bold",
  /**
    font-family: Usual;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
  */
  body2: "font-tk-usual font-light",
  /**
    font-family: Usual;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  */
  body3: "font-tk-usual",
  /**
   font-family: Usual;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-decoration-line: underline;
  */
  body4: "",
  /**
    font-family: Museo Sans Rounded;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    // italic
  */
  body5: "",
  /**
    font-family: Museo Sans Rounded;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    // italic bold
  */
  body6: "font-tk-freight-sans-pro font-bold text-base tracking-wide",
  //  font-family: FreightSans Pro;
  // font-size: 16px;
  // font-style: normal;
  // font-weight: 700;
  // line-height: 26px;
  // letter-spacing: 1.0199999809265137px;
  // text-align: left;
};

const VARIANTS = {
  default: "",
  // testing chips
  bgRED: "bg-red-100 text-red-800",
  bgYELLOW: "bg-yellow-100 text-yellow-800",
  bgGREEN: "bg-green-100 text-green-800",
  bgBLUE: "bg-blue-100 text-blue-800",
  // testing colors
  RED: "text-red-800",
  YELLOW: "text-yellow-800",
  GREEN: "text-green-800",
  BLUE: "text-blue-800",
};

const Typography = ({
  variant,
  size,
  children,
  css = "",
  type = "span",
  ...rest
}) => {
  return React.createElement(type, {
    className: classNames(VARIANTS[variant], SIZES[size], css),
    children,
    ...rest,
  });
};

Typography.defaultProps = {
  variant: "default",
  size: "SMALL",
};

export default Typography;
