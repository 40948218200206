import { useRef, useState } from "react";
import DayPicker from "react-day-picker";
// import "react-day-picker/lib/style.css";
import useTranslation from "next-translate/useTranslation";

// Components
import { FormikTextInput } from "../../components/formik/";
import CalendarIcon from "../../components/icons/d-ticketing/Calendar";
//Hooks
import useOnClickOutside from "../../hooks/useOnClickOutside";

export default function DatePicker({
  name,
  availableDates = [],
  onClick,
  compCss,
  value,
  error,
  errorLabel,
  label,
  placeholder,
  autocomplete = "on",
  ...rest
}) {
  const date = new Date();
  const [open, setOpen] = useState(false);
  const pickerRef = useRef(null);
  const { lang } = useTranslation();

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      onClick(day);
      setOpen(false);
    }
    return;
  };

  const toggleOpen = () => setOpen(!open);

  //Outside click handler hook
  useOnClickOutside(pickerRef, () => setOpen(false));

  const WEEKDAYS_LONG = {
    en: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    it: [
      "Domenica",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato",
    ],
  };
  const WEEKDAYS_SHORT = {
    en: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    it: ["D", "L", "M", "M", "G", "V", "S"],
  };
  const MONTHS = {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    it: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ],
  };

  const FIRST_DAY = {
    en: 0,
    it: 1, // Use Monday as first day of the week
  };

  function formatDay(d, locale = "en") {
    return `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${
      MONTHS[locale][d.getMonth()]
    } ${d.getFullYear()}`;
  }

  function formatMonthTitle(d, locale = "en") {
    return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
  }

  function formatWeekdayShort(i, locale) {
    return WEEKDAYS_SHORT[locale][i];
  }

  function formatWeekdayLong(i, locale) {
    return WEEKDAYS_SHORT[locale][i];
  }

  function getFirstDayOfWeek(locale) {
    return FIRST_DAY[locale];
  }

  const localeUtils = {
    formatDay,
    formatMonthTitle,
    formatWeekdayShort,
    formatWeekdayLong,
    getFirstDayOfWeek,
  };

  const availableDatesSorted = availableDates?.sort((a, b) => {
    return Date.parse(a) > Date.parse(b);
  });

  return (
    <div ref={pickerRef}>
      <FormikTextInput
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        icon={<CalendarIcon />}
        onFocus={toggleOpen}
        compCss={compCss}
        error={error}
        autocomplete={autocomplete}
      />
      {open && (
        <DayPicker
          className="w-full bg-app-white rounded-3xl "
          initialMonth={availableDatesSorted[0]}
          month={availableDatesSorted[0]}
          fromMonth={availableDatesSorted[0]}
          toMonth={availableDatesSorted[availableDatesSorted.length-1]}
          disabledDays={availableDates}
          onDayClick={handleDayClick}
          locale={lang}
          localeUtils={localeUtils}
          value={availableDatesSorted[0]}
        />
      )}
      {
        error && <div name={name} component="div" className="text-red-600 mb-4">
          {error}
        </div> 
      }
    </div>
  );
}
