import Link from "next/link";
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";

const convertBreadcrumb = (title) => {
  return title
    .replace(/-/g, " ")
    .replace(/oe/g, "ö")
    .replace(/ae/g, "ä")
    .replace(/ue/g, "ü")
    .replace(/\?.*/, "")
    .toUpperCase();
};

const defaultProps = {
  useDefaultStyle: false,
  rootLabel: "HOME",
  containerStyle: null,
  containerClassName: "",
  listStyle: null,
  listClassName: "flex flex-row gap-2",
  inactiveItemStyle: null,
  inactiveItemClassName: "",
  activeItemStyle: null,
  activeItemClassName: "text-app-bggrey",
  customLabel: undefined,
};

const BreadCrumb = (props) => {
  // get the props if passed or the default value [ DEBUG ]
  const useDefaultStyle = props.useDefaultStyle ?? defaultProps.useDefaultStyle,
        rootLabel = props.rootLabel ?? defaultProps.rootLabel,
        containerStyle = props.containerStyle ?? defaultProps.containerStyle,
        containerClassName = props.containerClassName ?? defaultProps.containerClassName,
        listStyle = props.listStyle ?? defaultProps.listStyle,
        listClassName = props.listClassName ?? defaultProps.listClassName,
        inactiveItemStyle = props.inactiveItemStyle ?? defaultProps.inactiveItemStyle,
        inactiveItemClassName = props.inactiveItemClassName ?? defaultProps.inactiveItemClassName,
        activeItemStyle = props.activeItemStyle ?? defaultProps.activeItemStyle,
        activeItemClassName = props.activeItemClassName ?? defaultProps.activeItemClassName,
        customLabel = props.customLabel ?? defaultProps.customLabel,
        router = useRouter();

  return (
    <>
      <div className="flex bg-white w-full text-app-blue space-x-2 font-tk-museo-sans-rounded">
        <nav style={{ ...containerStyle }} className={containerClassName} aria-label="breadcrumbs">
          <ol style={{ ...listStyle }} className={useDefaultStyle ? "_2jvtI" : listClassName}>
            <li style={{ ...inactiveItemStyle }} className={inactiveItemClassName}>
              <Link href={props.category === "g" ? "/main_management" : "/op_management"}>
                {rootLabel || 'HOME'}
              </Link>
            </li>
            <span>/</span>
            <li style={{ ...activeItemStyle }} className={activeItemClassName}>
              <Link href={router.asPath}>
                {customLabel || convertBreadcrumb(router.asPath.replace('/', '').replace('/', '-'))}
              </Link>
            </li>
          </ol>
        </nav>
      </div>
    </>
  );
};

export default BreadCrumb;
