import classes from "./OnDragOverBlur.module.css";

const OnDragOverBlur = (props) => {
  const { border } = props
  
  return (
    <div className={classes.dragOverBlur}>
      <div className={border ? classes.dragOverBorder : ''}>
        <div className={classes.dragOverText}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

OnDragOverBlur.defaultProps = {
  border: true
}

export default OnDragOverBlur;