/* #region PATH */
export const PATH_MAIN_MANAGMENT = "/main_management";
export const PATH_BUY = "/buy";
export const PATH_PROFILE = "/profile";
export const PATH_OPERATION_MANAGEMENT = "/op_management";
export const PATH_PURCHASE = "/purchase";
export const PATH_WIZARD = "/tickets_wizard"
/* #endregion */

/* #region REGEX */
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PHONE_REGEX = /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const IS_NUMBER_REGEX = /^\d+$/;
/* #endregion */

/* #region TRANSLATION NAMESPACE */
export const COMMON_TRANSLATION_NAMESPACE="common";
export const MAIN_TRANSLATION_NAMESPACE="main";
export const WIZARD_TRANSLATION_NAMESPACE="wizard";
export const TICKETS_WIZZARD_TRANSLATION_NAMESPACE="tickets_wizard";
export const WALLET_TAB_WIZZARD_TRANSLATION_NAMESPACE="wallet_tab"
/* #endregion */

/* #region SESSION STORAGE KEYS */
export const TICKETS_WIZARD_PRODUCT="ticketsWizardProduct";
export const TICKETS_WIZARD_CAT="ticketsWizardCategory";
export const TICKETS_WIZARD_TYPOLOGY = "ticketsWizardTypology";
export const TICKETS_WIZARD_TICKET_TYPOLOGY = "ticketsWizardTicketTypology";
export const TICKETS_WIZARD_FILE_DATA = "ticketsWizardFileData";
export const TICKETS_WIZARD_TABLE_DATA = "ticketsWizardTableData";
export const TICKETS_WIZARD_TABLE_HEADERS = "ticketsWizardTableHeaders";
export const TICKETS_WIZARD_FORM = "ticketsWizardForm";
export const TICKETS_WIZARD_UPLOAD = "ticketsWizardUpload";
export const TICKETS_WIZARD_CONFIRMED_COLUMNS = "ticketsWizardConfirmedColumns";
export const TICKETS_WIZARD_AVAILABLE_DATES = "ticketsWizardAvailableDates";
export const TICKETS_WIZARD_TITLES = "ticketsWizardTitles";
export const TICKETS_WIZARD_PRODUCT_DETAILS_DATA = "ticketsWizardProductDetailsData";
export const TICKETS_WIZARD_EMAIL_VALIDATIONS = "ticketsWizardEmailValidations";
export const TICKETS_WIZARD_TABLE_GROUPS = "ticketsWizardTableGroups";
export const TICKETS_WIZARD_CURRENT_STEP="ticketsWizardCurrentStep";
export const TICKETS_WIZARD_COUNTRIES="ticketsWizardCountries";

export const WIZARD_PREV_URL="wizardPrevPath";
/* #endregion */