import * as React from "react";

function SvgPlus1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.fill || 'none'}
    >
      <path
        d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108-.001 8 8 0 008 16z"
        fill={props.fill || "#000"}
      />
      <path
        d="M8 4a.5.5 0 01.5.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 110-1h3v-3A.5.5 0 018 4z"
        fill={props.fill || "#000"}
      />
    </svg>
  );
}

export default SvgPlus1;
