const Danger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00078 0.800781C4.00078 0.800781 0.800781 4.00078 0.800781 8.00078C0.800781 12.0008 4.00078 15.2008 8.00078 15.2008C12.0008 15.2008 15.2008 12.0008 15.2008 8.00078C15.2008 4.00078 12.0008 0.800781 8.00078 0.800781ZM7.36079 4.00078H8.56079V9.60078H7.36079V4.00078ZM8.00078 12.8008C7.52078 12.8008 7.20078 12.4808 7.20078 12.0008C7.20078 11.5208 7.52078 11.2008 8.00078 11.2008C8.48078 11.2008 8.80078 11.5208 8.80078 12.0008C8.80078 12.4808 8.48078 12.8008 8.00078 12.8008Z"
        fill="#DC3545"/>
    </svg>
  )
}

export default Danger