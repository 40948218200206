import * as React from "react";

function SvgResting(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 1.333h9.334c1.133 0 2 .867 2 2v9.334c0 1.133-.867 2-2 2H3.333c-1.133 0-2-.867-2-2V3.333c0-1.133.867-2 2-2zm9.334 12c.4 0 .666-.266.666-.666V3.333c0-.4-.266-.666-.666-.666H3.333c-.4 0-.666.266-.666.666v9.334c0 .4.266.666.666.666h9.334z"
        fill="#212529"
      />
    </svg>
  );
}

export default SvgResting;
