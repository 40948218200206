import * as React from "react";

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.467 11.533a.644.644 0 010 .934.605.605 0 01-.467.2.605.605 0 01-.467-.2L8 8.933l-3.533 3.534a.605.605 0 01-.467.2.605.605 0 01-.467-.2.645.645 0 010-.934L7.067 8 3.533 4.467a.644.644 0 010-.934.644.644 0 01.934 0L8 7.067l3.533-3.534a.644.644 0 01.934 0 .644.644 0 010 .934L8.933 8l3.534 3.533z"
        fill={props.fill || "#212529"}
      />
    </svg>
  );
}

export default SvgClose;
