import * as React from "react";

function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
      {...props}
    >
      <path
        d="M13.333 8c0 .4-.266.667-.666.667h-4v4c0 .4-.267.666-.667.666s-.667-.266-.667-.666v-4h-4c-.4 0-.666-.267-.666-.667s.266-.667.666-.667h4v-4c0-.4.267-.666.667-.666s.667.266.667.666v4h4c.4 0 .666.267.666.667z"
        // fill={props.fill || "#212529"}
      />
    </svg>
  );
}

export default SvgPlus;
