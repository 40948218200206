import * as React from "react";

function SvgRemoveticket(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.624 2.59a.6.6 0 00-.848 0L2.59 8.776a.6.6 0 000 .849l.62.62a.066.066 0 00.021.004c.037 0 .074-.01.106-.028a1.8 1.8 0 012.443 2.443.213.213 0 00-.028.105c0 .013.003.019.004.021l.62.62a.601.601 0 00.848 0l6.185-6.185a.6.6 0 000-.848l-.619-.62a.066.066 0 00-.021-.004.213.213 0 00-.107.028 1.8 1.8 0 01-2.442-2.443.212.212 0 00.028-.106.064.064 0 00-.004-.022l-.62-.62zM8.21 2.024a1.4 1.4 0 011.98 0l.62.62c.325.326.27.789.111 1.077a1 1 0 001.357 1.357c.288-.158.752-.213 1.077.112l.62.62a1.4 1.4 0 010 1.98L7.79 13.976a1.4 1.4 0 01-1.98 0l-.62-.62c-.325-.325-.27-.788-.112-1.076a1 1 0 00-1.357-1.357c-.288.157-.752.213-1.077-.112l-.62-.62a1.4 1.4 0 010-1.98L8.21 2.024v-.001z"
        fill="#721C24"
      />
      <g clipPath="url(#remove_ticket_svg__clip0)">
        <path
          d="M10.8 15.6a3.2 3.2 0 110-6.399 3.2 3.2 0 010 6.399zm0-6a2.8 2.8 0 100 5.6 2.8 2.8 0 000-5.6z"
          fill="#DC3545"
        />
        <path
          d="M10.8 9.6a2.8 2.8 0 100 5.6 2.8 2.8 0 000-5.6z"
          fill="#DC3545"
        />
        <path
          d="M11.122 12.4l.972-.972a.2.2 0 00-.282-.282l-.972.962-.978-.978a.2.2 0 00-.282.282l.976.988-.956.944a.2.2 0 00-.011.293.202.202 0 00.228.039.2.2 0 00.065-.05l.954-.954.948.948a.2.2 0 00.282-.282l-.944-.938z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="remove_ticket_svg__clip0">
          <path
            fill="#fff"
            transform="translate(7.2 8.8)"
            d="M0 0h7.2v7.2H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRemoveticket;
