import * as React from "react";

function SvgDatails(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.333 6c0 1.107-.893 2-2 2-1.106 0-2-.893-2-2s.894-2 2-2c1.107 0 2 .893 2 2zm2 7.333h-8V12c0-1.473 1.794-2.667 4-2.667 2.207 0 4 1.194 4 2.667v1.333zM14.667 8v1.333h-6V8h6zm0-2.667v1.334h-6V5.333h6zm0-2.666V4h-6V2.667h6z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgDatails;
