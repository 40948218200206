import * as React from "react";

function Visa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="65"
      height="39"
      viewBox="0 0 65 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="65" height="39" rx="2.6" fill="#1A226D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9688 25.7053L28.9673 13.3071H32.162L30.1636 25.7053H26.9688Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.7515 13.6115C41.1199 13.3627 40.1264 13.0913 38.8889 13.0913C35.7304 13.0913 33.507 14.7738 33.4886 17.183C33.4685 18.9651 35.0751 19.959 36.2864 20.5513C37.5313 21.1585 37.9495 21.547 37.9446 22.0899C37.936 22.9202 36.9509 23.3007 36.0324 23.3007C34.7534 23.3007 34.0738 23.1134 33.0232 22.6505L32.6123 22.4531L32.1655 25.2284C32.9105 25.5737 34.2915 25.8734 35.7247 25.8892C39.0814 25.8892 41.2626 24.227 41.2881 21.6543C41.2996 20.2411 40.4484 19.17 38.6047 18.2856C37.4883 17.7107 36.8031 17.3299 36.811 16.748C36.8117 16.2327 37.3898 15.6811 38.6411 15.6811C39.6861 15.6642 40.4425 15.9055 41.032 16.1575L41.3188 16.2992L41.7515 13.6115Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0053 21.3097C46.27 20.5971 47.2779 17.8442 47.2779 17.8442C47.2593 17.877 47.5412 17.1261 47.7026 16.6614L47.9182 17.7307C47.9182 17.7307 48.5312 20.6899 48.6574 21.3097C48.1601 21.3097 46.6291 21.3097 46.0053 21.3097ZM49.9467 13.3198H47.4778C46.712 13.3198 46.1389 13.5392 45.8034 14.3476L41.0591 25.7097H44.4145C44.4145 25.7097 44.962 24.1812 45.0862 23.8458C45.4522 23.8458 48.7126 23.8522 49.178 23.8522C49.2738 24.2849 49.567 25.7097 49.567 25.7097H52.5322L49.9467 13.3198Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2881 13.3176L21.1613 21.7702L20.8263 20.052C20.2436 18.0723 18.4298 15.9266 16.4009 14.8514L19.2615 25.6945L22.6426 25.6925L27.6735 13.3176H24.2881Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2591 13.3093H13.1073L13.0649 13.5662C17.0736 14.5932 19.726 17.0722 20.8268 20.0521L19.7073 14.3554C19.5142 13.5697 18.9529 13.3368 18.2591 13.3093Z"
        fill="#EC982D"
      />
    </svg>
  );
}

export default Visa;
