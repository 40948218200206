export default function TableActionsButtons({ options }) {

  const buttons = options.map((option, index) => {
    const {
      name,
      icon,
      onClick,
      disabled,
      title_enabled,
      title_disabled,
    } = option;
    return (
      <div
        key={index}
        onClick={() => {
          if(disabled) return;
          onClick();
        }}
      >
        {icon}
      </div>
    )
  });

  const allDisabled = options.every(option => option.disabled);

  return (
    <div className={`table-actions-wrapper ${allDisabled ? "cursor-not-allowed" : ""}`}>{buttons}</div>
  );
}
