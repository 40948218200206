import RadioCard from "./RadioCard";

const RadioCards = ((props) => {
  const { onOptionSelect, options, value } = props

  return (
    <>
      {
        options.map((option) => {
          return(
            <RadioCard 
              key={option.code}
              onClick={() => {onOptionSelect(option.code)}}
              option={option}
              selected={value === option.code}/>
          )
        }
      )}
    </>
  )
})

export default RadioCards;
