import { useState, useEffect } from "react";


export function useScrollState(ref, recalculate) {
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(true);

    useEffect(() => {
        function checkScrollPosition() {
            const tolerance = 1; // Adjust this value as needed
            if (ref.current) {
                setIsAtStart(ref.current.scrollLeft === 0);
                setIsAtEnd(Math.abs(ref.current.scrollLeft - (ref.current.scrollWidth - ref.current.clientWidth)) < tolerance);
            }
        }

        checkScrollPosition();
        ref.current.addEventListener('scroll', checkScrollPosition);
        window.addEventListener('resize', checkScrollPosition);

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('scroll', checkScrollPosition);
            }
            window.removeEventListener('resize', checkScrollPosition);
        };
    }, [ref, recalculate]);

    return [isAtStart, isAtEnd];
}