import { DefaultToastContainer } from "react-toast-notifications";

const CustomToastContainer = ({ children, ...props }) => (
    <DefaultToastContainer
        {...props}
        style={{ ...props.style, marginBottom: '40px' }}
    >
        {children}
    </DefaultToastContainer>
);
export {
    CustomToastContainer
}