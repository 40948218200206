import { DateTime } from "luxon";

const normalizeDate = (date) =>
  DateTime.fromISO(date).setLocale("it").toLocaleString({
    month: "long",
    day: "numeric",
    year: "numeric",
  });
const calendarDate = (date) => {
  DateTime.fromISO(date).setLocale("it").toLocaleString({
    month: "short",
    day: "2-digit",
  });
};

const nowFormattedDate = (now = DateTime.now()) => {
  let month = now.month < 10 ? `0${now.month}` : now.month;
  let day = now.day < 10 ? `0${now.day}` : now.day;
  let hour = now.hour < 10 ? `0${now.hour}` : now.hour;
  let minute = now.minute < 10 ? `0${now.minute}` : now.minute;
  let second = now.second < 10 ? `0${now.second}` : now.second;

  let result = `${now.year}-${month}-${day} ${hour}:${minute}:${second}`;
  return result;
};

const compareDateObjsWithoutHours = (dateA, dateB) => {
  return dateA.getDate() === dateB.getDate()
    && dateA.getMonth() === dateB.getMonth()
    && dateA.getFullYear() === dateB.getFullYear();
}

export const convertYYYYMMDDToDDMMYYYY = (stringToConvert, 
  separatorToSearchFor, 
  newSeparator, 
  forceTwoDigits, 
  keepHours,
  hoursSeparator = ' ',
  newHoursSeparator = ' ') => {
  let result = stringToConvert.split(hoursSeparator)[0]
  let hours = stringToConvert.split(hoursSeparator)[1]
  if(!keepHours) {
    hours = null
  }

  result = result.split(separatorToSearchFor)

  return buildDateOutput(result[2],
    result[1],
    result[0],  
    newSeparator, 
    forceTwoDigits, 
    hours,
    newHoursSeparator)
}

export const convertDDMMYYYYToDDMMYYYY = (stringToConvert, 
  separatorToSearchFor, 
  newSeparator, 
  forceTwoDigits, 
  keepHours,
  hoursSeparator = ' ',
  newHoursSeparator = '') => {
  let result = stringToConvert.split(hoursSeparator)[0]
  let hours = stringToConvert.split(hoursSeparator)[1]
  if(!keepHours) {
    hours = null
  }

  result = result.split(separatorToSearchFor)

  return buildDateOutput(result[0],
    result[1],
    result[2],  
    newSeparator, 
    forceTwoDigits, 
    hours,
    newHoursSeparator)
}

export const buildDateOutput = (day,
  month,
  year,
  newSeparator, 
  forceTwoDigits, 
  hours,
  newHoursSeparator = ' ') => {

  let dayToUse = day
  if(forceTwoDigits && dayToUse*1<=9) {
    dayToUse = '0' + dayToUse*1
  }
  
  let monthToUse = month
  if(forceTwoDigits && monthToUse*1<=9) {
    monthToUse = '0' + monthToUse*1
  }

  if(!forceTwoDigits) {
    dayToUse = parseInt(dayToUse)
    monthToUse = parseInt(monthToUse)
  }

  let result2 = dayToUse + newSeparator + monthToUse + newSeparator + year

  if(hours) {
    result2 = result2 + newHoursSeparator + hours
  }
  return result2
}

export { normalizeDate, calendarDate, nowFormattedDate, compareDateObjsWithoutHours };
