import { Sn } from "../icons/d-ticketing/logo";
import { Sdm } from "../icons/d-ticketing/logo";
import { DTicketingLogo } from "../icons";

export const Logo = ({ splash = false, css = "", onClick = () => {} }) => {
  let logo;

  switch (process.env.NEXT_PUBLIC_THEME) {
    case "SN":
      logo = (
        <Sn
          className={`${
            splash
              ? "w-[300px] h-[300px] md:w-[500px] md:h-[400px] animate-pulse"
              : "w-[70px] md:w-[200px] md:h-[100px]"
          } ${css}`}
          onClick={onClick}
        />
      );
      break;

    case "SDM":
      logo = (
        <Sdm
          className={`${
            splash
              ? "w-[600px] h-[400px] animate-pulse"
              : "w-[100px] h-[60px] md:w-[200px] md:h-[100px]"
          } ${css}`}
          color="#ffffff"
          onClick={onClick}
        />
      );
      break;

    case "COLLAUDO":
      logo = (
        <DTicketingLogo
          className={`${
            splash
              ? "w-[300px] h-[300px] md:w-[500px] md:h-[350px] animate-pulse "
              : "w-[100px] md:w-[150px] md:h-[80px]"
          } ${css}`}
          onClick={onClick}
        />
      );
      break;

    default:
      break;
  }

  return logo;
};
