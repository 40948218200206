
const NavEasyTicket = (props) => {

  let logoToDisplay = <img src={"/assets/images/easyticket/top-logo" + process.env.LOCALES_SUFFIX + ".png"} className={"easy-ticket-top-logo easy-ticket-top-logo" + process.env.LOCALES_SUFFIX}/>
  let navBarClasses = ' bg-app-navBarColor'
  if(props.pagePath == '/company') {
    logoToDisplay = <img src={"/assets/images/easyticket/login-top-logo" + process.env.LOCALES_SUFFIX + ".png"} className={"easy-ticket-top-logo easy-ticket-login-top-logo easy-ticket-login-top-logo" + process.env.LOCALES_SUFFIX}/>
    navBarClasses = ' company-page bg-white'
  }
  return (
    <div className={"easy-ticket-top-nav-bar easy-ticket-top-nav-bar" + process.env.LOCALES_SUFFIX + " " + navBarClasses}>
      {logoToDisplay}
    </div>
  )
};

export default NavEasyTicket;
