import { REGEXPS_MAP } from "@develon/dticketing-form-generator/dist";
import { nextAPI } from "@api/axiosAPI";

export const validateEmailAsYupDoes = (email) => {
  return REGEXPS_MAP.email.test(email);
}

export const emailValidationGCF = async (email, source) => {
  let validationResult = false;
  try {
    const response = await nextAPI.post("/gcf/validate_email", { email }, { cancelToken: source.token });
    validationResult = response?.data?.valid ?? false;
  } catch (error) {
    console.error(error);
  }
  return validationResult;
}

export const trimAndClearString = (string, clearLastDot = false) => {
  string = string.trim()

  if(clearLastDot) {
    if (string[string.length-1] === ".")
    string = string.slice(0,-1);
  }
  return string
}