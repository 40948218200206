import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { nextAPI } from "../../api/axiosAPI";
import { useAtoms } from "../../recoil/hooks";
import { downloadExportActivityInBrowser } from "../../helpers/excelReportsHelper"
import Tooltip from '@mui/material/Tooltip';
import { Button } from "../button";

const ReportButtons = ({
    setIsLoading,
    mode = "assign",
    product_code = "",
    search = "",
    tabName = "",
}) => {

    const { t, lang } = useTranslation();
    const translationCode = `common${process.env.LOCALES_SUFFIX}:report_buttons`;
    const router = useRouter();
    const { state } = useAtoms();

    const [lastGeneratedReport, setLastGeneratedReport] = useState(null);
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);
    const [noReportsAvailable, setNoReportsAvailable] = useState(false);

    const productsCategory = router.asPath === "/" ? "g" : "o";
    const withDetails = process.env.IS_DIREZIONE === "true";

    useEffect(() => {
        const checkForReports = async () => {
            const b = {
                params: {
                    type: `LATEST_TICKET_${mode.toUpperCase()}_EVENTS_SUMMARY`,
                    customer_id_research_filter: state.customer.id,
                    size: 1,
                    sort_by: "created_at",
                    sort_order: "desc",
                    product_categories_research_filter: productsCategory,
                }
            }
            const r = await nextAPI.post(
                `/reports/list`,
                b
            )
            const reports = r.data || [];
            const lastGeneratedReport = reports.find(r => r.status.toLowerCase() === "generated");
            const currentlyGeneratingReport = reports.at(0)?.status.toLowerCase() === "generating";

            if (currentlyGeneratingReport) {
                setIsGeneratingReport(true);
            } else if (lastGeneratedReport) {
                const parsedFiltersLastGeneratedReport = {
                    ...lastGeneratedReport,
                    research_filters: JSON.parse(lastGeneratedReport.research_filters),
                }
                setLastGeneratedReport(parsedFiltersLastGeneratedReport);
                setIsGeneratingReport(false);
            } else {
                setLastGeneratedReport(null);
                setIsGeneratingReport(false);
                setNoReportsAvailable(true);
            }
                    }

        checkForReports();

        const pollingCheck = setInterval(checkForReports, 5000);

        return () => {
            clearInterval(pollingCheck);
        }
    }, [])

    const handleGenerateReport = async () => {
        const b = {
            params: {
                mode,
                withDetails,
            },
            payload: {
                customer_id: state.customer.id,
                categories: [productsCategory],
                ...(product_code && product_code !== "all" ? {product_code} : {}),
                ...(search ? {search} : {}),
            }
        }
        const generationRes = await nextAPI.post(`/reports/generate`, b)
        if (generationRes.data?.result?.status.toLowerCase() === "generating") {
            setIsGeneratingReport(true);
        }
            }

    const handleDownloadReport = async () => {
        setIsLoading(true);
        const {
            id,
            research_filters: {
                search,
                product_code,
            }
        } = lastGeneratedReport;

        const sneakCaseTabName = tabName.toLowerCase().replace(" ", "_");
        const fileName = `${sneakCaseTabName}${search ? '_ricerca_' + search : ''}${product_code && product_code !== "all" ? '_product_' + product_code : ''}`;

        await downloadExportActivityInBrowser(state,
            id,
            window,
            document,
            fileName,
            lang
        )
        setIsLoading(false);
            }

    const isGenerationDisabled = isGeneratingReport

    const generateReportButton = (
        <Tooltip title={isGenerationDisabled ? t(`${translationCode}.disabled_generate_generating`) : t(`${translationCode}.generate_report`)}>
            <span>
                <Button
                    disabled={isGenerationDisabled}
                    onClick={handleGenerateReport}
                    css=" m-0 filter-button mr-2"
                    variant={isGenerationDisabled ? "disabled-btn" : "primary"}
                >
                {t(`${translationCode}.generate`)}
                </Button>
            </span>
        </Tooltip>
    )

    const isDownloadDisabled = noReportsAvailable || isGeneratingReport;
    const disabledText = isDownloadDisabled ? t(`${translationCode}.disabled_download_no_reports`) : t(`${translationCode}.disabled_download_generating`);

    const downloadReportButton = (
        <Tooltip title={isDownloadDisabled ? disabledText : t(`${translationCode}.download_report`)}>
            <span>
                <Button
                    disabled={noReportsAvailable || isGeneratingReport}
                    onClick={handleDownloadReport}
                    css=" m-0 filter-button"
                    variant={isDownloadDisabled ? "disabled-btn" : "primary"}
                >
                    {t(`${translationCode}.download`)}
                </Button>
            </span>
        </Tooltip>
    )

    return (
        <div
            className="flex flex-row justify-center items-center"
        >
            {generateReportButton}
            {downloadReportButton}
        </div>
    )
}

export {
    ReportButtons
};