//Css
import classes from "./Spinner.module.css";

const Spinner = () => (
  <div className={classes.Root}>
    <div className={classes.Ripple}>
      <div className="border-app-orange border-4"></div>
      <div className="border-app-orange border-4"></div>
    </div>
  </div>
);

export default Spinner;
