import classes from './TicketsWizardEmailField.module.css';
import { useEffect, useState, useRef } from 'react'
import axios from 'axios';

const TicketsWizardEmailField = ((props) => {
  const {
    disabled,
    errors,
    onChange,
    validationCallback,
    value,
  } = props


  const realtimeValidationRef = useRef(0);
  const [apiCancelSource, setApiCancelSource] = useState(null);
  const [lastValue, setLastValue] = useState(null);

  useEffect(() => {
    const handleAsyncEffect = async (_value) => {
      let valid = null
      let serviceValidationId = null
      try {
        const source = axios.CancelToken.source();
        setApiCancelSource(source); 
        const response = await axios.post(`${process.env.NEXT_PUBLIC_FRONT_END_BASE_URL}/api/gcf/validate_email`, { email: value }, { cancelToken: source.token });
        valid = response?.data?.valid ?? false;
        serviceValidationId = response?.data?.request_uuid ?? null;
        validationCallback(response?.data)
      } catch (error) {
        console.error(error);
      } finally {
        setApiCancelSource(null);
        setLastValue(_value);
      }
    }

    if (value === lastValue) {
      return;
    }

    const startValidation = value && (Array.isArray(errors) && errors.length === 0);
    
    if (startValidation) {
      if (realtimeValidationRef.current) {
        clearTimeout(realtimeValidationRef.current);
      }
      realtimeValidationRef.current = setTimeout(() => {
        handleAsyncEffect(value);
      }, 300);
    } else {
      let resetValidationCallSource = false
      if (apiCancelSource) {
        resetValidationCallSource = true
        apiCancelSource.cancel();
      }
      if (resetValidationCallSource) {
       setApiCancelSource(null);
      }
    }

    return () => {
      if (realtimeValidationRef.current) {
        clearTimeout(realtimeValidationRef.current);
      }
    };
  }, [value, errors])


  return (
    <div>
      <input
        disabled={disabled}
        value={value}
        onChange={onChange}
        className={classes.bgInput}
      />
    </div>
  )

})

export default TicketsWizardEmailField;