import * as React from "react";

function SvgSn(props: React.SVGProps<SVGSVGElement>) {
  return (

     <svg 
     id="Livello_1" 
     data-name="Livello 1" 
     version="1.0" 
     xmlns="http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd"
    viewBox="0 0 10931.000000 2717.000000"
 preserveAspectRatio="xMidYMid meet"
     {...props}>
    
    <defs><style>{".cls-1{fill:#fff;}"}</style></defs>
    <g transform="translate(0.000000,2717.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path className="cls-1" d="M3860 19779 l0 -7392 197 284 c1499 2166 3398 4548 5062 6349 656
    710 1487 1547 2096 2111 1592 1472 3051 2511 4357 3103 655 296 1272 478 1873
    552 176 21 614 30 785 15 719 -63 1332 -308 1898 -760 711 -567 1317 -1476
    1788 -2678 63 -161 214 -577 214 -590 0 -3 -25 34 -56 83 -392 624 -1081 1135
    -1829 1358 -396 117 -721 161 -1195 160 -398 0 -665 -27 -1080 -110 -961 -192
    -2062 -653 -3209 -1341 -2493 -1496 -5344 -4087 -8536 -7758 -511 -588 -1219
    -1425 -1211 -1433 3 -2 130 90 283 205 1117 838 2555 1772 3988 2590 3904
    2230 7854 3712 11560 4338 564 95 855 124 1330 132 354 6 583 -4 890 -38 1563
    -173 2828 -888 3560 -2012 756 -1162 922 -2702 481 -4469 -28 -115 -49 -208
    -45 -208 3 0 32 39 64 88 344 518 920 1140 1486 1604 645 528 1391 944 2127
    1184 l172 56 0 5984 0 5984 -13525 0 -13525 0 0 -7391z"/>
    <path className="cls-1" d="M56700 27154 c-130 -12 -319 -38 -425 -60 -1154 -240 -1910 -1075
    -2094 -2314 -58 -391 -62 -846 -11 -1245 91 -709 350 -1285 765 -1700 528
    -527 1293 -786 2201 -744 316 14 582 62 864 155 840 278 1419 920 1649 1830
    78 307 110 568 118 944 12 595 -64 1072 -243 1517 -362 904 -1102 1458 -2134
    1599 -110 14 -585 27 -690 18z m410 -274 c270 -28 506 -112 720 -255 592 -397
    932 -1198 969 -2285 38 -1145 -209 -2018 -714 -2515 -225 -222 -479 -358 -795
    -426 -91 -20 -135 -23 -325 -24 -249 0 -345 13 -523 71 -564 184 -955 642
    -1177 1379 -172 570 -213 1452 -100 2132 132 787 454 1365 940 1683 289 189
    655 276 1005 240z"/>
    <path className="cls-1" d="M37987 27130 c-326 -35 -676 -156 -927 -322 -116 -77 -307 -262 -377
    -368 -100 -149 -164 -302 -206 -488 -18 -84 -21 -128 -22 -327 0 -208 2 -239
    24 -325 103 -419 377 -766 866 -1096 168 -113 320 -200 693 -396 492 -257 632
    -337 857 -489 401 -270 592 -527 636 -854 49 -368 -183 -764 -545 -930 -179
    -83 -357 -118 -596 -118 -548 0 -1029 220 -1316 602 -121 162 -228 406 -273
    626 l-18 90 -136 3 c-117 2 -137 0 -137 -13 0 -36 54 -288 85 -394 188 -652
    590 -1043 1230 -1194 165 -39 317 -57 546 -64 412 -12 732 46 1029 188 465
    222 794 664 886 1189 20 113 22 357 4 475 -67 454 -321 796 -864 1163 -242
    164 -411 260 -963 545 -576 299 -838 469 -1045 680 -369 374 -367 830 4 1183
    289 274 659 361 1123 264 395 -82 699 -310 897 -670 41 -75 121 -273 139 -342
    l11 -48 149 0 c125 0 149 2 149 15 0 33 -81 327 -114 413 -226 589 -649 910
    -1303 991 -123 15 -386 21 -486 11z"/>
    <path className="cls-1" d="M43190 24138 c-712 -1590 -1300 -2904 -1307 -2920 l-12 -28 202 0
    202 0 246 548 246 547 1467 0 1468 0 248 -545 249 -545 490 -3 491 -2 -13 27
    c-184 412 -2667 5808 -2673 5810 -5 1 -592 -1299 -1304 -2889z m1690 -73 c364
    -792 664 -1446 667 -1452 4 -10 -263 -13 -1317 -13 -730 0 -1320 4 -1318 9 20
    51 1302 2901 1304 2898 2 -1 301 -650 664 -1442z"/>
    <path className="cls-1" d="M48430 27007 c0 -20 10 -26 68 -45 94 -30 244 -105 318 -157 137 -98
    244 -248 291 -410 17 -56 18 -211 21 -2632 l2 -2573 1875 0 1875 0 0 185 0
    185 -1475 0 -1475 0 0 2735 0 2735 -750 0 -750 0 0 -23z"/>
    <path className="cls-1" d="M61420 27006 c0 -18 7 -26 28 -31 15 -3 68 -26 117 -49 310 -147 483
    -448 535 -928 6 -64 10 -897 10 -2453 l0 -2355 185 0 185 0 0 2075 c0 1141 2
    2075 5 2075 3 0 956 -933 2117 -2072 1161 -1140 2115 -2074 2120 -2076 4 -2 8
    1311 8 2917 l0 2921 -185 0 -185 0 -2 -2074 -3 -2075 -1870 1816 c-1028 998
    -1913 1851 -1965 1895 -201 168 -426 300 -630 367 -116 39 -296 71 -396 71
    -71 0 -74 -1 -74 -24z"/>
    <path className="cls-1" d="M68620 27007 c0 -19 12 -26 88 -52 315 -107 520 -299 599 -560 17
    -57 18 -189 20 -2632 l3 -2573 1840 0 1840 0 0 155 0 155 -1400 0 -1400 0 0
    1275 0 1275 1170 0 1170 0 0 185 0 185 -1170 0 -1170 0 0 1150 0 1150 1400 0
    1400 0 0 155 0 155 -2195 0 -2195 0 0 -23z"/>
    <path className="cls-1" d="M103185 19020 c-2521 -143 -4312 -1591 -4910 -3970 -166 -658 -234
    -1301 -222 -2108 13 -894 122 -1600 357 -2312 96 -294 176 -488 306 -750 339
    -682 794 -1236 1379 -1680 933 -708 2221 -1081 3615 -1046 999 25 1830 218
    2590 601 1103 557 1883 1453 2341 2687 387 1043 522 2461 358 3763 -153 1217
    -571 2239 -1247 3050 -127 152 -475 492 -637 621 -831 664 -1833 1038 -3035
    1134 -188 15 -703 20 -895 10z m840 -549 c554 -85 1038 -295 1475 -642 131
    -103 399 -371 506 -504 400 -501 700 -1119 895 -1845 211 -787 307 -1717 278
    -2707 -44 -1561 -358 -2760 -953 -3643 -360 -535 -822 -931 -1362 -1171 -639
    -283 -1413 -344 -2117 -168 -799 200 -1462 703 -1939 1469 -601 965 -899 2326
    -875 3995 6 415 14 563 47 900 120 1201 451 2202 974 2945 184 262 475 573
    696 744 464 359 976 574 1520 635 52 6 109 13 125 15 17 2 156 3 310 1 224 -2
    308 -7 420 -24z"/>
    <path className="cls-1" d="M91208 18959 c-1136 -48 -2171 -402 -3003 -1028 -270 -202 -624 -538
    -826 -781 -745 -898 -1196 -2005 -1338 -3285 -35 -313 -44 -490 -44 -850 0
    -483 29 -832 104 -1273 277 -1632 1114 -2996 2331 -3799 265 -174 636 -368
    918 -479 940 -370 2047 -481 3160 -318 925 135 1747 464 2443 977 482 355 932
    843 1180 1279 l27 46 -196 189 c-107 103 -199 188 -203 188 -4 0 -49 -48 -99
    -108 -733 -853 -1557 -1445 -2395 -1721 -237 -78 -533 -140 -786 -166 -151
    -15 -514 -12 -671 5 -709 78 -1375 374 -1980 879 -139 116 -408 382 -526 521
    -671 788 -1117 1806 -1273 2910 -46 321 -55 473 -56 885 0 401 7 530 46 850
    239 1994 1317 3598 2810 4184 262 102 589 181 879 212 188 20 601 14 775 -10
    1005 -142 1946 -665 2840 -1577 159 -162 331 -351 433 -474 l33 -40 189 195
    c104 107 189 200 189 205 1 13 -116 200 -187 299 -194 269 -552 652 -807 862
    -307 253 -614 453 -964 629 -732 366 -1510 557 -2431 595 -267 11 -296 11
    -572 -1z"/>
    <path className="cls-1" d="M35190 18736 l0 -43 93 -32 c133 -47 287 -122 402 -198 143 -95 337
    -291 438 -443 255 -385 383 -864 417 -1552 6 -127 10 -1875 10 -4660 l0 -4458
    355 0 355 0 2 4072 3 4072 4045 -3968 c2225 -2183 4093 -4016 4153 -4075 l107
    -106 0 5718 0 5717 -355 0 -355 0 -2 -4072 -3 -4072 -3690 3581 c-2029 1970
    -3739 3623 -3800 3673 -504 420 -951 667 -1437 795 -204 53 -501 95 -680 95
    l-58 0 0 -44z"/>
    <path className="cls-1" d="M52236 18749 c-8 -19 -1156 -2581 -2551 -5694 -1396 -3113 -2540
    -5670 -2542 -5682 l-5 -23 384 0 385 0 484 1078 485 1077 2872 0 2873 0 491
    -1077 492 -1078 948 0 c521 0 948 3 948 6 0 11 -5232 11408 -5241 11418 -5 6
    -15 -5 -23 -25z m788 -5787 c718 -1564 1306 -2845 1306 -2847 0 -3 -1166 -5
    -2591 -5 -1691 0 -2590 3 -2587 10 22 65 2555 5697 2560 5692 4 -4 594 -1286
    1312 -2850z"/>
    <path className="cls-1" d="M57500 18737 l0 -43 133 -43 c369 -122 625 -270 848 -491 203 -202
    314 -402 386 -695 16 -65 17 -301 23 -3420 7 -3641 3 -3432 60 -3780 115 -712
    366 -1277 781 -1760 614 -716 1604 -1190 2799 -1339 253 -32 342 -37 660 -43
    927 -16 1689 101 2345 358 1286 504 2064 1546 2239 2999 34 279 36 494 36
    4403 l0 3897 -360 0 -360 0 -3 -4062 c-3 -4505 3 -4102 -67 -4448 -91 -453
    -272 -856 -538 -1200 -89 -115 -285 -314 -407 -415 -537 -442 -1262 -721
    -2120 -815 -177 -19 -541 -29 -695 -19 -444 29 -876 151 -1235 347 -723 395
    -1181 1090 -1339 2032 -65 384 -60 15 -63 4503 l-3 4077 -1560 0 -1560 0 0
    -43z"/>
    <path className="cls-1" d="M69370 18425 l0 -355 1895 0 1895 0 0 -5360 0 -5360 865 0 865 0 0
    5360 0 5360 1885 0 1885 0 0 355 0 355 -4645 0 -4645 0 0 -355z"/>
    <path className="cls-1" d="M80220 18738 l0 -43 148 -48 c595 -195 989 -526 1159 -977 14 -36 37
    -112 51 -170 l27 -105 2 -5022 3 -5023 785 0 785 0 0 5715 0 5715 -1480 0
    -1480 0 0 -42z"/>
    <path className="cls-1" d="M18450 15323 c-25 -1 -124 -7 -220 -13 -655 -41 -1377 -149 -2170
    -325 -1600 -356 -3246 -864 -4935 -1523 -1450 -566 -3357 -1443 -6555 -3015
    -734 -361 -840 -415 -1515 -767 -187 -97 -950 -491 -1695 -875 -745 -384
    -1357 -700 -1359 -702 -2 -2 -2 -5 0 -7 3 -3 58 15 124 40 209 79 497 156 750
    201 154 27 617 25 812 -5 683 -103 1213 -378 1589 -825 309 -367 488 -817 561
    -1407 15 -122 17 -411 20 -3057 l4 -2923 8722 0 8722 0 99 258 c181 471 376
    994 468 1252 1070 3026 1638 5512 1738 7605 13 269 13 865 0 1110 -72 1369
    -385 2414 -977 3264 -168 241 -444 546 -668 740 -463 400 -1026 694 -1626 850
    -192 49 -571 99 -929 121 -146 9 -842 11 -960 3z"/>
    <path className="cls-1" d="M33696 14420 c-1314 -78 -2545 -644 -3761 -1729 -161 -143 -571 -551
    -730 -726 -1160 -1272 -2247 -3002 -3242 -5160 -890 -1929 -1759 -4373 -2293
    -6450 -28 -110 -54 -208 -56 -217 -5 -17 173 -18 3645 -18 l3650 0 4 3578 c3
    2568 7 3632 15 3772 35 588 63 922 108 1300 232 1970 775 3458 1617 4436 125
    144 365 378 502 488 132 106 331 240 475 321 314 177 709 305 1023 334 60 5
    111 12 113 14 6 5 -140 24 -326 42 -192 18 -559 26 -744 15z"/>
    <path className="cls-1" d="M38390 4624 c-559 -42 -1014 -247 -1365 -615 -311 -326 -505 -746
    -587 -1274 -19 -119 -22 -182 -22 -415 0 -304 14 -435 73 -675 230 -935 906
    -1530 1835 -1616 158 -14 458 -7 606 16 440 67 792 226 1104 499 89 78 204
    210 267 305 l49 75 0 653 0 653 -275 0 -275 0 0 -764 0 -765 -57 -44 c-317
    -244 -704 -369 -1040 -337 -236 22 -435 93 -643 228 -561 363 -906 1100 -876
    1867 35 917 511 1667 1196 1883 142 45 228 57 395 56 214 -1 391 -42 604 -140
    243 -112 558 -359 761 -598 35 -42 67 -76 70 -76 4 0 39 36 79 81 l73 80 -47
    72 c-298 450 -813 752 -1417 831 -119 16 -409 27 -508 20z"/>
    <path className="cls-1" d="M53760 4624 c-14 -2 -65 -9 -115 -15 -486 -58 -954 -296 -1251 -637
    -270 -310 -442 -715 -511 -1202 -22 -158 -25 -686 -5 -850 99 -799 456 -1361
    1055 -1661 192 -97 378 -156 622 -200 170 -30 573 -38 765 -15 549 67 1000
    294 1301 654 261 313 409 668 480 1152 26 178 36 573 20 771 -41 484 -173 875
    -409 1209 -82 116 -298 334 -408 413 -290 205 -619 327 -1002 372 -114 13
    -470 19 -542 9z m533 -237 c220 -58 378 -150 547 -317 248 -245 399 -556 490
    -1010 91 -454 89 -1045 -6 -1508 -80 -391 -231 -708 -442 -933 -405 -431
    -1089 -503 -1579 -167 -101 69 -242 210 -322 323 -77 110 -187 333 -234 475
    -107 323 -151 637 -151 1080 0 537 75 931 244 1287 159 334 363 552 640 688
    186 90 311 116 545 111 143 -3 186 -8 268 -29z"/>
    <path className="cls-1" d="M72850 4624 c-14 -2 -65 -9 -115 -15 -489 -58 -965 -302 -1256 -644
    -270 -316 -425 -677 -501 -1165 -20 -128 -22 -183 -22 -470 0 -341 6 -421 55
    -670 41 -206 133 -468 221 -630 310 -567 827 -900 1528 -986 192 -23 595 -15
    765 15 352 63 649 184 890 363 440 327 704 828 787 1498 20 165 17 704 -5 855
    -29 190 -65 349 -114 495 -256 769 -856 1248 -1689 1345 -115 13 -471 19 -544
    9z m446 -219 c252 -44 453 -151 644 -344 192 -192 328 -438 424 -761 114 -386
    152 -923 100 -1418 -101 -970 -542 -1552 -1237 -1633 -228 -26 -488 17 -682
    113 -176 88 -353 242 -473 413 -128 181 -246 466 -307 738 -109 493 -109 1135
    1 1621 83 370 254 712 463 924 181 183 403 303 646 347 99 19 315 18 421 0z"/>
    <path className="cls-1" d="M100293 4615 c-556 -70 -1019 -315 -1333 -706 -292 -364 -453 -834
    -479 -1406 -43 -932 236 -1661 794 -2076 253 -188 533 -303 900 -368 170 -30
    573 -38 765 -15 951 116 1579 710 1755 1661 41 224 50 335 50 630 0 376 -33
    617 -121 895 -243 769 -804 1245 -1619 1376 -141 22 -563 28 -712 9z m620
    -228 c403 -106 718 -408 901 -865 151 -376 218 -855 195 -1386 -36 -825 -278
    -1397 -719 -1696 -336 -228 -823 -263 -1198 -85 -517 245 -809 811 -873 1690
    -14 194 -6 555 16 745 72 614 295 1098 628 1365 177 142 340 214 587 259 14 2
    102 3 195 1 144 -2 185 -7 268 -28z"/>
    <path className="cls-1" d="M90050 4604 c-217 -39 -372 -89 -522 -165 -310 -160 -502 -406 -568
    -730 -29 -139 -25 -400 9 -523 78 -291 293 -555 646 -795 131 -89 257 -161
    553 -315 740 -386 982 -583 1079 -881 22 -67 26 -98 26 -195 1 -97 -3 -127
    -25 -192 -87 -263 -293 -440 -585 -506 -111 -24 -405 -24 -522 1 -390 84 -658
    275 -817 580 -50 97 -96 223 -114 314 -20 101 -8 93 -126 93 l-105 0 7 -37
    c49 -280 110 -465 205 -627 200 -338 502 -525 957 -593 167 -24 494 -22 646 6
    296 53 523 167 712 355 179 179 292 396 340 651 23 123 15 376 -14 487 -52
    192 -140 337 -306 504 -214 213 -447 365 -1005 654 -307 158 -446 236 -572
    321 -254 170 -395 326 -454 504 -121 359 180 755 631 831 179 31 449 -10 629
    -95 260 -122 448 -348 546 -658 l23 -73 113 0 c62 0 113 4 113 9 0 5 -13 62
    -30 126 -133 530 -425 824 -920 927 -73 15 -145 20 -310 23 -118 2 -226 1
    -240 -1z"/>
    <path className="cls-1" d="M41662 4519 c2 -16 20 -28 78 -49 255 -92 405 -243 449 -448 8 -38
    11 -597 11 -1987 l0 -1935 1400 0 1400 0 0 120 0 120 -1065 0 -1065 0 0 965 0
    965 885 0 885 0 0 140 0 140 -885 0 -885 0 0 875 0 875 1065 0 1065 0 0 120 0
    120 -1671 0 -1670 0 3 -21z"/>
    <path className="cls-1" d="M46280 4516 c0 -14 6 -26 13 -26 25 0 163 -72 215 -112 148 -114 251
    -341 281 -616 8 -71 11 -649 11 -1882 l0 -1780 140 0 140 0 2 1576 3 1576
    1445 -1417 c795 -779 1518 -1490 1608 -1578 l162 -162 0 2223 0 2222 -140 0
    -140 0 -2 -1574 -3 -1573 -1405 1364 c-773 751 -1444 1398 -1493 1439 -253
    214 -474 311 -769 339 l-68 7 0 -26z"/>
    <path className="cls-1" d="M58172 2328 c-546 -1218 -992 -2217 -992 -2221 0 -4 69 -7 153 -7
    l152 0 187 418 187 417 1111 0 1110 0 78 -170 c43 -93 129 -281 191 -418 l114
    -247 369 0 c348 0 370 1 365 18 -6 24 -2018 4405 -2027 4415 -3 4 -453 -988
    -998 -2205z m1287 -47 c276 -601 500 -1095 498 -1097 -2 -2 -450 -3 -995 -2
    l-991 3 492 1097 c270 603 492 1096 493 1095 1 -1 227 -494 503 -1096z"/>
    <path className="cls-1" d="M65602 4519 c2 -19 15 -25 76 -39 222 -52 401 -234 447 -455 13 -60
    15 -341 15 -1998 l0 -1929 943 5 c798 4 961 7 1065 21 612 81 1010 309 1210
    694 99 191 134 364 113 565 -48 477 -309 778 -819 947 -64 21 -111 39 -104 39
    7 1 59 23 116 49 267 127 453 311 545 543 55 137 65 197 66 384 0 150 -3 180
    -24 255 -126 448 -444 750 -916 873 -240 62 -196 60 -1523 64 l-1214 4 4 -22z
    m2293 -228 c445 -92 716 -444 717 -931 0 -263 -77 -457 -248 -621 -108 -103
    -214 -164 -369 -212 -67 -20 -91 -21 -627 -25 l-558 -3 0 905 0 906 498 0
    c445 0 506 -2 587 -19z m-160 -2022 c279 -34 546 -144 710 -292 171 -154 261
    -339 286 -588 25 -251 -43 -503 -182 -677 -112 -141 -320 -264 -544 -324
    l-120 -32 -537 -4 -538 -3 0 965 0 966 418 0 c241 0 455 -5 507 -11z"/>
    <path className="cls-1" d="M77262 2327 c-546 -1218 -992 -2217 -992 -2220 0 -4 69 -7 153 -7
    l152 0 124 278 c69 152 154 341 189 420 l65 142 1109 -2 1110 -3 190 -417 191
    -418 369 0 c348 0 370 1 365 18 -6 24 -2017 4405 -2026 4415 -4 4 -454 -989
    -999 -2206z m1288 -45 c276 -602 500 -1096 497 -1098 -2 -2 -450 -3 -994 -2
    l-991 3 491 1097 c271 603 493 1096 493 1095 1 -1 228 -494 504 -1095z"/>
    <path className="cls-1" d="M81100 4395 l0 -145 735 0 735 0 0 -2075 0 -2075 335 0 335 0 0 2075
    0 2075 730 0 730 0 0 145 0 145 -1800 0 -1800 0 0 -145z"/>
    <path className="cls-1" d="M92850 4518 c0 -21 8 -25 80 -41 212 -48 366 -188 437 -397 17 -53
    18 -140 21 -2017 l2 -1963 335 0 335 0 0 1070 0 1070 1110 0 1110 0 2 -937 3
    -938 23 -47 c40 -79 96 -134 180 -175 l76 -38 464 -3 464 -3 -4 23 c-3 20 -14
    26 -83 44 -123 31 -190 69 -276 154 -87 86 -142 182 -165 289 -11 55 -14 379
    -14 1999 l0 1932 -335 0 -335 0 0 -1030 0 -1030 -1110 0 -1110 0 0 880 c0 839
    -1 883 -19 934 -38 107 -103 176 -211 222 -42 17 -79 19 -512 22 l-468 3 0
    -23z"/>
    <path className="cls-1" d="M103500 4518 c0 -21 10 -26 104 -51 160 -44 267 -125 330 -250 18
    -36 1377 -3752 1528 -4180 6 -16 14 -25 18 -20 3 4 240 693 526 1530 285 836
    522 1516 525 1510 4 -7 244 -695 535 -1530 290 -835 530 -1514 534 -1510 8 8
    1700 4505 1700 4517 0 3 -69 6 -153 6 l-152 0 -585 -1564 c-322 -861 -588
    -1561 -591 -1558 -4 4 -249 708 -545 1565 -295 857 -540 1554 -544 1550 -4 -5
    -250 -718 -546 -1585 -296 -868 -542 -1578 -545 -1578 -3 0 -232 624 -508
    1388 -277 763 -520 1423 -541 1467 -64 133 -187 240 -329 287 -61 20 -90 22
    -413 26 l-348 4 0 -24z"/>
    </g>
    </svg>
  );
}

export default SvgSn;
