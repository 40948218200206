import * as React from "react";

function SvgEmail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 16 16"
      fill={props.fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.333 2.667H4c-.735 0-1.333.598-1.333 1.333v3.333H4v-2l4.267 3.2a.667.667 0 00.8 0l4.266-3.2v6H8v1.334h5.333c.736 0 1.334-.598 1.334-1.334V4c0-.735-.598-1.333-1.334-1.333zm-4.666 4.5L4.444 4h8.445L8.667 7.167z"
        fill={props.fill || "#000"}
      />
      <path
        d="M1.333 8H6v1.333H1.333V8zm1.334 2h4v1.333h-4V10zm2 2h2.666v1.333H4.667V12z"
        fill={props.fill || "#000"}
      />
    </svg>
  );
}

export default SvgEmail;
