import * as React from "react";

const SvgRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.484468 0.23456C0.519302 0.199638 0.560684 0.17193 0.606243 0.153026C0.651802 0.134121 0.700643 0.12439 0.749968 0.12439C0.799294 0.12439 0.848135 0.134121 0.893694 0.153026C0.939252 0.17193 0.980634 0.199638 1.01547 0.23456L5.51547 4.73456C5.55039 4.76939 5.5781 4.81078 5.597 4.85633C5.61591 4.90189 5.62564 4.95073 5.62564 5.00006C5.62564 5.04939 5.61591 5.09823 5.597 5.14378C5.5781 5.18934 5.55039 5.23073 5.51547 5.26556L1.01547 9.76556C0.945053 9.83597 0.84955 9.87553 0.749968 9.87553C0.650386 9.87553 0.554883 9.83597 0.484468 9.76556C0.414053 9.69514 0.374494 9.59964 0.374494 9.50006C0.374494 9.40048 0.414053 9.30497 0.484468 9.23456L4.71972 5.00006L0.484468 0.76556C0.449546 0.730726 0.421839 0.689344 0.402934 0.643785C0.384029 0.598226 0.374298 0.549385 0.374298 0.50006C0.374298 0.450735 0.384029 0.401894 0.402934 0.356335C0.421839 0.310776 0.449546 0.269394 0.484468 0.23456Z"
      fill="black"
    />
  </svg>
);

export default SvgRight;
