import * as React from "react";

function SvgSort(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.464 5.25L8 0 4.536 5.25h6.928zM4.536 10.75L8 16l3.464-5.25H4.536z"
        fill="#343A40"
      />
    </svg>
  );
}

export default SvgSort;
