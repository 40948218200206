import { useEffect, useState, useRef } from 'react'
import useTranslation from "next-translate/useTranslation"
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import VerifiedIcon from '@mui/icons-material/Verified';
import DangerousIcon from '@mui/icons-material/Dangerous';
import Box from '@mui/material/Box';

const EmailField = ({
    name,
    label = null,
    onChange = () => { },
    onBlur = () => { },
    id = null,
    css = "",
    placeholder,
    icon,
    compCss,
    field = null,
    error = false,
    multipleSelectIndex = false,

    isRealtimeValidationEnabled = null,
    updateRealtimeValidationOutcome = () => { },

    onKeyUp = null,
    maxLength = null,
    inputType = "text",
    min = "1",
    max = "10000000000",
    labelFontSize = false,
    disabled,
    pageTranslationCode = null,
    labelBottomMargin = "",
    ...rest
}) => {
    const { t } = useTranslation(`common${process.env.LOCALES_SUFFIX}`);
    const [value, setValue] = useState(null)

    const realtimeValidationRef = useRef(0);
    const [verificationProgressFeedback, setVerificationProgressFeedback] = useState(0);

    const [realtimeValidationMap, setRealtimeValidationMap] = useState({
        inProgress: false,
        deferring: false,
        serviceValidationId: null,
        email: null,
        valid: null,
        apiCancelSource: null
    });

    const updateRealtimeValidationMap = async ({
        serviceValidationId = null,
        deferring = null,
        valid, // true, false, null (null means that the validation has not been done yet)
        email = null,
        inProgress = null,
        apiCancelSource = null
    }) => {
        const updatedState = {
            ...realtimeValidationMap,
            ...(serviceValidationId !== null && { serviceValidationId }),
            valid,
            ...(email !== null && { email }),
            ...(deferring !== null && { deferring }),
            ...(inProgress !== null && { inProgress }),
            ...(apiCancelSource !== null && { apiCancelSource })
        }

        setRealtimeValidationMap(updatedState)

        updateRealtimeValidationOutcome({
            ...updatedState,
            fieldName: name,
            label: label ?? placeholder,
        })
    };


    const onChangeHandler = (e) => {
        onChange(e.target.value, multipleSelectIndex);
        setValue(e.target.value);
    };

    let labelFontSizeToUse = 'font-normal'
    if (labelFontSize) {
        labelFontSizeToUse = labelFontSize
    }


    useEffect(() => {
        let progressFeedbackTimer = null

        const handleAsyncEffect = async (_value) => {
            let valid = null
            let serviceValidationId = null
            try {
                const source = axios.CancelToken.source();
                updateRealtimeValidationMap({
                    ...realtimeValidationMap,
                    inProgress: true,
                    deferring: false,
                    apiCancelSource: source,
                    email: _value,
                })
                progressFeedbackTimer = setInterval(() => {
                    setVerificationProgressFeedback((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
                }, 200);
                const response = await axios.post(`${process.env.NEXT_PUBLIC_FRONT_END_BASE_URL}/api/gcf/validate_email`, { email: value }, { cancelToken: source.token });
                valid = response?.data?.valid ?? false;
                serviceValidationId = response?.data?.request_uuid ?? null;
            } catch (error) {
              console.error(error) 
                            } finally {
                clearInterval(progressFeedbackTimer);
                setVerificationProgressFeedback(100);
                updateRealtimeValidationMap({
                    ...realtimeValidationMap,
                    serviceValidationId,
                    valid,
                    inProgress: false,
                    deferring: false,
                    apiCancelSource: null,
                    email: _value,
                })

                setVerificationProgressFeedback(0);
            }
        }

        if (isRealtimeValidationEnabled === true) {
            const startValidation = value && !error;
            if (startValidation) {
                if (realtimeValidationRef.current) {
                    clearTimeout(realtimeValidationRef.current);
                }
                updateRealtimeValidationMap({
                    ...realtimeValidationMap,
                    deferring: true,
                    email: value,
                })
                realtimeValidationRef.current = setTimeout(() => {
                    handleAsyncEffect(value);
                }, 300);
            } else {
                let resetValidationCallSource = false
                if (realtimeValidationMap.apiCancelSource) {
                    resetValidationCallSource = true
                    realtimeValidationMap.apiCancelSource.cancel();
                }
                updateRealtimeValidationMap({
                    ...realtimeValidationMap,
                    inProgress: false,
                    deferring: false,
                    valid: null,
                   ...(resetValidationCallSource && { apiCancelSource: null })
                })
            }
        }

        return () => {
            if (realtimeValidationRef.current) {
                clearTimeout(realtimeValidationRef.current);
                clearInterval(progressFeedbackTimer);
            }
        };
    }, [value, error])


    const realtimeValidationLabel = () => {
        let labelColor = null
        let status = null
        
        if (realtimeValidationMap.inProgress === true) {
            status = "progress"
            labelColor = "text-app-yellowDark"
        } else if (realtimeValidationMap.valid === true) {
            status = "success"
            labelColor = "text-app-checkGreen"
        } else if (realtimeValidationMap.valid === false) {
            status = "error"
            labelColor = "text-app-redLight"
        }

        return (
            <span
                className='ml-auto'
            >
                {status === "progress" && (
                    <Box sx={{ position: 'relative', display: 'inline-flex', margin: 0 }}>
                        <CircularProgress variant="determinate"
                            className={`${labelColor}`}
                            color='info'
                            variant='determinate'
                            size={32}
                            sx={{
                                color: '#FFF3CD'
                            }}
                            value={verificationProgressFeedback}
                        />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                className='text-app-grey !text-2xs'
                            >{`${Math.round(verificationProgressFeedback)}%`}</div>
                        </Box>
                    </Box>
                )}
                {status === "success" && (
                    <VerifiedIcon
                        className={`${labelColor} !text-2xl `}
                    />
                )}
                {status === "error" && (
                    <DangerousIcon
                        className={`${labelColor} !text-2xl`}
                    />
                )}
            </span>
        )
    }

    const realTimeValidationError = isRealtimeValidationEnabled &&  realtimeValidationMap.inProgress === false && realtimeValidationMap.valid === false
    const realTimeValidationSuccess = isRealtimeValidationEnabled && realtimeValidationMap.inProgress === false && realtimeValidationMap.valid === true
    const realTimeValidationInProgress = isRealtimeValidationEnabled && realtimeValidationMap.inProgress === true
    const realTimeValidationDisabled = !isRealtimeValidationEnabled || (realtimeValidationMap.valid === null && realtimeValidationMap.inProgress === false)

    const realTimeValidationFeedbackMessage = () => {
        let labelMessage = null
        let labelColor = null
        if (realTimeValidationInProgress) {
            labelColor = 'text-app-yellowDark'
            labelMessage = t("realtime_email_validation.feedbacks.in_progress")
        } else if (realTimeValidationError) {
            labelColor = 'text-app-redLight'
            labelMessage = t("realtime_email_validation.feedbacks.error")
        } else if (realTimeValidationSuccess) {
            labelColor = 'text-app-greenDark'
            labelMessage = t("realtime_email_validation.feedbacks.success")
        }

        return (
            <span className={`absolute top-20 text-xs ${labelBottomMargin} ${labelColor}`}>
                {labelMessage}
            </span>
        );
    }


    return (
        <div className={`flex flex-col w-full relative ${compCss}`}>
            <div
                className='flex justify-between w-full mb-2 h-[30px]'
            >
                {label && (
                    <span
                        className={` ${labelFontSizeToUse} ${error || realTimeValidationError ? "text-app-redLight" : "text-app-mediumgrey"}`}
                    >
                        {label}
                    </span>
                )}
                {(!error && isRealtimeValidationEnabled) && realtimeValidationLabel()}
            </div>
            <input
                type={inputType}
                id={id}
                min={min}
                max={max}
                disabled={disabled}
                style={disabled ? { color: "rgb(108 117 125)" } : {}}
                className={`
                    ${css}
                    box-border
                    w-full rounded-md border px-4 py-3 transition-colors focus:outline-none disabled:cursor-not-allowed 
                    ${(error || realTimeValidationError) && "border-app-redLight focus:border-app-redLight"}
                    ${!error && (realTimeValidationDisabled) && "border-app-line focus:border-app-orange"}
                `}
                onChange={onChangeHandler}
                placeholder={placeholder ?? label}
                {...rest}
                onKeyUp={onKeyUp}
                maxLength={maxLength}
                onBlur={onBlur}
            />
            {icon && icon}
            {error ? (
                <span className={`text-app-redLight ${labelBottomMargin} absolute top-20 text-xs`}>
                    {error}
                </span>
            ) : realTimeValidationFeedbackMessage()}
        </div>
    );
};

export {
    EmailField
};
