import * as React from "react";

function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 8c0-.4.266-.667.666-.667S14 7.6 14 8v4.667c0 1.133-.867 2-2 2H2.667c-1.134 0-2-.867-2-2V3.333c0-1.133.866-2 2-2H10c.4 0 .667.267.667.667s-.267.667-.667.667H2.667c-.4 0-.667.266-.667.666v9.334c0 .4.267.666.667.666H12c.4 0 .667-.266.667-.666V8zM7.8 9.8l7.333-7.333a.644.644 0 000-.934.644.644 0 00-.933 0L7.333 8.4 5.8 6.867a.644.644 0 00-.933 0 .644.644 0 000 .933l2 2c.133.133.266.2.466.2s.334-.067.467-.2z"
        fill="#212529"
      />
    </svg>
  );
}

export default SvgCheck;
