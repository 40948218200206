import * as React from "react";

function SvgAlert(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 .8C4 .8.8 4 .8 8S4 15.2 8 15.2 15.2 12 15.2 8 12 .8 8 .8zM7.36 4h1.2v5.6h-1.2V4zM8 12.8c-.48 0-.8-.32-.8-.8 0-.48.32-.8.8-.8.48 0 .8.32.8.8 0 .48-.32.8-.8.8z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgAlert;
