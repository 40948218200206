import * as React from "react";

function SvgEllipsis(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 3.467A1.289 1.289 0 108 .889a1.289 1.289 0 000 2.578zM8 9.289A1.289 1.289 0 108 6.71 1.289 1.289 0 008 9.29zM8 15.111a1.289 1.289 0 100-2.578 1.289 1.289 0 000 2.578z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgEllipsis;
