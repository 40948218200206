import * as React from "react";

function SvgTrash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill={props.fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer hover:opacity-60 transition-opacity"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3.333h-2.667v-.666c0-1.134-.866-2-2-2H6.667c-1.134 0-2 .866-2 2v.666H2c-.4 0-.667.267-.667.667s.267.667.667.667h.667v8.666c0 1.134.866 2 2 2h6.666c1.134 0 2-.866 2-2V4.667H14c.4 0 .667-.267.667-.667S14.4 3.333 14 3.333zm-8-.666c0-.4.267-.667.667-.667h2.666c.4 0 .667.267.667.667v.666H6v-.666zM11.333 14c.4 0 .667-.267.667-.667V4.667H4v8.666c0 .4.267.667.667.667h6.666zm-4-6.667v4c0 .4-.266.667-.666.667S6 11.733 6 11.333v-4c0-.4.267-.666.667-.666s.666.266.666.666zm2.667 4v-4c0-.4-.267-.666-.667-.666s-.666.266-.666.666v4c0 .4.266.667.666.667s.667-.267.667-.667z"
        fill={props.fill ?? "#000"}
      />
    </svg>
  );
}

export default SvgTrash;
