import * as React from "react";

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill={props.fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 1.252a2.08 2.08 0 00-1.472.61l-8.333 8.333a.666.666 0 00-.175.31l-.667 2.667a.667.667 0 00.808.808l2.667-.667a.667.667 0 00.31-.175l8.333-8.333a2.082 2.082 0 00-1.471-3.553zm-.529 1.553a.747.747 0 111.057 1.057l-8.202 8.203-1.41.352.352-1.41 8.203-8.202z"
        fill={props.fill ?? "#000"}
      />
      <path
        d="M8.667 12.667a.667.667 0 100 1.333h6a.667.667 0 000-1.333h-6z"
        fill={props.fill ?? "#000"}
      />
    </svg>
  );
}

export default SvgEdit;
