import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

//Modal Components
import ModalHeader from "./modalHeader/ModalHeader";
import ModalFooter from "./modalFooter/ModalFooter";

const Modal = ({
  open = false,
  onSubmit = () => {},
  closeModal = () => {},
  header = true, //Display Modal Header
  footer = true, //Display Modal Footer
  showCancel = true, //Display Cancel button in Modal Footer
  title = "", // Modal Title
  customTitle, //Custom Modal Title
  titleCancel = "", //Footer Cancel Button
  titleConfirm = "", // Footer Confirm Button
  children, //Body
  width,
  confirmDisabled,
  modalCss = "",
  containerClass = "px-4 pt-5 pb-4 sm:p-6 sm:pb-4",
  modalheaderCss = "",
  titleCss = "",
  maxWidthClass="max-w-700",
  isMobile=false,
  showingSpinner=false,
  ...props
}) => {
  const modalRef = useRef(null);

  let wrapperClasses = " items-end justify-center sm:block sm:p-0 text-center "

  let wrapperClasses2 = `inline-block align-bottom bg-white text-left overflow-x-hidden transform transition-all ${
    !width && "sm:my-8 sm:align-middle sm:min-w-[700px]"
  } ${modalCss} ${maxWidthClass}`

  if(!isMobile) {
    wrapperClasses = wrapperClasses + " px-4 flex pb-20 min-h-screen pt-4 "
    wrapperClasses2 = wrapperClasses2 + ' overflow-hidden shadow-xl '
  }
  if(isMobile) {
    maxWidthClass = " w-full "
    wrapperClasses = wrapperClasses + ' bg-white h-full '
    wrapperClasses2 = wrapperClasses2 + ' w-full '
    if(!showingSpinner) {
      wrapperClasses2 = wrapperClasses2 + ' h-full '
    }
  }

  let dialogOverlay = <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
              style={{ zIndex: -999 }}
            />
          </Transition.Child>

  if(isMobile) {
    dialogOverlay = null
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[10000] overflow-y-auto"
        initialFocus={modalRef}
        static
        open={open}
        onClose={closeModal}
      >
        <div className={wrapperClasses}>
          {dialogOverlay}
          {/** triky hack for center the modal */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={wrapperClasses2}
            >
              <div
                className={`bg-white w-full ${containerClass}`}
                ref={modalRef}
              >
                {header && (
                  <ModalHeader
                    modalRef={modalRef}
                    onClose={() => closeModal(false)}
                    title={title}
                    customTitle={customTitle}
                    css={modalheaderCss}
                    titleCss={titleCss}
                  />
                )}

                {children}

                {footer && (
                  <ModalFooter
                    clickCancel={closeModal}
                    clickConfirm={onSubmit}
                    cancel={showCancel}
                    titleConfirm={titleConfirm}
                    titleCancel={titleCancel}
                    confirmDisabled={confirmDisabled}
                  />
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
