import { useAtoms } from "@recoil/hooks";
import React, { useState, useEffect } from "react";
import { useTable, useRowSelect } from "react-table";
import { CheckBox } from "../checkbox";
import { Button } from "..";



export default function Table({ columns, data, widthColumn, check, header, classToAdd, multipleSelectionActionTitle, multipleSelectionActionFunc, MultipleActionIcon }) {
  if (!data) return null;

  const { state } = useAtoms();

  const pageTranslationCode = `a:ass_tab`;

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <CheckBox size="1.5em" checked={rest.checked} />

          <input
            type="checkbox"
            ref={resolvedRef}
            className="-mt-4 ml-1 absolute cursor-pointer"
            {...rest}
            style={{ opacity: 0 }}
          />
        </>
      );
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      check &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  let tableClasses = "flex flex-col w-full " + classToAdd

  const selectedRowsIds = (multipleSelectionActionFunc && rows.filter(r => r.isSelected === false).map(r => r.original.id)) || []
  

  const action = (
    <div
      className="w-24 "
    >
      <Button
        size="large"
        css="ml-4"
        variant={selectedRowsIds.length > 0 ? "primary" : "disabled-btn"}
        disabled={selectedRowsIds.length === 0}
        startIcon={() => <MultipleActionIcon />}
        onClick={() => {
          multipleSelectionActionFunc(selectedRowsIds)
        }}
      >
        {multipleSelectionActionTitle}
      </Button>
    </div>
  )

  return (
    <>
      <table className={tableClasses} {...getTableProps()}>
        <thead>
          {multipleSelectionActionFunc && (
            <tr
              className="flex justify-between items-center h-20"
              style={{
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3E66FB",
              }}
            >
              {action}
            </tr>
          )}
          {headerGroups.map((headerGroup, index) => (
            <tr
              key={index}
              {...headerGroup.getHeaderGroupProps()}
              className={`flex w-full flex-row bg-app-grayOne text-app-dark font-tk-usual border-solid border-b-2`}
              style={{
                wordBreak: "break-word"
              }}
            >
              {headerGroup.headers.map((column, index) => {
                  let justifyPosition = 'justify-center'
                  if (index === 0) {
                    justifyPosition = 'justify-left'
                  }

                  let forceWidthColumn = ''
                  if(column.id == 'downloaded'
                    || column.id == 'price'
                    || column.id == 'assigned'
                    || column.id == 'forwarded'
                    || column.id == 'quantity'
                    || column.id == 'not_assigned'
                    || column.id == 'draft'
                    || column.id == 'total'
                    || column.id == 'assignment_available') {
                    forceWidthColumn = 'w-20'
                  } else if(column.id == 'handled') {
                    forceWidthColumn = 'w-26'
                  }
                  return (
                    <th
                      key={index}
                      className={`flex h-auto p-3 ${
                        widthColumn ? widthColumn : "w-1/2  last:w-1/4 " + forceWidthColumn
                      } ${("flex " + justifyPosition)}`}
                      {...column.getHeaderProps()}
                    >
                      <span
                        className={`${
                          index === 0 ? "text-left ml-5 mt-3" : "text-center mt-3"
                        }`}
                      >
                        {column.render("Header")}
                      </span>
                    </th>)
                })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}></tbody>
        <tbody>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                key={index}
                {...row.getRowProps()}
                className={`flex w-full flex-row ${
                  row.original.disabled
                    ? "bg-app-disabledlight"
                    : index % 2 === 0
                    ? "bg-app-white"
                    : "bg-app-grayOne"
                } text-app-dark font-tk-usual border-solid border-b-1 ${row.original?.hide === true && 'hidden'}`}
                style={{
                  background:
                    row.isSelected &&
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3E66FB",
                  wordBreak: "break-word"
                }}
              >
                {row.cells.map((cell, index) => {
                  let justifyPosition = 'justify-center'
                  if(index === 0) {
                    justifyPosition = 'justify-left'
                  }

                  let forceWidthColumn = ''
                  if(cell.column.id == 'downloaded'
                    || cell.column.id == 'price'
                    || cell.column.id == 'assigned'
                    || cell.column.id == 'quantity'
                    || cell.column.id == 'forwarded'
                    || cell.column.id == 'not_assigned'
                    || cell.column.id == 'draft'
                    || cell.column.id == 'total'
                    || cell.column.id == 'assignment_available') {
                    forceWidthColumn = 'w-20'
                  } else if (cell.column.id == 'handled') {
                    forceWidthColumn = 'w-26'
                  }
                  return (
                    <td
                      key={index}
                      className={`flex h-12 pb-5 transition-all
                      ${
                        state.sidebarShowing
                          ? "sm:h-28 md:h-28 lg:h-24 xl:h-20 2xl:h-16 items-center"
                          : "sm:h-28 md:h-24 lg:h-20 xl:h-16 2xl:h-14 items-center"
                      }
                      column-type-` + cell.column.id + `
                      p-1 ${widthColumn ? widthColumn : "w-1/2 last:w-1/4 " + forceWidthColumn} ${
                         ("flex " + justifyPosition)
                      }`}
                      {...cell.getCellProps()}
                    >
                      <div
                        className={`${
                          index === 0
                            ? "ml-5 mt-3 "
                            : "mt-3 "
                        }`}
                      >
                        {cell.render("Cell")}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
