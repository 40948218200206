import * as React from "react";

function SvgWrench(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.341 4.075l-2.593 2.592L9.391 4.31l2.593-2.593a4.334 4.334 0 00-5.656 5.658l-4.184 4.183a.666.666 0 000 .943l1.415 1.414a.665.665 0 00.942 0l4.184-4.184a4.334 4.334 0 005.656-5.656z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgWrench;
