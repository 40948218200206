import React from "react";

import { Logo } from "../";

export const Header: React.FC = () => {
  return (
    <div className="transition duration-500 ease-in-out transform bg-app-darkbg">
      <div className="flex flex-col flex-wrap p-5 mx-auto md:items-center md:flex-row">
        <a href="/" className="pr-2 lg:pr-8 lg:px-6 focus:outline-none">
          <Logo />
        </a>

        {/* <button className="w-auto px-8 py-2 my-2 text-base font-medium text-white transition duration-500 ease-in-out transform bg-blue-600 border-blue-600 rounded-md focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:b-gblue-700 ">
            {"Button"}
          </button> */}
      </div>
    </div>
  );
};
