import * as React from "react";

function SvgRemove(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.716 8l2.16-2.16a.445.445 0 00-.627-.627l-2.16 2.138-2.173-2.173a.444.444 0 00-.627.626L7.458 8l-2.125 2.098a.443.443 0 10.627.626l2.12-2.12 2.107 2.107a.444.444 0 00.626-.627L8.716 8z"
        fill="#000"
      />
      <path
        d="M8 15.111A7.11 7.11 0 118 .89a7.11 7.11 0 010 14.222zM8 1.778a6.222 6.222 0 100 12.444A6.222 6.222 0 008 1.778z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgRemove;
