import { useState, useEffect } from "react";
import Modal from "./modal";
import useTranslation from "next-translate/useTranslation";

const VideoTutorialModal = ({ showModalFromParent, closeVideoTutorialModal }) => {

const { t, lang } = useTranslation();

  const pageTranslationCode = `common${
    process.env.LOCALES_SUFFIX
  }:assignInvitesModal`;

  const videoTutorialTranslationCode = `common${
    process.env.LOCALES_SUFFIX
  }:video-tutorial-modal`;

  const [show, setShow] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState(undefined)
  const [currentVideoTitle, setCurrentVideoTitle] = useState(undefined)
  const [videoList, setVideoList] = useState(undefined)

  useEffect(() => {
    if(process.env.VIDEO_TUTORIALS) {
      let videoTutorialList = JSON.parse(process.env.VIDEO_TUTORIALS);
      setVideoList(videoTutorialList)

      for (const [videoLabel, videoUrlFromList] of Object.entries(videoTutorialList)) {
        const videoTitle = t(`${videoTutorialTranslationCode}.${videoLabel}`)
        setCurrentVideoTitle(videoTitle)
        setCurrentVideoUrl(videoUrlFromList[lang])
        break;
      }
    }
  }, []);

  useEffect(() => {
    setShow(showModalFromParent)
  }, [showModalFromParent]);

  const chooseVideo = (videoUrlFromList, videoTitle) => {
    setCurrentVideoUrl(videoUrlFromList[lang])
    setCurrentVideoTitle(videoTitle)
  }

  let videoTutorialListToShow = []
  if(videoList) {
    for (const [videoLabel, videoUrlFromList] of Object.entries(videoList)) {
      const videoTitle = t(`${videoTutorialTranslationCode}.${videoLabel}`)
      let linkClassActive = ''
      if(videoTitle === currentVideoTitle) {
        linkClassActive = 'active'
      }
      const videoChoiceWrapper = <div key={"video" + videoLabel} className="video-choice-wrapper w-full bg-grey-200 p-3 ">
        <a onClick={() => { chooseVideo(videoUrlFromList, videoTitle) }}
           className={"cursor-pointer text-app-darkbg " + linkClassActive}>{videoTitle}</a>
      </div>
      videoTutorialListToShow.push(videoChoiceWrapper)
    }
  }

  return (
    <Modal
      open={show}
      closeModal={() => {closeVideoTutorialModal()}}
      title={"Video Tutorial"}
      titleCancel={null}
      titleConfirm={null}
      onSubmit={null}
      footer={false}
      isMobile={false}
      maxWidthClass="max-w-[1100px]"
      modalCss="w-full xl:min-w-[90%] h-[700px]"
      containerClass="h-full p-8"
      //modalheaderCss="bg-app-lightbg"
    >
      <div className="m-3 flex bg-app-reallyLightGrey p-3 h-[85%]">
        <div className="flex flex-col w-full w-3/5">
          <iframe width="100%" height="100%" src={currentVideoUrl} title={currentVideoTitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div className="flex flex-col w-full w-2/5">
          { videoTutorialListToShow } 
        </div>
      </div>
    </Modal>
  );

};

export default VideoTutorialModal;
