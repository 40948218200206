import { useRecoilState } from "recoil";
//Atoms
import * as atoms from "../atoms";
import Constants from "../../utilities/Constants";
import {
  saveDataToLocal,
} from "../../helpers/localStorage";

export const useAtoms = () => {
  const [sidebarEnabled, setSidebarEnabled] = useRecoilState(
    atoms.sidebarEnabled
  );
  const [sidebarShowing, setSidebarShowing] = useRecoilState(
    atoms.sidebarShowing
  );
  const [customer, setCustomer] = useRecoilState(atoms.customer);

  const [invoiceAvailable, setInvoiceAvailable] = useRecoilState(atoms.invoiceAvailable);
  const [mustUpdateAnomalyCounts, setMustUpdateAnomalyCounts] = useRecoilState(atoms.mustUpdateAnomalyCounts);
  const [trackingForLoggedUser, setTrackingForLoggedUser] = useRecoilState(atoms.trackingForLoggedUser);
  const [loginProcessCompleted, setLoginProcessCompleted] = useRecoilState(atoms.loginProcessCompleted);
  const [customerFileUploaded, setCustomerFileUploaded] = useRecoilState(atoms.customerFileUploaded);
  const [navBarCustomers, setNavBarCustomers] = useRecoilState(atoms.navBarCustomers);
  const [authenticationServiceCustomers, setCustomersFromAuthenticationService] = useRecoilState(atoms.authenticationServiceCustomers);
  const [cart, setCart] = useRecoilState(atoms.cart);
  const [cartTotal, setCartTotal] = useRecoilState(atoms.cartTotal);
  const [order, setOrder] = useRecoilState(atoms.order);
  const [products, setProducts] = useRecoilState(atoms.products);
  const [mixedProducts, setMixedProducts] = useRecoilState(atoms.mixedProducts);
  const [warningAnomaliesCount, setWarningAnomaliesCount] = useRecoilState(atoms.warningAnomaliesCount)
  // const [userIsStillLoggingIn, setUserIsStillLoggingIn] = useRecoilState(atoms.userIsStillLoggingIn);
  const [filteredProductsForHolding, setFilteredProductsForHolding] = useRecoilState(atoms.filteredProductsForHolding);
  const [assegnaInvitiData, setAssegnaInvitiData] = useRecoilState(
    atoms.assegnaInvitiData
  );
  const [checkBannerStatus, setCheckBannerStatus] = useRecoilState(
    atoms.checkBannerStatus
  );

  const [bannerData, setBannerData] = useRecoilState(atoms.bannerData);
  const [assegnaInvitiForm, setAssegnaInvitiForm] = useRecoilState(
    atoms.assegnaInvitiForm
  );

  const [canSeeProfiloPage, setCanSeeProfiloPage] = useRecoilState(
    atoms.canSeeProfiloPage
  );

  const [language, setLanguage] = useRecoilState(atoms.language);
  const [swipedFinger, setSwipedFinger] = useRecoilState(atoms.swipedFinger)

  const infinite_value = 10000000000
  const array_products_types_in_merchadise_group = Constants.ARRAY_PRODUCTS_TYPES_IN_MERCHANDISE_GROUP

  const toggleLanguage = (payload) => {
    setLanguage(payload);
  };

  const toggleSidebarState = () => {
    setSidebarEnabled((prevState) => !prevState);
  };
  const toggleSidebarShowing = (payload) => {
    setSidebarShowing(payload);
  };

  const changeCustomerInSession = (id) => {
    if (navBarCustomers != undefined){
      const selectedCustomer = navBarCustomers.filter((i) => i.id === id).pop();
      setCustomer(selectedCustomer);
      saveDataToLocal("startUsing", 'true');
      return selectedCustomer
    }
    return null
  };

  const saveCustomerInSession = (payload) => {
    setCustomer(payload);
  };
  const saveTrackingForLoggedUser = (payload) => {
    setTrackingForLoggedUser(payload);
  };

  const saveLoginProcessCompleted = (payload) => {
    setLoginProcessCompleted(payload);
  };

  const saveCustomerFileUploaded = (file) => {
    setCustomerFileUploaded(file)
  }

  const saveNavBarCustomersInSession = (payload) => {
    setNavBarCustomers(payload);
  };

  const saveCustomersFromAuthenticationServiceInSession = (payload) => {
    setCustomersFromAuthenticationService(payload);
  };

  /*
  const saveUserIsStillLoggingIn = (payload) => {
    setUserIsStillLoggingIn(payload);
  }
  */

  //Cart Total calculation
  const totalCalculation = (array) => {
    let total = 0;
    array.forEach((i) => (total = total + i.total));
    +total.toFixed(2);
    total = total * 100
    return total
  };

  const get_cart_row_merchandising_limits = (element) => {
    var merch_max_limit_to_use = element.merch_max_limit
    var merch_stock_to_use = element.merch_stock

    if(merch_max_limit_to_use === null) {
      merch_max_limit_to_use = infinite_value
    }
    if(merch_stock_to_use === null) {
      merch_stock_to_use = infinite_value
    }

    return {merch_max_limit_to_use, merch_stock_to_use}
  }

  //Adding Initial items to cart
  const addItemsToCart = async (payload) => {
    //Adding quantity, total fields to each item
    payload.forEach((cart_row) => {
      cart_row.quantity = 0;
      cart_row.total = 0;
      cart_row.limit = cart_row.sell_limit ?? infinite_value; //Default limit

      //Setting Merchandising Limit
      if (array_products_types_in_merchadise_group.includes(cart_row.product_type)) {
        let merchandise_limit = infinite_value;
        let { merch_max_limit_to_use, merch_stock_to_use } = get_cart_row_merchandising_limits(cart_row)

        cart_row.related_products.forEach((related_product) => {
          merchandise_limit = check_buy_limit_for_cart_row_type_relation(payload, related_product)
        });

        merchandise_limit = Math.min(merchandise_limit, merch_max_limit_to_use, merch_stock_to_use)

        //set the plus button limit with the "last ultimate" calculated limit value
        cart_row.limit = merchandise_limit;
      }

      return cart_row;
    });

    setCart(payload);
    setCartTotal(totalCalculation(payload));
    
    return {cartTotal: totalCalculation(payload)}
  };

  const saveOrderInSession = async (payload) => {
    setOrder(payload)
  };

  const check_buy_limit_for_cart_row_type_relation = (cart, cart_row) => {
    let limit = 0
    if (cart_row.relation_type === 'sell_with') {
      limit = check_buy_limit_for_sell_with_relation(cart, cart_row.related_product_code)
    }
    if (cart_row.limit_merch_by_rel) {
      limit = check_buy_limit_for_sell_with_relation_also_on_quantity(cart, cart_row.related_product_code)
    }
    return limit;
  }

  const check_buy_limit_for_sell_with_relation = (cloned_cart, related_product_code) => {
    let limit = 0;

    cloned_cart.forEach((i) => {
      if (related_product_code === i.code && i.quantity > 0) {
        limit = infinite_value;
      }
    });

    return limit;
  }

  const check_buy_limit_for_sell_with_relation_also_on_quantity = (cloned_cart, related_product_code) => {
    let limit = 0;

    cloned_cart.forEach((i) => {
      if (related_product_code === i.code) {
        limit = limit + i.quantity;
      }
    });

    return limit;
  }

  const update_row_in_cart = (cloned_cart, limit, item_id) => {
    const newIndex = cloned_cart.findIndex((c) => c.id === item_id);
    const newClonedItem = { ...cloned_cart[newIndex] };

    const newQuantity = Math.min(limit, newClonedItem.quantity)

    newClonedItem.quantity = newQuantity;
    newClonedItem.limit = limit;
    newClonedItem.total = newQuantity * newClonedItem.current_price;
    cloned_cart[newIndex] = newClonedItem;
  }

  //------------------------------------------------------------------
  //MARK: Complex Logics - Caution !!
  //------------------------------------------------------------------
  //Cart Management
  const updateCart = (id, quantity, type) => {
    let clonedCart = [...cart];

    const index = clonedCart.findIndex((item) => item.id === id);
    const clonedItem = { ...clonedCart[index] };

    // Add | Subract Quantity
    clonedItem.quantity = clonedItem.quantity + quantity;

    //When trash icon is pressed || When manual quantity entered
    if (quantity === 0 || type === "manual") {
      clonedItem.quantity = quantity;
    }

    //Calculating item total
    clonedItem.total = clonedItem.quantity * clonedItem.current_price;

    clonedCart[index] = clonedItem;
    
    //Setting Merchandise quantity based on product quantity
    clonedCart.forEach((cart_row) => {
      if (array_products_types_in_merchadise_group.includes(cart_row.product_type)) {
        let merchandise_limit = infinite_value; // Default limit
        let { merch_max_limit_to_use, merch_stock_to_use } = get_cart_row_merchandising_limits(cart_row)

        cart_row.related_products.forEach((related_product) => {
          merchandise_limit = check_buy_limit_for_cart_row_type_relation(clonedCart, related_product)
        });

        merchandise_limit = Math.min(merchandise_limit, merch_max_limit_to_use, merch_stock_to_use)

        update_row_in_cart(clonedCart, merchandise_limit, cart_row.id)
      }
    });

    setCart(clonedCart);
    setCartTotal(totalCalculation(clonedCart));
  };

  //Assegna Inviti Data
  const updateAssegnaInvitiData = (payload) => {
    setAssegnaInvitiData(payload);
  };

  //Assegna Inviti Form
  const updateAssegnaInvitiForm = (payload) => {
    setAssegnaInvitiForm(payload);
  };

  //Set Products from API to state
  const updateProducts = (payload) => {
    setProducts(payload);
  };

  const updateMixedProducts = (payload) => {
    let oldproducts = state.mixedProducts.filter((product) => !payload.find(p => product.code === p.code));
    let updatedMixedProducts = [...oldproducts, ...payload];
    setMixedProducts(updatedMixedProducts);
  };

  const updateFilteredProductsForHolding = (payload) => {
    setFilteredProductsForHolding(payload);
    saveDataToLocal("filteredProductsForHolding", JSON.stringify(payload));
  };

  //Check if Banner status to be called
  const toggleCheckBanner = () => {
    setCheckBannerStatus(!checkBannerStatus);
  };

  const updateBannerData = (payload) => {
    setBannerData(payload);
  };

  const updateSideBar = (payload) => {
    setCanSeeProfiloPage(payload);
  };

  const updateSwipedFinger = (direction) => {
    setSwipedFinger(direction)
  }

  //Atoms State
  const state = {
    sidebarEnabled,
    sidebarShowing,
    customer,
    customerFileUploaded,
    navBarCustomers,
    authenticationServiceCustomers,
    cart,
    cartTotal,
    order,
    products,
    mixedProducts,
    filteredProductsForHolding,
    assegnaInvitiData,
    checkBannerStatus,
    bannerData,
    assegnaInvitiForm,
    canSeeProfiloPage,
    loginProcessCompleted,
    // userIsStillLoggingIn,
    language,
    swipedFinger,
    invoiceAvailable,
    warningAnomaliesCount,
    mustUpdateAnomalyCounts
  };

  //Atoms Actions
  const actions = {
    toggleSidebarState,
    changeCustomerInSession,
    toggleSidebarShowing,
    saveNavBarCustomersInSession,
    saveCustomersFromAuthenticationServiceInSession,
    addItemsToCart,
    saveOrderInSession,
    updateCart,
    updateProducts,
    updateMixedProducts,
    updateFilteredProductsForHolding,
    updateAssegnaInvitiData,
    updateAssegnaInvitiForm,
    toggleCheckBanner,
    updateBannerData,
    saveCustomerInSession,
    saveLoginProcessCompleted,
    updateSideBar,
    toggleLanguage,
    setWarningAnomaliesCount,
    setCustomer,
    updateSwipedFinger,
    saveCustomerFileUploaded,
    saveTrackingForLoggedUser,
    setInvoiceAvailable,
    setMustUpdateAnomalyCounts,
    // saveUserIsStillLoggingIn
  };

  return { state, actions };
};
