
const UnverifiedEmailsList = (props) => {
  const { unverfiedEmails, t } = props
  return (
    <div>
      {t(`${props.translationCode}:unverified_emails_list.description_1`)}
      <br/>
      <br/>
      {
        unverfiedEmails.map((unverifiedEmail) => {
          return (
            <li key={unverifiedEmail.id}>
              {`Riga ${unverifiedEmail.row}: ${unverifiedEmail.email}`}
            </li>
          )
        })
      }
      <br/>
      {t(`${props.translationCode}:unverified_emails_list.description_2`)}
      <br/>
      {t(`${props.translationCode}:unverified_emails_list.description_3`)}
    </div>
  )
}

export default UnverifiedEmailsList;