const Radio = ({
  checked = true,
  disabled = false,
  onChange = () => {},
  label = "",
  name = "radio-sizes",
  value = "1",
  inputClass = "h-4 w-4 cursor-pointer",
  containerClass = "flex flex-row items-center ",
  labelClass = "ml-2",
}) => {
  const id = Math.floor(Math.random() * 999).toString();

  return (
    <div className={containerClass}>
      <label className="items-center flex">
        <input
          type="radio"
          className={inputClass}
          name={id}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        {label && (
          <span
            className={`cursor-pointer ${labelClass} ${
              disabled ? "text-app-disabledgrey" : ""
            }`}
          >
            {label}
          </span>
        )}
      </label>
    </div>
  );
};
export default Radio;
