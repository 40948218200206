import React, { useState, useEffect } from "react";
import { Dropdown } from "../dropdown";
import { Typography } from "../typography";
import { Left, Right } from "../icons/d-ticketing/chevron";
//Locale
import useTranslation from "next-translate/useTranslation";
import { useSession } from "next-auth/react";

export default function Pagination({ css = "", onChangePage = () => {}, onChangeRowsPerPage = () => {}, 
    count, page, rowsPerPage,
    dropdownForcedClassname = false,
    showRowsPerPage = false}) {
  const { t } = useTranslation();
  const { data: session, status } = useSession();

  const pageTranslationCode = `common${
    process.env.LOCALES_SUFFIX
  }`;

  const pages = Array.apply(null, Array(count)).map((x, i) => ({
    label: i + 1,
    value: i,
  }));
  const [selectedPage, setSelectedPage] = useState(page);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(rowsPerPage);

  useEffect(() => {
    setSelectedPage(page);
    setSelectedRowsPerPage(rowsPerPage);
  }, [page, rowsPerPage]);

  const rowsPerPageChoices = [5, 10, 20, 50, 100]

  const changePage = (page) => {
    setSelectedPage(Number(page));
    onChangePage(Number(page));
  };

  const changeRowsPerPage = (newRowsPerPage) => {
    setSelectedRowsPerPage(Number(newRowsPerPage));
    onChangeRowsPerPage(Number(newRowsPerPage));
  }

  const leftArrowClick = () => {
    if (selectedPage <= 0) {
      onChangePage(0);
      setSelectedPage(0);
    } else {
      setSelectedPage(selectedPage - 1);
      onChangePage(selectedPage - 1);
    }
  };

  const rightArrowClick = () => {
    if (selectedPage < pages.length - 1) {
      setSelectedPage(selectedPage + 1);
      onChangePage(selectedPage + 1);
    }
  };
  const length = pages.length;

  const elements = () => {
    if (page+1 == count) {
      return <>
        <div className="border-solid border-l h-12 flex items-center flex-row pl-3 pr-3 w-44">
          {/* count == 1 -> first page, return the total elements */}
          <h1>{count == 1 ? totElements : totElements % count} {t(`${pageTranslationCode}:elements`)} </h1>
        </div>
      </>
    }
  }

  return (
    <div className={`flex w-full justify-end items-end h-12 bg-app-grayOne ${css} `} >
      <div className="flex flex-row">
        {showRowsPerPage && (
          <div className="border-solid border-l h-12 flex items-center flex-row pl-3 pr-3 ">
            <Dropdown
              options={rowsPerPageChoices}
              value={selectedRowsPerPage}
              onChange={changeRowsPerPage}
              none={false}
              forcedClassName={dropdownForcedClassname}
            />
            <Typography size="small" css="">
              {t(`${pageTranslationCode}:paginationChooseQty`)}
            </Typography>
          </div>
        )}
        <div className="border-solid border-l h-12 flex items-center flex-row pl-3 pr-3 w-44">
          {pages.length > 1 && (
            <Dropdown
              options={pages}
              value={selectedPage}
              onChange={changePage}
              none={false}
              forcedClassName={dropdownForcedClassname}
            />)}
          {pages.length > 1 && (
            <Typography size="small" css="w-52">
              {t(`${pageTranslationCode}:pagination`, { length })}
            </Typography>)}
          {pages.length <= 1 && (
            <Typography size="small" css="w-52">
              {t(`${pageTranslationCode}:pagination1`)}
            </Typography>)}
        </div>
        {pages.length > 1 && (<div
          className="border-solid border-l h-12 flex items-center pl-4 pr-4 cursor-pointer hover:bg-app-disabled"
          style={{ transition: "all 0.3s ease-in-out" }}
          onClick={leftArrowClick} >
          <Left />
        </div>)}
        {pages.length > 1 && (<div
          className="border-solid border-l h-12 flex items-center pl-4 pr-4 cursor-pointer hover:bg-app-disabledgrey"
          style={{ transition: "all 0.3s ease-in-out" }}
          onClick={rightArrowClick} >
          <Right />
        </div>)}
      </div>
    </div>
  );
}
