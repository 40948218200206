import * as React from "react";

function SvgClock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill={props.fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 3.5a.5.5 0 10-1 0V9a.5.5 0 00.252.434l3.5 2a.5.5 0 00.496-.868L8 8.71V3.5z"
        fill={props.fill ?? "#000"}
      />
      <path
        d="M8 16A8 8 0 108-.001 8 8 0 008 16zm7-8A7 7 0 111 8a7 7 0 0114 0z"
        fill={props.fill ?? "#000"}
      />
    </svg>
  );
}

export default SvgClock;
