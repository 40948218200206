import { Typography } from "../../typography";
import { Close } from "../../icons/index";

const ModalHeader = ({
  title = "",
  onClose = () => {},
  css,
  modalRef,
  customTitle,
  titleCss = ""
}) => {
  return (
    <div
      className={`flex justify-between items-center w-full bg-white ${css} mb-5 `}
      ref={modalRef}
    >
      {customTitle ? (
        <div className="flex w-4/5 font-bold">{customTitle}</div>
      ) : (
          <Typography size={"xl"} css={`font-tk-freight-sans-pro text-4xl font-bold ${titleCss}`}>{title}</Typography>
      )}

      <Close
        onClick={onClose}
        fontSize="40"
        className="cursor-pointer active:transform active:scale-[0.9] transition-transform ml-5"
      />
    </div>
  );
};

export default ModalHeader;
