import { useEffect } from 'react'
import useTranslation from "next-translate/useTranslation";
import { useAtoms } from "../../recoil/hooks";
import { saveDataToLocal } from "../../helpers/localStorage"
import Constants from "../../utilities/Constants"

const FooterEasyTicket = (props) => {
  const { t } = useTranslation();
  const { state, actions } = useAtoms();

  const pageTranslationCode = `easyticket_footer${
    process.env.LOCALES_SUFFIX 
  }`;
 
  const easyticketFooterClickAwayListener = (tabIndex) => {
    actions.updateSwipedFinger(null)
    props.setCurrentEasyTicketSelectedTab.call(this, tabIndex)
  }

  const setDesktopVersion = () => {
    props.setForceLoading(true)
    saveDataToLocal(Constants.FORCE_DESKTOP_VERSION, Constants.FORCE_DESKTOP_VERSION)
    window.location.reload()
  }

  const parsedPermissions = JSON.parse(process.env.ASSEGNA_MODAL_VISIBILITY_PER_ROLE)
  const userPermissionsCategoryG = parsedPermissions?.[state.customer?.customer_type]?.['g'] ?? {}
  const userPermissionsCategoryO = parsedPermissions?.[state.customer?.customer_type]?.['o'] ?? {}

  const accountIndex = !userPermissionsCategoryG.assign_manual || !userPermissionsCategoryO.assign_manual ? 
    1 : 
    2
  const activeIndex = props.currentEasyTicketSelectedTab

  const ctas = [
    ...(userPermissionsCategoryG.assign_manual ? [{ tabIndex: 0, translate: ':invitations' }] : []),
    ...(userPermissionsCategoryO.assign_manual ? [{ tabIndex: 1, translate: ':pass' }] : []),
    { tabIndex: accountIndex, translate: ':account' }
  ]

  const swipedDirection = state.swipedFinger
  useEffect(() => {
    const ctasLength = ctas.length
    const { tabIndex } = ctas[activeIndex]
    const newIndex = swipedDirection === 'left' ? 
      tabIndex + 1 :
      tabIndex - 1

    if (
      !swipedDirection ||
      (activeIndex === 0 && swipedDirection === 'right') ||
      (activeIndex === ctasLength && swipedDirection === 'left') ||
      newIndex > (ctasLength - 1)
    ) {
      return 
    }

    props.setCurrentEasyTicketSelectedTab.call(this, newIndex)
  }, [swipedDirection])

  const footerToShow = (
    <>
      <div id="easyticket-footer" className="easyticket-footer flex justify-center w-full py-5 bg-white mb-6">
        {ctas.map((cta, index) => {
          return (
          <a 
            key={cta.tabIndex}
            className={`footer-link ${activeIndex === index ? 'active' : ''}`}
            onClick={() => easyticketFooterClickAwayListener(cta.tabIndex)}
          >
            {t(pageTranslationCode + cta.translate)}
            {activeIndex === index && <span className="active-dot">&nbsp;</span>}
          </a>
          )
        })}
      </div>
      <div id="easyticket-footer-2" className="easyticket-footer fixed flex justify-center w-full py-2 text-sm bg-white">
        <a onClick={() => {setDesktopVersion();}}>
          {t(pageTranslationCode + ':switch-to-desktop-version')}
        </a>
      </div>
    </>
  )

  return (
    <>
    {(props.pagePath !== '/confirmdata' && props.pagePath !== '/company') && footerToShow}
    </>
  )
};

export default FooterEasyTicket;
