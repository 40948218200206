import { Button } from "../../button/index";

const ModalFooter = ({
  titleConfirm = "Confirm",
  titleCancel = "Cancel",
  clickConfirm = () => {},
  clickCancel = () => {},
  cancel = true,
  confirmVariant = "primary",
  confirmDisabled = false,
}) => {
  return (
    <div className="flex flex-col mt-12">
      <hr />

      <div className="flex justify-end items-center w-full bg-white space-x-5 mt-1">
        {cancel && (
          <Button variant="secondary" onClick={clickCancel}>
            {titleCancel}
          </Button>
        )}

        {titleConfirm && (<Button
          variant={confirmDisabled ? "disabled-btn" : confirmVariant}
          onClick={clickConfirm}
          disabled={confirmDisabled}
          type="submit"
        >
          {titleConfirm}
        </Button> )}
      </div>
    </div>
  );
};

export default ModalFooter;
