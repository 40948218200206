import { download } from "../helpers/api/desk_excel_reports/xlsx/download"
import { Button } from "../components";
import { convertYYYYMMDDToDDMMYYYY } from "../helpers/dateHelper"
import { format, it, enGB } from 'date-fns'

export const downloadExportActivityInBrowser = async (state,
    excelReportGeneratedDataId,
    window,
    document,
    fileName,
    lang) => {
    const data = await download(excelReportGeneratedDataId)
    if(data && data.data) {
        const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        const todayFormattedDate = format(new Date(), 'yyyy_MM_dd', { locale: lang == 'it' ? it : enGB })

        a.download = fileName + "_" + todayFormattedDate + ".xlsx";
        a.click();

        URL.revokeObjectURL(url);
    }
}

export const buildBannerFromData = (excelReportGeneratedData,
    t,
    pageTranslationCode,
    setExcelReportGeneratedData,
    downloadExportActivity,
    forceRefreshExcelReportGeneratedData,
    loadingExcelReportData
    ) => {
    if(excelReportGeneratedData) {
        const bannerTitle = <div className="text-black text-xl font-tk-freight-sans-pro">
                                {t(`${pageTranslationCode}.export_activity_title`)}
                            </div>

        const xClose =  <button data-collapse-toggle="banner" type="button" 
                            onClick={() => {setExcelReportGeneratedData(null)}}
                            className="text-black-400 hover:bg-black-200 hover:text-black-900 rounded-lg text-sm p-1.5 dark:hover:bg-black-600 dark:hover:text-white absolute top-2 right-2">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
                        </button>

        let executionDate =     t(`${pageTranslationCode}.export-request-date`);
        let statusLabel =       t(`${pageTranslationCode}.export-status-executing`)
        let progress =          t(`${pageTranslationCode}.export-status-progress`)
        let status =            t(`${pageTranslationCode}.export-status`)
        let lastButton =        null

        if(!loadingExcelReportData) {
            if(excelReportGeneratedData.created_at) {
                executionDate = executionDate + ": " + 
                    convertYYYYMMDDToDDMMYYYY(excelReportGeneratedData.created_at, 
                        '-', 
                        '/',
                        true,
                        true,
                        ' ',
                        ', ')
            }

            lastButton =    <Button
                                variant="secondary"
                                css="space-x-1 float-right mr-0 font-tk-freight-sans-pro"
                                endIcon={null}
                                onClick={() => {forceRefreshExcelReportGeneratedData()}}
                                tooltipMessage={t(`${pageTranslationCode}.export-tooltip-invitations`)}
                            >
                                { t(`${pageTranslationCode}.export_refresh`) }
                            </Button>

            if(excelReportGeneratedData.status
                && excelReportGeneratedData.status == 'GENERATED') {
                statusLabel = t(`${pageTranslationCode}.export-status-completed`)

                lastButton =    <Button
                                    variant="secondary"
                                    css="space-x-1 float-right mr-0 font-tk-freight-sans-pro"
                                    endIcon={null}
                                    onClick={() => {downloadExportActivity()}}
                                    tooltipMessage={t(`${pageTranslationCode}.export-tooltip-invitations`)}
                                >
                                    { t(`${pageTranslationCode}.export_download`) }
                                </Button>
                
            }
            status = status + ": " + statusLabel

            progress = progress + ": " + excelReportGeneratedData.written_records*1 + "/" + excelReportGeneratedData.total_records*1
        }

        let colorClasses = "bg-yellow-50 border-yellow-200"
        if(excelReportGeneratedData.status
            && excelReportGeneratedData.status == 'GENERATED') {
            colorClasses = "bg-green-50 border-green-200"
        }
        if(loadingExcelReportData) {
            colorClasses = "bg-gray-100 border-gray-200"
        }

        return  <div id="banner" tabIndex="-1" 
            className={"py-3 px-4 w-full relative border border-b " + colorClasses}>
                    {bannerTitle}
                    <div className="flex gap-8 justify-between items-center">
                        <div className="font-tk-freight-sans-pro">{executionDate}</div>
                        <div className="font-tk-freight-sans-pro">{status}</div>
                        <div className="font-tk-freight-sans-pro">{progress}</div>
                        <div className="font-tk-freight-sans-pro">{lastButton}</div>
                    </div>
                    { xClose }
                </div>
    }
    else {
        return null
    }
}