const FormikDropdown = ({
  name,
  label = null,
  placeholder = "",
  onChange = () => {},
  value,
  options = [],
  css = "",
  style = {},
  compCss = "my-1",
  labelCss = "",
  error = false,
  errorLabel = "",
  ...rest
}) => {
  return (
    <div className={`flex flex-col w-full ${compCss}`}>
      {label && (
        <span className={`text-app-mediumgrey font-normal mb-2 ${labelCss}`}>
          {label}
        </span>
      )}
      <span className="font-tk-usual text-app-redLight">{errorLabel}</span>
      <select
        name={name}
        className={`${css} form-select w-full rounded-md border p-3 transition-colors  focus:border-app-orange cursor-pointer ${
          error && "border-app-redLight"
        }`}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        style={{ ...style }}
        {...rest}
      >
        {options &&
          options.map((o, index) => {
            return (
              <option value={o.value ?? o} key={index}>
                {o.label ?? o}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default FormikDropdown;
