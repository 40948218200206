import { useEffect } from "react";
/**
 * On Click Outside Hooks
 * @param {*} ref - Ref - Reference to the target <div>
 * @param {*} callback Function - Callback function which fires on Mouse event
 * @returns
 */

const useOnClickOutSide = (ref, callback = () => {}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) callback();
      else return;
    };

    // Bind the event listener
    window.addEventListener("mousedown", handleClickOutside);

    //CleanUp
    return () => window.removeEventListener("mousedown", handleClickOutside);
  }, []);
};

export default useOnClickOutSide;
