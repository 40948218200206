const CheckCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      className='text-app-defaultColor'>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 -0.599609C5.1399 -0.599609 -0.600098 5.14039 -0.600098 12.0004C-0.600098 18.8604 5.1399 24.6004 11.9999 24.6004C18.8599 24.6004 24.5999 19.0004 24.5999 12.0004C24.5999 5.00039 18.9999 -0.599609 11.9999 -0.599609ZM10.1799 16.9004L5.6999 12.4204L7.0999 11.0204L10.1799 14.1004L16.8999 7.38039L18.2999 8.78039L10.1799 16.9004Z"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <path
        d="M10.1794 16.9004L5.69941 12.4204L7.09941 11.0204L10.1794 14.1004L16.8994 7.38039L18.2994 8.78039L10.1794 16.9004Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CheckCircle;