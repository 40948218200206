import * as React from "react";

function SvgSwitch1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={28} height={16} rx={8} fill="currentColor" />
      <circle cx={20} cy={8} r={6} fill="#fff" />
    </svg>
  );
}

export default SvgSwitch1;
