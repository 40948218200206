const NodeCache = require("node-cache");

class CacheHelper {
    cache = null;

    constructor() {
        this.cache = new NodeCache();
        this.cache.on("expired", (key, value) => {});
        this.cache.on("set", (key, value) => {});
    }

    set(key, value, ttl) {
        this.cache.set(key, value, ttl);
    }

    get(key) {
        return this.cache.get(key);
    }
}

export default new CacheHelper();