import { Check, Resting } from "../icons/d-ticketing/checkbox/index";

export default function CheckBox({
  checked = true,
  onClick = () => {},
  label = "",
  ref,
  size = "",
}) {
  if (checked)
    return (
      <div className="flex flex-row items-center  cursor-pointer">
        <Resting onClick={onClick} ref={ref} width={size} height={size} />
        <div className="ml-2">{label}</div>
      </div>
    );
  return (
    <div className="flex flex-row items-center cursor-pointer">
      <Check onClick={onClick} ref={ref} width={size} height={size} />
      <div className="ml-2">{label}</div>
    </div>
  );
}
