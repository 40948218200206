import * as React from "react";

function SvgPrint(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.417 2.667h5.166a1.083 1.083 0 011.082 1.022l.002.061v.916h.583c.967 0 1.75.784 1.75 1.75v4.5A1.083 1.083 0 0112.917 12h-1.25v.583a1.084 1.084 0 01-1.084 1.084H5.417a1.084 1.084 0 01-1.084-1.084V12h-1.25A1.083 1.083 0 012 10.917v-4.5c0-.967.783-1.75 1.75-1.75h.583V3.75a1.083 1.083 0 011.022-1.082l.062-.001zM10.583 9.5H5.417a.25.25 0 00-.25.25v2.833c0 .138.112.25.25.25h5.166a.25.25 0 00.25-.25V9.75a.25.25 0 00-.25-.25zm1.667-4h-8.5a.917.917 0 00-.917.917v4.5c0 .138.112.25.25.25h1.25V9.75a1.083 1.083 0 011.084-1.083h5.166a1.083 1.083 0 011.084 1.083v1.416h1.25a.25.25 0 00.25-.25v-4.5a.917.917 0 00-.917-.916zm-1.667-2H5.417a.25.25 0 00-.248.216l-.002.034v.916h5.666V3.75a.25.25 0 00-.216-.248l-.034-.002z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgPrint;
