import classes from "./AutocompleteField.module.css";
import useTranslation from "next-translate/useTranslation";
import { useState, memo, useMemo, useCallback } from "react"

const AutocompleteField = (props) => {
  const {
    disabled,
    listName,
    options,
    onChange,
    value
  } = props
  const { lang } = useTranslation()

  const [renderDatalist, setRenderDatalist] = useState(false)

  const handleOnChange = useCallback((e) => onChange(e), [onChange]);
  const handleOnFocus = useCallback(() => setRenderDatalist(true), []);
  const handleOnBlur = useCallback(() => setRenderDatalist(false), []);

  const mappedOptions = useMemo(() => options.map((option, index) => (
    <option key={index} value={option.label[lang]} />
  )), [options, lang]);

  return (
    <div>
      <input
        type="text"
        list={listName}
        disabled={disabled}
        value={value}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        className={classes.bgInput}/>
      { renderDatalist &&
        <datalist id={listName}>
          {mappedOptions}
        </datalist>
      }
    </div>
  )
}

export default memo(AutocompleteField);