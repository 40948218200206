import { useState, useLayoutEffect, useEffect } from "react";
import { useSession } from "next-auth/react";
import { callLogin } from "src/helpers/authHelper";
import { Sidebar, Nav, Container } from "../components";
import { NavEasyTicket, FooterEasyTicket } from "../components";
import Spinner from "../components/Spinner";
import { useAtoms } from "../recoil/hooks";
import { isMobile } from 'react-device-detect';
import { buildComponentName } from "../helpers/componentsHelper";
import { getDataFromLocal } from "../helpers/localStorage"
import Constants from "src/utilities/Constants";
import useTranslation from "next-translate/useTranslation";
import { removeDataFromLocal } from "../helpers/localStorage"

const Layout = (props) => {
  const { data: session, status }  = useSession();
  const { state, actions } = useAtoms();
  const [showSidebar, setShow] = useState(true);
  const [nav, setNav] = useState(false);
  const [easyTicketFooter, setEasyTicketFooter] = useState(false);
  const [layoutDeviceStyleClasses, setLayoutDeviceStyleClasses] = useState('');
  const [useMobileVersion, setUseMobileVersion] = useState(false);
  const [backToMobileButton, setBackToMobileButton] = useState(null);
  const { t, lang } = useTranslation();
  const [forceLoading, setForceLoading] = useState(false)
  const [justEntered, setJustEntered] = useState(true)
  const ComponentsMap = { Nav, NavEasyTicket, FooterEasyTicket };

  const pageTranslationCode = `easyticket_footer${
    process.env.LOCALES_SUFFIX 
  }`;

  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
  useIsomorphicLayoutEffect(() => {
    let valueToUse = state.sidebarShowing
    const forceDesktopVersion = getDataFromLocal(Constants.FORCE_DESKTOP_VERSION)
    if(justEntered && isMobile && forceDesktopVersion) {
      actions.toggleSidebarShowing(false);
      valueToUse = false
    }
    setShow(valueToUse), [state.sidebarShowing];
  });

  if (!session && status !== "loading") {
    callLogin();
  }

  const removeForcedDesktopVersion = () => {
    setForceLoading(true)
    removeDataFromLocal(Constants.FORCE_DESKTOP_VERSION)
    window.location.href = '/'
  }

  const parentSidebarClick = () => {
    setJustEntered(false)
  }

  useEffect(() => {
    const forceDesktopVersion = getDataFromLocal(Constants.FORCE_DESKTOP_VERSION)
    let useMobileVersionTemp = isMobile && !forceDesktopVersion
    setUseMobileVersion(useMobileVersionTemp)
  }, [])
  
  useEffect(() => {
    let navComponentName = buildComponentName("Nav", useMobileVersion)
    let layoutDeviceStyleClasses = 'desktop';
    if(useMobileVersion) {
      layoutDeviceStyleClasses = 'mobile';
    }
    setLayoutDeviceStyleClasses(layoutDeviceStyleClasses)

    let footerComponentName = buildComponentName("Footer", useMobileVersion)
    const FooterComponent = ComponentsMap[footerComponentName];
    if(FooterComponent) {
      const footerToDisplay = <FooterComponent 
                                hasUser={session?.user ?? false} 
                                username={session?.user?.email} 
                                {...props}
                                currentEasyTicketSelectedTab={props.currentEasyTicketSelectedTab}
                                setCurrentEasyTicketSelectedTab={props.setCurrentEasyTicketSelectedTab}
                                setForceLoading={setForceLoading}
                                />;
      setEasyTicketFooter(footerToDisplay);
    }
    
    const NavComponent = ComponentsMap[navComponentName];

    const navToDisplay = <NavComponent {...props} parentSidebarClick={parentSidebarClick}/>;
    setNav(navToDisplay)

    if(isMobile) {
      if(!useMobileVersion) {
        setBackToMobileButton(
          <div className="fixed back-to-mobile-button-wrapper text-sm py-3 bg-white">
            <a className="back-to-mobile-button" onClick={() => {removeForcedDesktopVersion()}}>
              {t(pageTranslationCode + ':switch-to-mobile-version')}
            </a>
          </div>)
      }
      else {
        setBackToMobileButton(null)
      }
    }

  }, [props.currentEasyTicketSelectedTab, props.pagePath, useMobileVersion]);

  if (session && status !== "loading" && !forceLoading) {
    return (
      <div id="layout-wrapper" className={"layout-wrapper " + layoutDeviceStyleClasses}>
        {nav}
        <Container>
          { !state.sidebarEnabled 
          && !useMobileVersion
          && <Sidebar show={showSidebar} /> }
          { props.children }
        </Container>
        {easyTicketFooter}
        {backToMobileButton}
      </div>
    );
  }

  return <Spinner />;
};

export default Layout;
