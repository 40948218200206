import React from "react";

const Container = (props, { children } ) => {
  return (
    <div className="font-normal leading-normal text-gray-900 text-sm box-border flex flex-1 w-full">
      {props.children}
    </div>
  );
};
export default Container;
