export const saveDataToLocal = async (key, value) =>
  typeof window !== "undefined"
    ? window.localStorage.setItem(key, value)
    : undefined;

export const getDataFromLocal = (key) => {
  if(typeof window === "undefined") {
    return undefined
  }
  return window.localStorage.getItem(key)
}

export const removeDataFromLocal = (key) =>
  typeof window !== "undefined"
    ? window.localStorage.removeItem(key)
    : undefined;

export const removeAllDataFromLocal = () => {
  typeof window !== "undefined" ? window.localStorage.clear() : undefined;
}
