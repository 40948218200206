export { default as Alert } from "./Alert";
export { default as Switch1 } from "./Switch1";
export { default as Switch } from "./Switch";
export { default as Calendar } from "./Calendar";
export { default as Clock } from "./Clock";
export { default as Close } from "./Close";
export { default as Copy } from "./Copy";
export { default as Datails } from "./Datails";
export { default as Download } from "./Download";
export { default as Edit } from "./Edit";
export { default as Ellipsis } from "./Ellipsis";
export { default as Email } from "./Email";
export { default as Filter } from "./Filter";
export { default as InfoRounded } from "./InfoRounded";
export { default as Menu } from "./Menu";
export { default as Minus } from "./Minus";
export { default as NotAllowed } from "./NotAllowed";
export { default as Plus1 } from "./Plus1";
export { default as Plus } from "./Plus";
export { default as Print } from "./Print";
export { default as RemoveTicket } from "./RemoveTicket";
export { default as Remove } from "./Remove";
export { default as Sort } from "./Sort";
export { default as Trash } from "./Trash";
export { default as Wrench } from "./Wrench";
export { default as Wait } from "./Wait";
