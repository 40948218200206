import * as React from "react";

function SvgRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 16l-10 10v-20l10 10z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgRight;
