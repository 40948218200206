import * as React from "react";

function SvgWait(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4242 21.8594C14.2263 21.9087 14.0245 21.9525 13.8242 21.9894C13.3007 22.0868 12.9546 22.5903 13.0514 23.1143C13.0992 23.3721 13.2456 23.5868 13.4444 23.727C13.6493 23.8712 13.91 23.9363 14.1757 23.8869C14.4142 23.8426 14.6546 23.7905 14.8904 23.7317C15.4074 23.603 15.7224 23.0793 15.5934 22.5625C15.4647 22.0453 14.9415 21.7306 14.4242 21.8594Z"
        fill="#453B08"
      />
      <path
        d="M21.5593 8.9273C21.6269 9.13097 21.7559 9.29725 21.9189 9.41214C22.1605 9.58234 22.4767 9.63961 22.7785 9.53983C23.2844 9.37185 23.5585 8.82633 23.3911 8.32069C23.3149 8.09019 23.2303 7.85896 23.1401 7.63382C22.9422 7.13906 22.381 6.89819 21.886 7.09609C21.3915 7.29389 21.1505 7.85524 21.3486 8.35015C21.4243 8.53939 21.4953 8.73362 21.5593 8.9273Z"
        fill="#453B08"
      />
      <path
        d="M17.5594 20.4818C17.3892 20.5942 17.2135 20.7029 17.0366 20.8048C16.5749 21.0711 16.4168 21.6613 16.683 22.1228C16.7552 22.2483 16.8515 22.3509 16.9624 22.4293C17.26 22.6387 17.6642 22.6701 18.0007 22.4762C18.2111 22.3549 18.4203 22.2258 18.623 22.0917C19.0674 21.7981 19.1898 21.1995 18.896 20.7549C18.6023 20.3102 18.0039 20.188 17.5594 20.4818Z"
        fill="#453B08"
      />
      <path
        d="M23.9908 11.6223C23.9699 11.0898 23.5213 10.6753 22.9887 10.6961C22.4567 10.7171 22.0419 11.1657 22.0628 11.6981C22.0708 11.9016 22.0728 12.1082 22.0681 12.3115C22.0607 12.6454 22.224 12.9431 22.4778 13.122C22.629 13.2285 22.8125 13.2929 23.0115 13.2975C23.544 13.3093 23.9853 12.8869 23.9971 12.3541C24.0025 12.1112 24.0004 11.8651 23.9908 11.6223Z"
        fill="#453B08"
      />
      <path
        d="M21.4015 17.9438C20.9745 17.6234 20.3704 17.7104 20.0506 18.1367C19.9281 18.2999 19.7991 18.4611 19.6671 18.6164C19.3219 19.0219 19.3707 19.6309 19.7762 19.9763C19.7993 19.996 19.8226 20.014 19.8469 20.0309C20.25 20.3151 20.8103 20.2498 21.1362 19.8673C21.294 19.6821 21.4477 19.4895 21.5941 19.2945C21.9139 18.8683 21.8274 18.2637 21.4015 17.9438Z"
        fill="#453B08"
      />
      <path
        d="M22.8198 14.4561C22.3114 14.2966 21.77 14.5797 21.6106 15.0881C21.5497 15.2823 21.4821 15.4775 21.4091 15.6686C21.2489 16.0894 21.4024 16.5524 21.7543 16.8005C21.8188 16.8458 21.8899 16.8843 21.9671 16.9135C22.4649 17.1034 23.0224 16.8537 23.2121 16.3557C23.2987 16.1284 23.3791 15.8962 23.4518 15.6653C23.611 15.1568 23.3281 14.6155 22.8198 14.4561Z"
        fill="#453B08"
      />
      <path
        d="M10.2149 21.9982C9.3523 21.8434 8.52484 21.5796 7.74019 21.2118C7.73091 21.2069 7.7226 21.2014 7.71285 21.197C7.52795 21.1099 7.34336 21.0167 7.16444 20.9193C7.16382 20.9186 7.16269 20.9181 7.16171 20.9177C6.83343 20.7369 6.51305 20.5369 6.20181 20.3178C1.66327 17.1207 0.572018 10.8273 3.7693 6.28881C4.46454 5.30229 5.30588 4.47926 6.24385 3.82695C6.25541 3.8189 6.26697 3.8109 6.27842 3.80281C9.58368 1.52538 14.0717 1.3719 17.5814 3.71216L16.8276 4.80129C16.618 5.10444 16.747 5.32535 17.1138 5.29233L20.3881 4.99919C20.7555 4.96618 20.9751 4.64843 20.8763 4.29369L19.9971 1.12571C19.8986 0.77056 19.6466 0.72805 19.4368 1.03115L18.6813 2.12286C16.1057 0.393896 13.0165 -0.265381 9.95029 0.266313C9.64147 0.319761 9.33698 0.385178 9.03667 0.461325C9.03435 0.461738 9.03249 0.461996 9.03063 0.462409C9.01902 0.465246 9.00726 0.469013 8.99596 0.47216C6.35194 1.15115 4.04506 2.69324 2.39895 4.89349C2.38507 4.90994 2.37078 4.92604 2.35768 4.94394C2.30294 5.01766 2.24862 5.09309 2.19543 5.16852C2.10844 5.29213 2.0227 5.41883 1.94067 5.54554C1.9304 5.56081 1.92256 5.57634 1.91358 5.59177C0.5552 7.69672 -0.100002 10.1303 0.0123622 12.6077C0.0126202 12.6159 0.0121559 12.6241 0.0123622 12.6325C0.0232479 12.8745 0.0424396 13.1198 0.0684929 13.3614C0.0698859 13.377 0.0733425 13.3917 0.0759736 13.4073C0.102904 13.6502 0.136696 13.8936 0.179052 14.137C0.609525 16.6205 1.78105 18.8553 3.53653 20.5941C3.5406 20.5982 3.54483 20.6027 3.54896 20.6069C3.5504 20.6085 3.552 20.6093 3.5534 20.6108C4.02504 21.076 4.53806 21.5063 5.09044 21.8953C6.53601 22.9139 8.1457 23.5872 9.8744 23.8974C10.3989 23.9917 10.9 23.6425 10.9941 23.1183C11.0881 22.5936 10.7393 22.0922 10.2149 21.9982Z"
        fill="#453B08"
      />
      <path
        d="M11.4079 4.29199C10.9764 4.29199 10.627 4.64178 10.627 5.07261V12.8498L17.7398 16.5267C17.8543 16.586 17.9768 16.6139 18.0975 16.6139C18.38 16.6139 18.6528 16.46 18.7916 16.1916C18.9894 15.8085 18.8397 15.3378 18.4566 15.14L12.1879 11.8991V5.07261C12.1879 4.64178 11.8388 4.29199 11.4079 4.29199Z"
        fill="#453B08"
      />
    </svg>
  );
}

export default SvgWait;
