import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton
} from "@material-tailwind/react";
import { Button } from "../../components";
import CloseIcon from '@mui/icons-material/Close';

export const DticketingDialog = ({children, open = false, title,size="sm",body, onClickPositiveAction = null, onClickNegativeAction = null, positiveLabel = "Ok", negativeLabel="Delete", onClickIconAction}) => {

  return(
    <Dialog size={size} className="rounded" open={open}>
          <DialogHeader className="font-light text-tk-freight-sans-pro-lights text-app-dark text-xl justify-between">
             <span>{title}</span>
          <IconButton
            color="blue-gray"
            size="sm"
            variant="text"
            onClick={onClickIconAction}
          >
            <CloseIcon />
          </IconButton>

          </DialogHeader>
          <DialogBody className="font-tk-usual text-sm text-black">
            {children}
          </DialogBody>
          <DialogFooter>
          { negativeLabel
            && onClickNegativeAction 
            && <Button
              variant="secondary"
              css="h-[36px]"
              labelClass="font-semibold"
              onClick={onClickNegativeAction}
            >
              {negativeLabel}
            </Button>
          }
          {/* TODO: rifattorizzare con i bottoni di "@material-tailwind/react"; */}
         { positiveLabel
          && onClickPositiveAction 
          && <Button 
              data-testid="wizard-next-btn"
              css="h-[36px]"
              labelClass="font-semibold"
              variant={"primary"}
              onClick={onClickPositiveAction}
             >
              {positiveLabel}
            </Button>
          }
          </DialogFooter>
      </Dialog>
  );

}