import Modal from '@components/modal/modal';
import classes from './CompilationErrorsModal.module.css';

const CompilationErrorsModal = (props) => {
  const {
    onSubmit,
    open,
    closeModal,
    modalBody,
    modalTexts
  } = props

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={modalTexts.title}
      titleCancel={modalTexts.titleCancel}
      titleConfirm={modalTexts.titleConfirm}
      onSubmit={onSubmit}
    >
      {modalBody}
    </Modal>
  );
}

export default CompilationErrorsModal;