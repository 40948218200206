import { useEffect } from "react";
import { useRouter } from "next/router";
import { useAtoms } from "../recoil/hooks";
import { nextAPI } from "../api/axiosAPI";
import * as Sentry from "@sentry/nextjs";

const Hoc = ({ children }) => {
  const { state, actions } = useAtoms();
  const router = useRouter();
  const { asPath } = useRouter();

  //Guarding all routes, only access if customer exists
  useEffect(() => {
    const handleFlow = async () => {

      const checkTheStorageCustomer = state?.customer && state?.customer?.code;
      if (checkTheStorageCustomer) {
        const checkPayload = {
          code: state.customer.code,
        };
        try {
          const checkCustomerCodeConformity = await nextAPI.post(
            "/customers/uid",
            checkPayload
          );

          if (!checkCustomerCodeConformity.data.length) {
            throw new Error("Customer not found");
          }
        } catch (error) {
          const e = new Error(`Customer with code: ${state.customer.code || 'no-code'}, id: ${state.customer.id || 'no-id'} and mail: ${state.customer.email || 'no-mail'} not found`);
          Sentry.captureException(e);
          router.push("/logout");
        }
      }
    }
    if(state.loginProcessCompleted === true) {
      handleFlow();
    }
  }, [state.loginProcessCompleted]);

  useEffect(() => {
    if (!state.customer && state.loginProcessCompleted === true && asPath !== "/logout") {
      return router.push("/logout");  
    }

    const handleAsync = async () => {
      const anomaliesCalls = []
      const unreadAnomaliesParams = { // Unread not handled anomalies
        handled: false,
        read: false,
        page: 0,
        limit: 5,
        sort: "created_at",
      };
      const unreadData = nextAPI.get("/communicator/confirmations", { params: unreadAnomaliesParams });
      anomaliesCalls.push(unreadData);

      const totalAnomalies = {  // Every not handled anomaly
        handled: false,
        page: 0,
        limit: 1,
        sort: "created_at",
      };
      const totalData = nextAPI.get("/communicator/confirmations", { params: totalAnomalies });
      anomaliesCalls.push(totalData);

      const anomalies = await Promise.all(anomaliesCalls);
      const unread = Number(anomalies[0].data?.totalDocs);
      const total = Number(anomalies[1].data?.totalDocs);

      actions.setWarningAnomaliesCount({
        unread,
        atLeastOne: total > 0,
      });
    }

    const trackingForLoggedUser = JSON.parse(localStorage.getItem("trackingForLoggedUser"));
    if(trackingForLoggedUser?.logged_customer_code) {
      handleAsync();
    }

  }, [asPath, state.mustUpdateAnomalyCounts]); // This effect act as a guard to prevent user from accessing other routes if customer is not set -- it only gets activated if the user has atttempted login

  return children;
};

export default Hoc;
