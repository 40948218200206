import { useRouter } from "next/router";
import { useEffect } from "react";
import { Logo } from "../logo";
import { Button } from "../button";
import Menu from "../icons/d-ticketing/Menu";
import Modal from "../modal/modal";
import { useAtoms } from "../../recoil/hooks";
import { useState } from "react";
import { Typography } from "../typography";
import { getDataFromLocal } from "../../helpers/localStorage";
import useTranslation from "next-translate/useTranslation";
import { useSession } from "next-auth/react";
import setLanguage from "next-translate/setLanguage";
import { checkAndCleanCustomerWithIssues } from "../../helpers/authHelper";
import { useRecoilState } from "recoil";
import * as atoms from "../../recoil/atoms";
import * as Sentry from "@sentry/nextjs";

const Nav = (props) => {
  const { state, actions } = useAtoms();
  const router = useRouter();
  const { t, lang } = useTranslation();
  const { data: session, status } = useSession();
  const [usernameToShow, setUsernameToShow] = useState(null)
  const [language, setLocalLanguage] = useState(state.language);
  const getLocalConfirm = getDataFromLocal("confirmData");
  const [customer, setCustomer] = useRecoilState(atoms.customer);

  const localData = getLocalConfirm
    ? JSON.parse(getLocalConfirm)
    : getLocalConfirm;

  useEffect(() => {
    let usernameToShowTemp = session?.user?.email ? session.user.email : null
    setUsernameToShow(usernameToShowTemp)
  }, [customer, session]);

  //Force reload page for language to work correctly
  useEffect(() => {
    if (lang !== language) {
      const currentPath = router.asPath.split("#").shift();
      window.location.replace("/" + language + currentPath);
    }
  }, [language]);

  // const checkCurrentUserEmail = () => {
  //   const customerFromLocalStorage = getDataFromLocal('customer')
  //   let usernameToShowTemp = null
  //   if(customerFromLocalStorage) {
  //     usernameToShowTemp = JSON.parse(customerFromLocalStorage).email
  //   }
  //   return usernameToShowTemp
  // }

  const pageTranslationCode = `common${
    process.env.LOCALES_SUFFIX
  }`;

  const [showWarning, setShowWarning] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  const navBarCustomersInSession = state.navBarCustomers;

  let hasSelect = Boolean(!state.sidebarEnabled) || Boolean(state?.customer); // enable Company selection
  const disabled = state.sidebarEnabled;
  const dropdownValue = state.customer?.id;

  //Dropdown not visible in company page
  if (router.pathname === "/company") {
    hasSelect = false;
  }

  //Customer Change Handler
  const changeCustomer = (id) => {
    setShowWarning(true);
    setCustomerId(id);
  };

  //Sidebar Menu Handler
  const onClickMenu = () => {
    if(!state.sidebarEnabled) {
      actions.toggleSidebarShowing(!state.sidebarShowing);
      props.parentSidebarClick();
    }
  };

  const closeModal = () => setShowWarning(false);

  const warningModal = (
    <Modal
      open={showWarning}
      title={t(`${pageTranslationCode}:nav.change`)}
      titleCancel={t(`${pageTranslationCode}:nav.cancel`)}
      titleConfirm={t(`${pageTranslationCode}:nav.proceed`)}
      closeModal={closeModal}
      onSubmit={() => {
        const selectedCustomer = actions.changeCustomerInSession(customerId);
        Sentry.withScope((scope) => {
          let _customer = localStorage.getItem('customer')
          try {
            _customer = JSON.parse(_customer)
          } catch (error) {
            console.log("Error parsing tracking data"), error
          }
          if (selectedCustomer?.logged_customer_code?.length < 18) {
            scope.setContext("storage", {
              selectedCustomer,
              storage_customer: _customer,
            });
            Sentry.captureMessage("Customer data is incomplete -- change customer in nav");
          }
        });
        actions.saveTrackingForLoggedUser({
          logged_mail: selectedCustomer?.email,
          logged_name: selectedCustomer?.company_name,
          logged_customer_code: selectedCustomer?.code,
        })
        const checkAndCleanCustomerWithIssuesRes = checkAndCleanCustomerWithIssues(selectedCustomer)
        if(!checkAndCleanCustomerWithIssuesRes) {
          window.location.href = "/unauthorized"
          return
        }
        
        closeModal();

        if (localData && localData[`${customerId}`]) {
          router.push(isMobile ? "/main_management" : "/");
        } else {
          router.push("/confirmdata");
        }
      }}
    >
      <Typography size="LARGE">
        {t(`${pageTranslationCode}:nav.ask`)}
      </Typography>
    </Modal>
  );

  const changeLanguage = async (lang) => {
    await setLanguage(lang);
    actions.toggleLanguage(lang);
    setLocalLanguage(lang);
    location.reload();
  };

  return (
    <div className="transition duration-500 ease-in-out transform bg-app-darkbg">
      {warningModal}
      <div className="flex items-center">
        <div className="flex-1 justify-start items-center">
          <div className="flex flex-row items-center justify-start px-4">
            <Button
              variant={disabled ? "disabled-btn" : ""}
              label={<Menu color="white" />}
              onClick={() => {onClickMenu()}}
            />
            <Logo
              css="cursor-pointer"
              onClick={() => {
                router.push("/");
              }}
            />
          </div>
        </div>
        <div className="flex-1 justify-center items-center"></div>
        <div className="flex-1 justify-end items-center">
          <div className="flex flex-row items-center justify-end px-4 py-3">
            {/* <Dropdown
              customCss={
                "px-6 py-2.5 "
              }
            /> */}
            <select
              className={`bg-transparent transition-colors text-xs md:text-base focus:border-app-orange cursor-pointer text-white mr-10`}
              onChange={async (e) => {
                let val = e.target.value;
                changeLanguage(val);
              }}
              value={language}
            >
              {(process.env.AVAILABLE_LANGUAGES || ["it"]).map((lang, _) => {
                  return (
                    <option value={lang} key={lang}>
                      {t(`${pageTranslationCode}:language.${lang}`)}
                    </option>
                  );
              })}
            </select>
            {hasSelect && (
              <select
                className={`bg-transparent  transition-colors text-xs md:text-base focus:border-app-orange cursor-pointer text-white select-choose-brand`}
                onChange={(e) => changeCustomer(e.target.value)}
                value={dropdownValue}
              >
                {navBarCustomersInSession !== undefined &&
                  navBarCustomersInSession.length > 0 &&
                  navBarCustomersInSession.map((o, index) => {
                    return (
                      <option value={o.value ?? o.company_name ?? o} key={index}>
                        {o.label ?? o.company_name ?? o}
                      </option>
                    );
                  })}
              </select>
            )}
            {usernameToShow
              && (
              <div className="hidden md:flex ml-8">
                <span className="text-app-textColor">{usernameToShow}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
