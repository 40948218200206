const TextInput = ({
  name,
  label = null,
  onChange = () => {},
  css = "",
  placeholder,
  icon,
  compCss,
  error = false,
  errorLabel = "",
  onFocus,
  onBlur,
  autocomplete="on",
  ...rest
}) => {
  return (
    <div className={`flex flex-col w-full invitation-input relative ${compCss}`}>
      {label && (
        <span
          className={` font-normal mb-2 ${
            error ? "text-app-redLight" : "text-app-mediumgrey"
          }`}
        >
          {label}
        </span>
      )}
      <input
        name={name}
        className={`${css} w-full rounded-md border px-4 py-3 relative ${
          error
            ? "border-app-redLight focus:border-app-redLight"
            : "border-app-line focus:border-app-defaultColor"
        } transition-colors  focus:outline-none disabled:cursor-not-allowed disabled:bg-white`}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder ?? label}
        {...rest}
        autocomplete={autocomplete}
      />
      <div className="absolute mt-11 right-2 pointer-events-none">
        {icon && icon}
      </div>
    </div>
  );
};

export default TextInput;
