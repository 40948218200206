import { Fragment } from "react";
import { Menu as RMenu, Transition } from "@headlessui/react";
import Ellipsis from "../icons/d-ticketing/Ellipsis";

export default function Menu({ options }) {
  return (
    <RMenu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <RMenu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              <Ellipsis />
            </RMenu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <RMenu.Items
              static
              className="origin-top-right absolute right-0 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
            >
              {options.map((option, index) => {
                return (
                  <div
                    key={index}
                    style={{ padding: "1vh" }}
                    onClick={() => {
                      option.onClick();
                    }}
                    className={option.css}
                  >
                    <RMenu.Item>
                      <span>{option.value}</span>
                    </RMenu.Item>
                  </div>
                );
              })}
            </RMenu.Items>
          </Transition>
        </>
      )}
    </RMenu>
  );
}
